import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProgramEnrolledForPatient } from "../api/VitalObservation";
import GlucoseIcon from '../../assets/images/glucose@2x.png';
import BloodPressureIcon from '../../assets/images/bloodPressure@2x.png';
import OxygenIcon from '../../assets/images/heartrate@2x.png';
import HeartRateIcon from '../../assets/images/heartrate@2x.png';
import WeightIcon from '../../assets/images/weight@2x.png';
import TemperatureIcon from '../../assets/images/thermometer@2x.png';
import './VitalReadings.css';
import BloodGlucoseModal from "./BloodGlucoseModal";
import BloodPressureModal from "./BloodPressureModal";
import OxygenModal from "./OxygenModal";
import HeartRateModal from "./HeartRateModal";
import WeightModal from "./WeightModal";
import TemperatureModal from "./TemperatureModal";





const VitalReadings = () => {

    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    const [btnLoading, setBtnLoading] = useState(false);
    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [manualReadingOptions, setManualReadingOptions] = useState([]);
    const orgReadingUnits = useSelector((state) => state?.auth?.user?.organizationUnit);
    const [bloodGlucoseModalShow, setBloodGlucoseModalShow] = useState(false);
    const [bloodPressureModalShow, setBloodPressureModalShow] = useState(false);
    const [oxygenModalShow, setOxygenModalShow] = useState(false);
    const [heartRateModalShow, setHeartRateModalShow] = useState(false);
    const [weightModalShow, setWeightModalShow] = useState(false);
    const [temperatureModalShow, setTemperatureModalShow] = useState(false);


    const [programId, setProgramId] = useState('');


    const conditionsList = [
        {
            title: 'Blood Glucose',
            logo: GlucoseIcon,
            color: 'rgba(221, 76, 56, 0.11)',
            conditionName: 'blood_glucose',
            visible: false
        },
        {
            title: 'Blood Pressure',
            logo: BloodPressureIcon,
            color: 'rgba(0, 93, 168, 0.12)',
            conditionName: 'blood_pressure',
            visible: false
        },
        {
            title: 'Oxygen',
            logo: OxygenIcon,
            color: 'rgba(146, 163, 223, 0.22)',
            conditionName: 'oxygen',
            visible: false
        },
        {
            title: 'Heart Rate',
            logo: HeartRateIcon,
            color: 'rgba(146, 163, 223, 0.22)',
            conditionName: 'heart_rate',
            visible: false
        },
        {
            title: 'Weight',
            logo: WeightIcon,
            color: 'rgba(117, 222, 203, 0.15)',
            conditionName: 'weight',
            visible: false
        },

        {
            title: 'Temperature',
            logo: TemperatureIcon,
            color: 'rgba(146, 223, 154, 0.22)',
            conditionName: 'temperature',
            visible: false
        },
    ];



    useEffect(() => {
        setBtnLoading(true);
        getProgramEnrolledForPatient(patientId)
            .then((res) => {
                setProgramId(res?.result[0]?.program?.id || '');
                const updatedConditionsList = conditionsList.map(condition => ({
                    ...condition,
                    visible: res?.result[0]?.device?.some(device => device.conditionName === condition.conditionName)
                }));

                setManualReadingOptions(updatedConditionsList);

            })
            .catch((error) => console.log("Error fetching programs", error))
            .finally(() => setBtnLoading(false));
    }, [patientId]);



    const handleClick = (item) => {
        if (item.conditionName === "blood_glucose") {
            setBloodGlucoseModalShow(true);
        };

        if (item.conditionName === 'blood_pressure') {
            setBloodPressureModalShow(true);
        };

        if (item.conditionName === "oxygen") {
            setOxygenModalShow(true);
        };

        if (item.conditionName === "weight") {
            setWeightModalShow(true);
        };

        if (item.conditionName === "temperature") {
            setTemperatureModalShow(true);
        };
    }

    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>
            
            {btnLoading ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (

                <div className="custom-card p-4">
                    <div className="cards-container-v">
                        {manualReadingOptions && manualReadingOptions.length > 0 ? (

                            manualReadingOptions.map((item, index) => (
                                item.visible && (
                                    <div
                                        key={index}
                                        className={`card-v ${item.visible ? 'visible' : 'hidden'}`}
                                        style={{ cursor: 'pointer', backgroundColor: item.color }}
                                        onClick={() => handleClick(item)}
                                    >
                                        <div className="card-content">
                                            <img src={item.logo} alt={`Icon for ${item.title}`} />
                                            <h4 style={{ color: '#0066cc' }}>{item.title}</h4>
                                            <p>Click to add readings</p>
                                        </div>
                                    </div>
                                )
                            ))

                        ) : (
                            <div>No Data Available</div>
                        )}
                    </div>
                </div>



            )}
        
            {temperatureModalShow && <TemperatureModal temperatureModalShow={temperatureModalShow} temperatureModalClose={() => setTemperatureModalShow(false)} units={orgReadingUnits.temperature} programId={programId} />}

            {oxygenModalShow && <OxygenModal oxygenModalShow={oxygenModalShow} oxygenModalClose={() => setOxygenModalShow(false)} units={orgReadingUnits.oxygen} programId={programId} />}
            {heartRateModalShow && <HeartRateModal heartRateModalShow={heartRateModalShow} heartRateModalClose={() => setHeartRateModalShow(false)} units={orgReadingUnits.heart_rate} programId={programId} />}
            {weightModalShow && <WeightModal weightModalShow={weightModalShow} weightModalClose={() => setWeightModalShow(false)} units={orgReadingUnits.weight} programId={programId} />}
            {bloodPressureModalShow && <BloodPressureModal bloodPressureModalShow={bloodPressureModalShow} bloodPressureModalClose={() => setBloodPressureModalShow(false)} units={orgReadingUnits.blood_pressure} programId={programId} />}
            {bloodGlucoseModalShow && <BloodGlucoseModal bloodGlucoseModalShow={bloodGlucoseModalShow} bloodGlucoseModalClose={() => setBloodGlucoseModalShow(false)} units={orgReadingUnits.blood_glucose} programId={programId} />}
        </section>
    )
};

export default VitalReadings;