
const cipher = salt => {
    const textToChars = text => text?.split('').map(c => c?.charCodeAt(0));
    const byteHex = n => ("0" + Number(n)?.toString(16)).slice(-2);
    const applySaltToChar = code => textToChars(salt)?.reduce((a, b) => a ^ b, code);
    return text => text?.split('')
        ?.map(textToChars)
        ?.map(applySaltToChar)
        ?.map(byteHex)
        ?.join('');
}
const decipher = salt => {
    const textToChars = text => text?.split('')?.map(c => c?.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded?.match(/.{1,2}/g)
        ?.map(hex => parseInt(hex, 16))
        ?.map(applySaltToChar)
        ?.map(charCode => String.fromCharCode(charCode))
        ?.join('');
}
// To create a cipher
const encrypt = cipher("7lI5IFL97DwrdK3Z5ZGUV8n6YMZZARbm")
const decrypt = decipher("7lI5IFL97DwrdK3Z5ZGUV8n6YMZZARbm")

//Encrypt data
export const encryptData = (data) => {
    //console.log("Encrypt Data ==> ", encrypt);
    //return encrypt(data)
    return data
    //return CryptoJS.AES.encrypt(data, REACT_APP_SECRET_KEY)?.toString()
}

//Decrypt Data
export const decryptData = (data) => {
    //console.log("Decrypt Data ==> ", decrypt);
    //return decrypt(data)
    return data
    //return CryptoJS.AES.decrypt(data, REACT_APP_SECRET_KEY)?.toString(CryptoJS.enc.Utf8);
}