import { axios } from "../../../lib/axios";

export const getRelatedPersonByRef = (patientId) => {
    return axios.get(`relatedPerson?patientRefId=`, patientId);
}

export const getPatientDataForRelatedPerson = (patientId) => {
    return axios.get(`patient/${patientId}`);
}

export const rejectRelatedPerson = ({ id, userId }) => {
    return axios.delete(`relatedPerson?id=${id}&patientRefId=${userId}&isMobile=true`);
}

export const acceptRelatedPerson = (payload) => {
    return axios.put(`relatedPerson`, payload);
}