
//For local setup
/* const local = {
    apiUrl: "https://mhgs4aogx3.execute-api.ca-central-1.amazonaws.com/dev/",
    PORT: ''
}; */

//For WEBSOCKET end point
let webSocketEndpoint;
if (typeof process.env.AWS_WEBSOCKET_ENDPOINT !== "undefined") webSocketEndpoint = process.env.AWS_WEBSOCKET_ENDPOINT

 

//For dev server port KEY can not be remove its using in application
const dev = {
    apiUrl: "https://api.dev.ca.florizelhealth.com/",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.dev.ca.florizelhealth.com"
};

//For qa server port KEY can not be remove its using in application
const qa = {
    apiUrl: "https://api.qa.ca.florizelhealth.com/",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.qa.ca.florizelhealth.com"
};

//For prod server port KEY can not be remove its using in application
const prod = {
    apiUrl: "",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.qa.ca.florizelhealth.com",
};

//console.log("process.env.REACT_APP_ENV :", process.env.REACT_APP_ENV);
if(process.env.REACT_APP_ENV === "qa") {
    module.exports = qa;
} else if (process.env.REACT_APP_ENV === "prod") {
    module.exports = prod;
} else {
    module.exports = dev;
}