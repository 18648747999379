import { axios } from "../../../lib/axios";

export const listAppointmentsByStatus = (patientId, statusOfAppointment) => {
    return axios.get(`appointment?patientId=${patientId}&status=${statusOfAppointment}`);
};

export const handleCancelAppointment = (payload) => {
    return axios.put(`appointment`, payload);

};

export const getAppointmentById = (appointmentId) => {
    return axios.get(`appointment/${appointmentId}`);
}