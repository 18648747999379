import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store, persistor } from './components/store/Store';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import ConfirmationDialogProvider from './ContextsAndProvider/ConfirmDialog';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from './ContextsAndProvider/Zoom-Context';


const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
const zmClient = ZoomVideo.createClient();


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConfirmationDialogProvider>
          <QueryClientProvider client={queryClient}>
            <ZoomContext.Provider value={zmClient}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </ZoomContext.Provider>
          </QueryClientProvider>
        </ConfirmationDialogProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
