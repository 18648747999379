import { CircularProgress, Skeleton, Tooltip, TableCell, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { getPrescriptionsByPatientId } from "../api/Prescriptions";
import { failed, success } from "../common/Toastify";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { medicationRequestTitle, noDataAvailText } from "../VitalReadings/Constants";
import ViewIcon from "../../assets/images/view.png";
import moment from "moment";
import CustomPagination from "../common/components/Pagination/CustomPagination";
import ViewPrescriptionModal from "./ViewPrescriptionModal";

const Prescriptions = () => {


    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    const [btnLoading, setBtnLoading] = useState(false);
    const [prescriptionsList, setPrescriptionsList] = useState([]);
    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const user = useSelector((state) => state?.auth?.user);
    const [prescriptionModal, setPrescriptionModal] = useState(false);
    const [selectedPrescription, setSelectedPrescription] = useState(null);

    useEffect(() => {
        setBtnLoading(true);
        getPrescriptionsByPatientId(patientId)
            .then((res) => {
                setPrescriptionsList(res?.data)
            })
            .catch((res) => failed(res?.message))
            .finally(() => setBtnLoading(false))

    }, [patientId]);

    const handleShow = (data) => {
        setSelectedPrescription(data);
        setPrescriptionModal(true);

    };

    return (
        <>
            {prescriptionModal && <ViewPrescriptionModal modalShow={prescriptionModal} modalClose={() => setPrescriptionModal(false)} fileData={selectedPrescription} />}
            <section className="common-listing" style={{ marginLeft: '100px' }}>
                
                <div>

                    {btnLoading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80vh",
                            }}
                        >
                            <CircularProgress size={50} />
                        </div>
                    ) : (
                        <div className="table-wrap">
                            <Table responsive>
                                <thead>
                                    <tr>
                                        {medicationRequestTitle?.map((title) => (
                                            <th
                                                key={title}
                                                style={{
                                                    textAlign: title === "Edit" ? "center" : "auto",
                                                }}
                                            >
                                                {title}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {btnLoading ? (
                                        [1, 2, 3, 4, 5, 6].map((val) => (
                                            <tr key={val}>
                                                {medicationRequestTitle?.map((index) => (
                                                    <td key={index}>
                                                        <Skeleton animation="wave" />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : prescriptionsList?.length > 0 ? (
                                        prescriptionsList
                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map((data) => {
                                                return (
                                                    <tr key={data?.id}>
                                                        <td>
                                                            {moment(data?.updatedAt).format("MM/DD/YYYY | HH:mm")}
                                                        </td>
                                                        <td>{data?.requester?.display.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ')}</td>
                                                        <td>
                                                            {data?.encounter?.reference.split("/")?.[1] || "NA"}
                                                        </td>
                                                        <td
                                                            style={
                                                                (data?.status == "active"
                                                                    ? { color: "green" }
                                                                    : null) ||
                                                                (data?.status == "In Progress"
                                                                    ? { color: "grey" }
                                                                    : null) ||
                                                                (data?.status == "Cancelled"
                                                                    ? { color: "red" }
                                                                    : null)
                                                            }
                                                        >{data?.status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ')}</td>
                                                        
                                                    </tr>
                                                );
                                            })
                                    ) : (
                                        <tr>
                                            <TableCell
                                                colSpan={medicationRequestTitle.length}
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                {noDataAvailText}
                                            </TableCell>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            {prescriptionsList?.length > 0 ? (
                                <div>
                                    <CustomPagination
                                        tableData={prescriptionsList}
                                        totalRows={prescriptionsList?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        handlePage={(pageNo) => setPage(pageNo)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            </section>
        </>
    )
};

export default Prescriptions;