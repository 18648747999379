import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Event } from '@mui/icons-material';
import { safeAgeString } from "../../../utils/DateSupport";
import './date-picker.css';

function ReactDatePicker({ keyField, formik, label, minDate = moment().subtract(200, "years"), maxDate = moment(), required = true, hasValue = false, readOnly = false, displayAge = false }) {
    const [value, setValue] = useState(false);
    const [age, setAge] = useState((hasValue) ? safeAgeString(formik.values[keyField]) : "");
    const [open, setOpen] = useState(false)
    const [dateValue, setDateValue] = useState(false)
    const handleChange = (val) => {
        formik.setFieldValue(keyField, moment(val).format("MM-DD-YYYY")?.replace(/-/g, '/'));
        setValue(val);
        setAge(safeAgeString(val));
    };

    return (
        <div className="field-wrap createPatientDOB react-date-picker-wrapper" onClick={() => !readOnly && setOpen(true)}>
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <DatePicker
                label={false}
                locale='en-US'
                format="yyyy-MM-dd"
                dayPlaceholder='DD'
                monthPlaceholder='MM'
                yearPlaceholder='YYYY'
                minDate={new Date(minDate) || new Date()}
                maxDate={new Date(maxDate) || new Date()}
                value={hasValue ? new Date(formik.values[keyField] || new Date()) : value}
                onChange={(val) => {
                    handleChange(val);
                    setDateValue(true)
                }}
                readOnly={readOnly}
                onCalendarOpen={() => !readOnly && setOpen(true)}
                onCalendarClose={() => !readOnly && setOpen(false)}
                isOpen={open}
                clearIcon={null}
                openCalendarOnFocus={!readOnly}
                calendarIcon={<Event style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
            />
            <div>{dateValue && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : ((hasValue || value) && displayAge) ? <div style={{ paddingLeft: "10px" }}>{(formik.values[keyField].length > 0) ? safeAgeString(formik.values[keyField]) : age}</div> : null}</div>
        </div>
    )
}

export default ReactDatePicker;

//import React, { useEffect, useState } from "react";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

//const DatePickers = React.forwardRef(({ Name, Placeholder, value = '', minDate = new Date(), maxDate = false, handleVal, isRequired = false, edit = true, invalid = false, clearable = false }, ref) => {

//    const [startDate, setStartDate] = useState(value);
//    const [invalidDate, setInvalidDate] = useState(false);
//    useEffect(() => {
//        setInvalidDate(invalid);
//    }, [invalid])

//    const _handleDateChange = (val) => {
//        setStartDate(val);
//        handleVal(val);
//        setInvalidDate(false);
//    }
//    useEffect(()=>{
//      if(value==""){
//        setStartDate(value)
//      }
//    },[value])
//    return (
//        <>
//        <div className="field-wrap createPatientDOB margin-bottom-4">
//            <DatePicker
//                size="lg"
//                popperPlacement="bottom-start"
//                popperModifiers={[
//                  {
//                    options: {
//                      offset: [0, 0],
//                    },
//                  },
//                ]}
//                selected={startDate}
//                minDate={minDate}
//                maxDate={maxDate}
//                onChange={(val) => _handleDateChange(val)}
//                placeholderText={Placeholder}
//                onKeyDown={(e) => e.preventDefault()}
//                dropdownMode="scroll"
//                peekNextMonth
//                showMonthDropdown
//                showYearDropdown
//                isClearable={clearable}
//                className={`text-left form-control ${invalidDate && !startDate ? 'is-invalid' : ''}`}
//                disabled={!edit ? true : false}
//            />
//            </div>
//         </>
//    );
//});

//export default DatePickers;