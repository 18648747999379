import moment from "moment";
import { axios } from "../../../../lib/axios";

export const updateAppointmentRequest = (payload) => {
    return axios.put("appointment", { ...payload, isFromWeb: true })
}

export const getAppointments = ({ practitionerId, status }) => {
    return axios.get(`appointment?practitionerId=${practitionerId}&status=${status}`);
};
export const createEncounter = ({ patient, practitioner, appointmentId, appointmentTime, orgId }) => {
    const payload = {
        subject: patient,
        status: "in-progress",
        period: {
            start: appointmentTime
        },
        participant: [
            {
                individual: practitioner,
                period: {
                    start: moment().utc().format()
                }
            }
        ],
        type: "appointment",
        appointmentId,
        managingOrganization: {
            reference: `Organization/${orgId}`,
            type: "Organization",
        }
    }
    return axios.post("encounter", payload)
}

export const createEncounterInbound = ({ patient, status, practitionerId, practitionerName, proceedWith, orgId, questionnairInfo, requestedPeriod }) => {
    const payload = {
        "subject": patient,
        "managingOrganization": {
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "practitionerId": practitionerId,
        "participant": [
            {
                "individual": {
                    "reference": `Practitioner/${practitionerId}`,
                    "type": "Practitioner",
                    "display": practitionerName
                }
            }
        ],
        "period": requestedPeriod,
        "type": [
            {
                "coding": [
                    {
                        "code": "VV",
                        "display": "Virtual Visit Encounter Type"
                    }
                ]
            }
        ],
        "questionnaireResponse": questionnairInfo,
        "proceedWith": proceedWith,
        "status": status
    }
    return axios.post("encounter", payload)
}

export const editEncounter = ({ encounterId, status, patient, practitionerId, practitionerName, proceedWith, orgId, questionnairInfo, requestedPeriod }) => {
    const payload = {
        "subject": patient,
        "managingOrganization": {
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "practitionerId": practitionerId,
        "participant": [
            {
                "individual": {
                    "reference": `Practitioner/${practitionerId}`,
                    "type": "Practitioner",
                    "display": practitionerName
                }
            }
        ],
        "requestedPeriod": requestedPeriod,
        "type": [
            {
                "coding": [
                    {
                        "code": "VV",
                        "display": "Virtual Visit Encounter Type"
                    }
                ]
            }
        ],
        "questionnaireResponse": questionnairInfo,
        "proceedWith": proceedWith,
        "status": status
    }
    return axios.put(`encounter/${encounterId}`, payload)
}

export const bookAppointment = ({ practitionerId, patientId, requestedPeriod, description, comment, sessionToken, isReschdule = false, document = null, intakeQuestions }) => {
    const payload = {
        requestType: practitionerId ? "provider" : "organization",
        status: "proposed",
        description,
        practitionerId,
        comment,
        patientId,
        requestedPeriod,
        document,
        isFromWeb: false,
        sessionToken,
        isReschdule,
        intakeQuestions
    }
    return axios.post("appointment", payload)
}
export const checkShedule = ({ actorId, date }) => {
    return axios.get(`schedule/check?actorId=${actorId}&date=${date}`)
}
export const getSlots = ({ actorId, date, serviceCategoryId }) => {
    return axios.get(`schedule/patient?actorId=${actorId}&date=${date}&serviceCategoryId=${serviceCategoryId}`)
}

export const listServiceForOrg = ({ date, patientId }) => {
    return axios.get(`serviceCategory?isMobile=true&id=${patientId}`);
}

export const listOrgSlots = ({ serviceCategoryId, date, orgId, patientId }) => {
    return axios.get(`schedule/patient?serviceCategoryId=${serviceCategoryId}&date=${date}&patientId=${patientId}&org_id=${orgId}`);
}