import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useMedia } from 'react-use';

const FullCalendarComp = ({ eventsList = [], ...props }) => {
    const isWide = useMedia('(min-width: 480px)');
    return (
        <FullCalendar
            headerToolbar={isWide
                ? {
                    start: 'prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,timeGridWeek,timeGridDay'
                }
                : {
                    start: 'prev,next today',
                    center: false,
                    end: 'title'
                }
            }
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            events={eventsList}
            {...props}
        />
    );
};

export default FullCalendarComp;
