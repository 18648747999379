/**
 * Splits a contact number into phone number and extension parts.
 *
 * @param {string} val - The contact number to split. It may include an extension separated by ' ext. '.
 * @returns {Object} An object containing the phone number and extension parts.
 * - phone_number {string}: The extracted phone number. If no phone number is found, an empty string is returned.
 * - ext {string}: The extracted extension. If no extension is found, an empty string is returned.
 */
export const splitContactNumber = (val) => {
    let contactNumber = {};
    let splitPhoneNumber = val?.split(' ext. ');
    contactNumber["phone_number"] = splitPhoneNumber?.[0] || '';
    contactNumber["ext"] = splitPhoneNumber?.[1] || '';
    return contactNumber;
}

export const splitHomeContactNumber = (val) => {
    let contactNumber = {};
    let splitPhoneNumber = val?.split(' ext. ');
    contactNumber["home_phone_number"] = splitPhoneNumber?.[0] || '';
    contactNumber["ext"] = splitPhoneNumber?.[1] || '';
    return contactNumber;
}

/**
 * Creates a formatted contact number from the given values.
 *
 * @param {Object} values - The values used to create the contact number.
 * - values.phone_number - The phone number to include in the contact number. If not provided, an empty string is assumed.
 * - values.ext - The extension to include in the contact number. If not provided, it will be omitted from the contact number.
 * @returns {string} The formatted contact number combining the phone number and extension (if available) otherwise empty string.
 */
export const createContactNumber = (values) => {
    let contactNumber = values?.phone_number;
    let extSeparator = " ext. ";
    if (values?.ext) {
        contactNumber += extSeparator + values?.ext
    }
    return contactNumber;
}

/**
 * Calculates the length of a formatted phone number by removing dashes and underscores.
 *
 * @param {string} val - The formatted phone number to measure. Dashes (-) and underscores (_) will be removed before calculating the length.
 * @returns {number} The length of the formatted phone number after removing dashes and underscores.
 */
export const formattedPhoneNumberLength = (val) => {
    return val?.replace(/-|_/g, "")?.length;
}