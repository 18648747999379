import moment from "moment";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import { addManualReadingForPatient } from "../api/VitalObservation";
import { useFormik } from "formik";
import * as Yup from 'yup';
import TextInput from "../common/textfield/TextInput";

const OxygenModal = ({ oxygenModalShow, oxygenModalClose, units, programId }) => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const currentDate = moment(new Date()).format('MM/DD/YYYY');

    const formik = useFormik({
        initialValues: {
            oxygen: ""
        },
        validationSchema: Yup.object({
            oxygen: Yup.number()
                .required('Oxygen is required')
                .integer('Oxygen must be a whole number')
                .min(0, 'Oxygen must be at least 0')
                .max(100, 'Oxygen must be at most 100'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let params = {
                patientId: patientId,
                programId: programId,
                effectiveDateTime: currentDate,
                conditionName: "oxygen",
                valueQuantity: {
                    value: {
                        oxygen: values.oxygen
                    },
                    unit: {
                        oxygen: units.oxygen
                    },
                },
                device: {
                    reference: 'Device/null',
                }
            };

            addManualReadingForPatient(params)
                .then((res) => success(res?.message))
                .catch((error) => failed(error))
                .finally(() => {
                    setBtnLoading(false);
                    oxygenModalClose();
                })
        }
    });

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={oxygenModalShow} onHide={oxygenModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Oxygen Reading</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"oxygen"} formik={formik} label={`Oxygen (${units.oxygen})`} placeholder={"Enter here..."} readOnly={false} disabled={false} required={true} />
                        </Col>
                    </Row>

                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                oxygenModalClose();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Add
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )


}

export default OxygenModal;