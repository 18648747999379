import { Modal } from "react-bootstrap";
import { Button } from "../common/Button";
import { useNavigate } from "react-router-dom";

const EndSessionModalWR = ({ sessionEndModalShow, sessionEndModalClose }) => {
    const navigate = useNavigate();


    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={sessionEndModalShow} onHide={sessionEndModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Session End</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <label>The session was ended by practitioner.</label>
                <br />
                <div className="action-wrap">
                    <Button onClick={() => navigate("/app/home")} variant="secondary" title="OK">
                        OK
                    </Button>

                </div>
            </Modal.Body>

        </Modal>
    )
};

export default EndSessionModalWR;