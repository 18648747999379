import { useQuery } from "react-query";
import { onError } from "./onError";
import { axios } from "../../lib/axios";



export function usePdfRefDocumentRequestQuery({ Id, typeData, onSuccess, ...props }) {
    const fetch = () => {
        if (typeData == "MedicationRequest") {
            return axios.get(`documentreference?medicationRequestId=${Id}`)
        } else if (typeData === "Encounter") {
            return axios.get(`documentreference?encounterId=${Id}`)
        } else {
            return axios.get(`documentreference?serviceRequestId=${Id}`)
        }
    }

    const query = useQuery(
        ["view-pdf-ref-doc", Id],
        () => fetch(),
        {
            select: (data) => data?.data,
            onSuccess,
            onError,
            ...props
        }
    );

    return { ...query }
}