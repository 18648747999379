import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import TextInput from "../common/textfield/TextInput";
import SelectField from "../common/textfield/SelectField";
import TextArea from "../common/textfield/TextArea";
import Radio from "../common/textfield/Radio";
import { Button } from "../common/Button";

const QuestionnaireGetCare = ({ questionnaireModalShow, handleQuestionnaireModalClose, questions, openProviderModal }) => {
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        initialValues: (() => {
            const initialValues = {
                callerExt: ""
            };

            questions?.item?.forEach((question) => {
                switch (question.answerType) {
                    case 'freeText':
                    case 'dropDown':
                        initialValues[question.text] = '';
                        break;
                    case 'radio':
                        initialValues[question.text] = question.answerOption?.[0]?.text || '';
                        break;
                    default:
                        initialValues[question.text] = '';
                }
            });

            return initialValues;
        })(),
        validationSchema: Yup.object().shape({
            ...questions?.item?.reduce((validationSchema, question) => {
                if (question.required) {
                    validationSchema[question.text] = Yup.string().required(`${question.text} is required`);
                }

                if (question.tag === 'TELEPHONE') {
                    validationSchema[question.text] = Yup.string()
                        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number. Please enter a valid format like (123) 456-7890')
                        .required(`${question.text} is required`);
                }
                return validationSchema;
            }, {}),
        }),
        onSubmit: (values) => {


            const updatedQuestionnaireData = questions?.item?.map((question) => {
                return {
                    ...question,
                    patientResponse: values[question.text],
                };
            });

            if (values?.callerExt !== '') {
                updatedQuestionnaireData.push({
                    "callerExt": values?.callerExt
                });
            }

            handleQuestionnaireModalClose();
            openProviderModal(updatedQuestionnaireData);
        }
    });

    const handleRadioChange = (question, value) => {
        formik.setFieldValue(question, value);
    };


    return (
        <section>
            {isLoading ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <Modal
                    backdropClassName
                    backdrop={'static'}
                    size='lg'
                    show={questionnaireModalShow} onHide={handleQuestionnaireModalClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered className="custom-dialog">

                    <Modal.Header closeButton className="border-0">
                        <Modal.Title id="contained-modal-title-vcenter">Questions</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <form className="common-form border-fields" onSubmit={formik.handleSubmit}>

                            {questions?.item?.map((question, questionIndex) => (
                                <div key={questionIndex}>
                                    {question.answerType === 'freeText' && question.tag !== 'TELEPHONE' && (
                                        <TextArea
                                            keyField={question.text}
                                            label={question.text}
                                            formik={formik}
                                            placeholder={"Type your answer here"}
                                        />
                                    )}

                                    {question.answerType === 'freeText' && question.tag === 'TELEPHONE' && (
                                        <Row>

                                            <Col>
                                                <TextInput
                                                    keyField={question.text}
                                                    label={'Return Telephone Number'}
                                                    type="phone"
                                                    formik={formik}
                                                    placeholder={"Contact Number here"}
                                                />
                                            </Col>

                                            <Col>
                                                <TextInput keyField={"callerExt"} type="ext" label={"Ext."} formik={formik} placeholder={"Ext"} required={false} />
                                            </Col>

                                        </Row>

                                    )}

                                    {question.answerType === 'dropDown' && (
                                        <SelectField
                                            keyField={question.text}
                                            label={question.text}
                                            formik={formik}
                                            options={question.answerOption.map(option => ({ value: option.text, name: option.text }))}
                                        />
                                    )}

                                    {question.answerType === 'radio' && (
                                        <div>
                                            <div>{question.text}</div>
                                            {question.answerOption?.map((option, optionIndex) => (
                                                <Radio
                                                    key={optionIndex}
                                                    keyField={option.text}
                                                    name={question.text}
                                                    value={option.text}
                                                    radioLabel={option.text}
                                                    label={option.text}
                                                    isChecked={formik?.values[question.text] === option.text}
                                                    formik={formik}
                                                    handleChange={(value) => handleRadioChange(question.text, value)}
                                                />
                                            ))}
                                        </div>
                                    )}


                                </div>
                            ))}

                            <div className="action-wrap">
                                <Button type="submit" disabled={isLoading}>
                                    {isLoading ? <CircularProgress size={24} /> : 'Next'}
                                </Button>
                            </div>
                        </form>

                    </Modal.Body>
                </Modal>
            )}
        </section>
    )
}

export default QuestionnaireGetCare;