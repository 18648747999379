import { useEffect, useState } from "react";
import { FullPageSpinner } from "../common/Spinner/FullPageSpinner";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { Skeleton, TableCell, Tooltip, lighten } from '@mui/material';
import { CalendarMonth, DescriptionOutlined, FilePresentOutlined, ListAlt, VideocamOutlined } from "@mui/icons-material";
import Search from './Search';
import { useFormik } from "formik";
import { dashTypes, eventType } from './Constants';
import FullCalendar from '../common/textfield/FullCalendar';
import moment from "moment";
import { decryptData, encryptData } from "../EncryptDecrypt";
import { Link, useNavigate } from "react-router-dom";
import Video from "../../assets/images/Video.svg";
import { Table } from "react-bootstrap";
import CustomPagination from "../common/components/Pagination/CustomPagination";
import { listAppointmentsByStatus } from "../api/Appointments";
import { failed, success } from "../common/Toastify";
import { useSelector } from "react-redux";
import Audio from '../../assets/images/audioCall.png';
import { Button } from '../common/Button';
import ViewAppointmentModal from "../Visits/ViewAppointmentModal";
import RescheduleModal from "../Visits/RescheduleModal";
import { Buffer } from "buffer";


const AppointmentsList = ({ appointments, selectedTab, dashboard = false, setCount = () => { } }) => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;
    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);
    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [calendarView, setCalendarView] = useState(true);
    const [btnActive, setBtnActive] = useState("today");
    const [todayAppts, setTodayAppts] = useState([]);
    const [upcomingAppts, setUpcomingAppts] = useState([]);
    const [requestedCount, setRequestedCount] = useState(0);
    const [todayCount, setTodayCount] = useState(0);
    const [upcomingCount, setUpcomingCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [showAppointment, setShowAppointment] = useState(false);
    const [scheduledAppointments, setScheduledAppointments] = useState([]);
    const [completedAppointments, setCompletedAppointments] = useState([]);

    const [viewModalShow, setViewModalShow] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState("");
    const [rescheduleModal, setRescheduleModal] = useState(false);


    const formik = useFormik({
        initialValues: {
            type: "booked",
        },
    });

    const handleFilterData = (data) => {
        setFilterData(data);
        page !== 0 && setPage(0);
    };

    const handleShowFile = async (data) => {
        setShowAttachmentModal(true)
        setAttachmentUrl(data?.url)
    }

    const handleConnect = async (data) => {
        if (data?.isHostJoined !== true) {
            failed("Please wait for the host to start session");
        } else {
            if (data?.sessionToken) {
                setIsLoading(true)
                const base64Url = await data?.sessionToken?.split(".")[1],
                    base64 = await base64Url?.replace(/-/g, "+")?.replace(/_/g, "/"),
                    buff = await Buffer.from(base64, "base64"),
                    payloadinit = await buff.toString("ascii"),
                    parseddata = JSON.parse(payloadinit || "{}"),
                    patient = data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor,
                    practitioner = data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;
                let encounterId = data?.encounterId ? data?.encounterId : null;
                let topic = parseddata?.tpc.trim();
                await navigate("/app/zoom-meeting", { state: { encounterId, appointmentData: data, topic: encryptData(parseddata?.tpc), sessionToken: data?.sessionToken, password: encryptData(parseddata?.password) } });

            } else { failed("Session token does not exist!") }
        }

    }

    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    return (
        <section className={dashboard ? "dashboard" : "common-listing"}>
            {isLoading ? <FullPageSpinner /> : null}

            <div className="dash-buttons">
                <Search list={appointments} filterData={handleFilterData} />
            </div>

            <>
                <div className="table-wrap">
                    <Table responsive>
                        <thead>
                            <tr>
                                {!dashboard && <th>Requests ID</th>}
                                <th>Practitioner Name</th>
                                <th>Reason</th>
                                <th>Appointment Date</th>
                                <th>Appointment Time</th>
                                {!dashboard && <th>Attachments</th>}
                                <th style={{ textAlign: "center" }}>Communication Channel</th>
                                {formik?.values?.type !== "cancelled" ?
                                    <th style={{ textAlign: "center" }}>Action</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                    <tr key={val}>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                    </tr>
                                ))
                            ) : filterData?.length > 0 ? (
                                filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                    const name = decryptData(data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor?.display?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
                                    var a = moment().format("X");
                                    var b = moment(data?.requestedPeriod?.[0]?.start?.replace("Z", "")).format("X");
                                    return (
                                        <tr onClick={() => {
                                            setSelectedAppointment(data);
                                            setViewModalShow(true);
                                        }}>
                                            {!dashboard && <td className="name-text">{data?.id}</td>}
                                            <td><span>{name}</span></td>
                                            <td>{data?.description.length > 10 ? `${data?.description.substring(0, 20)}...` : data?.description}</td>
                                            <td>{moment(data?.requestedPeriod[0]?.start).format('ddd, MMM DD')}</td>
                                            <td>{moment(data?.requestedPeriod[0]?.start).format('HH:mm')} - {moment(data?.requestedPeriod[0]?.end).format('HH:mm')}</td>

                                            <td>
                                                <div className='action-wrap' style={{ alignItems: "center" }}>
                                                    <>
                                                        {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                                            <Tooltip title={data?.intakeQuestions?.callerNumber}>
                                                                <img src={Audio} alt="Audio Channel" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                                                <img src={Video} alt="Video Channel" />
                                                            </Tooltip>
                                                        )}
                                                    </>

                                                </div>
                                            </td>
                                            {formik?.values?.type !== "cancelled" ?
                                                <td onClick={(e) => { e.stopPropagation(); }}>
                                                    <div className="action-wrap">
                                                        <Button
                                                            variant={"primary"}
                                                            onClick={() => {
                                                                setSelectedAppointment(data);
                                                                setViewModalShow(true);
                                                            }}>
                                                            View
                                                        </Button>
                                                        {(selectedTab === "Upcoming" && (data?.intakeQuestions?.preferredChannel !== 'phone' && data?.intakeQuestions?.preferredChannel !== 'Phone')) && <Button
                                                            variant="primary"
                                                            className="success-btn"
                                                            style={{
                                                                margin: "0 0 0 9px",
                                                                backgroundColor: !data.isHostJoined ? lighten('#00C853', 0.4) : '#00C853',
                                                            }}
                                                            onClick={() => {
                                                                if (data?.isHostJoined !== true) {
                                                                    failed("Please wait for practitioner to start the session.");
                                                                } else {
                                                                    handleConnect(data);
                                                                }
                                                            }}
                                                        >Join</Button>}
                                                    </div>
                                                </td> : null}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                {filterData?.length > 0
                    ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page}
                        handlePage={(pageNo) => setPage(pageNo)} />
                    : null}
            </>

            {viewModalShow && <ViewAppointmentModal appointmentModalShow={viewModalShow} appointmentModalClose={() => {
                setSelectedAppointment("");
                setViewModalShow(false);
            }} appointmentData={selectedAppointment} handleRescheduleClick={() => {
                setViewModalShow(false);
                setRescheduleModal(true);
            }} />}

            {rescheduleModal && <RescheduleModal rescheduleModalShow={rescheduleModal} rescheduleModalClose={() => setRescheduleModal(false)} previousAppointmentData={selectedAppointment} />}


        </section>
    )

}

export default AppointmentsList;