import React from "react";

const TextArea = ({ label, keyField, formik, required = true, hideRequired = false, isError = false, ...props }) => {
    return (
        <div className="field-wrap">
            <label htmlFor={keyField}>
                {label}
                {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <div>
                <textarea style={{ minHeight: "5rem" }} id={keyField} {...formik?.getFieldProps(keyField)} {...props} />
            </div>
            <div>{isError ? <div className="error-text">{isError}</div> : formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    );
};

export default TextArea;
