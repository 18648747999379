import React, { useState } from "react";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userlogin } from "../slice/authService"
import { userLogin } from "../api/AuthService"
import { failed, success } from "../common/Toastify";
import TextInput from "../common/textfield/TextInput";
import { Button } from "../common/Button";
import User from '../../assets/images/person.png';
import Lock from '../../assets/images/Lock.png';
import './login.css'


function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [btnLoading, setBtnLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: yup.object({
            username: yup.string().required('Username Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
            password: yup.string().required('Password Required').matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            userLogin({ password: values.password, username: values.username?.toLowerCase() }).then((res) => {
                if (res.status === true) {
                    switch (res.authType) {
                        case "verify":
                            navigate("/auth/setpassword", { state: values });
                            break;
                        case "reset":
                            navigate("/auth/verifiedUserSetPass", { state: { username: values.username, session: res.session } });
                            break;
                        case "success":
                            dispatch(userlogin(res.user));
                            break;
                        case "mfa":
                            navigate("/auth/verifyMFA", { state: { username: values.username, password: values.password } });
                            break;
                        default:
                            break;
                    }
                    success(res.message = "Successfully Logged In");
                } else {
                    if (res.authType === 'expire') {
                        navigate("/auth/reset-temporary-pass", { state: { email: values?.username } });
                    }
                    failed(res.message);
                }
            }).finally(() => setBtnLoading(false));
        },
    });


    return (
        
        <form className="custom-form" onSubmit={formik.handleSubmit}>
            
            <h3>Login</h3>
            <TextInput icon={User} keyField={"username"} label={"User name"} formik={formik} placeholder={"User name"} />
            <TextInput type="password" icon={Lock} keyField={"password"} label={"Password"} formik={formik} placeholder={"Password"} />
            <div className="page-link">
                <Link to="/auth/forgotpassword" title="Forgot Password">Forgot Password?</Link>
                <Link to="" title="Privacy Policy">Privacy Policy </Link>
            </div>
            <div className="btn-wrap" style={{ textAlign: "center" }}>
                <Button type="submit" className="custom-btn" isLoading={btnLoading}>Login</Button>
            </div>
        </form>
    );
}

export default Login;