import moment from "moment";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import { addManualReadingForPatient } from "../api/VitalObservation";
import { useFormik } from "formik";
import * as Yup from 'yup';
import TextInput from "../common/textfield/TextInput";

const TemperatureModal = ({ temperatureModalShow, temperatureModalClose, units, programId }) => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const currentDate = moment(new Date()).format('MM/DD/YYYY');

    const formik = useFormik({
        initialValues: {
            temperature: ""
        },
        validationSchema: Yup.object({
            temperature: Yup.number()
                .typeError("Must be a number")
                .min(0, "Must be greater than or equal to 0")
                .max(500, "Must be less than or equal to 999")
                .test(
                    "decimal-places",
                    "Only one decimal place is allowed",
                    value => (value + "").match(/^\d+(\.\d{0,1})?$/)
                )
                .required("Temperature is required"),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let params = {
                patientId: patientId,
                programId: programId,
                effectiveDateTime: currentDate,
                conditionName: "oxygen",
                valueQuantity: {
                    value: {
                        temperature: values.temperature
                    },
                    unit: {
                        temperature: units.temperature
                    },
                },
                device: {
                    reference: 'Device/null',
                }
            };

            addManualReadingForPatient(params)
                .then((res) => success(res?.message))
                .catch((error) => failed(error))
                .finally(() => {
                    setBtnLoading(false);
                    temperatureModalClose();
                })
        }
    });

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={temperatureModalShow} onHide={temperatureModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Temperature Reading</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"temperature"} formik={formik} label={`Temperature (${units.temperature})`} placeholder={"Enter here..."} readOnly={false} disabled={false} required={true} />
                        </Col>
                    </Row>

                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                temperatureModalClose();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Add
                        </Button>
                    </div>
                </form>
            </Modal.Body>


        </Modal>
    )

}

export default TemperatureModal;