import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "./common/textfield/TextInput";
import { useLocation } from "react-router-dom";
import { Button } from "./common/Button";
import { failed, success } from "./common/Toastify";
import { verifyMFA } from "./api/AuthService";
import { useDispatch } from "react-redux";
import { userlogin } from "./slice/authService";


function Verify() {
    const Dispatch = useDispatch();
    const location = useLocation();
    const [btnLoading, setBtnLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            username: location?.state?.username,
            password: location?.state?.password,
            code: "",
        },
        validationSchema: Yup.object({
            code: Yup.number().typeError('you must specify a number').required('Please Enter your Verification Code'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true)
            verifyMFA({ ...values }).then(({ data }) => {
                if (data.status === true) {
                    Dispatch(userlogin(data.user));
                    localStorage.setItem("authentication", data.token);
                    success(data.message);
                    console.log("Log from Verify.js file");
                } else {
                    failed(data.error);
                }
            }).catch(res => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => setBtnLoading(false));
        },
    });

    return (
        <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
            <Row>
                <Col>
                    <TextInput keyField={"code"} label={"Verification Code"} formik={formik} placeholder={"999999"} />
                </Col>
            </Row>
            <div className="btn-wrap" style={{ display: 'flex' }}>
                <Button type="submit" isLoading={btnLoading}>Submit</Button>
            </div>
        </form>
    );
}

export default Verify;