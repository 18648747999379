import { axios } from "../../../lib/axios";

export const getCareAppointmentQuestionnaireID = (orgId) => {
    return axios.get(`organization/${orgId}`);
}

export const getCareAppointmentQuestionsById = (questionnaireId) => {
    return axios.get(`questionnaire/${questionnaireId}`);
}

export const feedbackSubmit = (payload) => {
    return axios.post(`questionnaireresponse`, payload);
}