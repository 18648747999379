import { Col, Row } from "react-bootstrap";
import DashboardCard from "./dashboard-card";
import AppointmentIcon from '../../assets/images/appointmentsDashboard.png';
import TimeIcon from '../../assets/images/time@2x.png';
import CompleteIcon from '../../assets/images/check_blue.png';
import { useEffect, useRef, useState } from "react";
import "./dashboard.css";
import { listAppointmentsByStatus } from "../api/Appointments";
import { useSelector } from "react-redux";
import { failed } from "../common/Toastify";
import AppointmentsList from "./AppointmentsList";
import { CircularProgress } from "@mui/material";
import moment from "moment";

const Appointments = () => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;
    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Upcoming");
    const [personCounts, setPersonCounts] = useState([]);
    const [scheduledAppointments, setScheduledAppointments] = useState([]);
    const [requestedAppointments, setRequestedAppointments] = useState([]);
    const [completedAppointments, setCompletedAppointments] = useState([]);
    const upcomingAppointmentsRef = useRef(null);
    const [appointmentCount, setAppointmentCount] = useState(0);



    useEffect(() => {
        if (selectedTab === "Completed") {
            fetchData("completed");
        } else if (selectedTab === "Upcoming") {
            fetchData("booked");
        } else if (selectedTab === "Requested") {
            fetchData("proposed");
        }

    }, [selectedTab]);

    const fetchData = (status) => {
        if (status === "completed") {
            setBtnLoading(true);
        }
        listAppointmentsByStatus(patientId, status)
            .then((res) => {
                if (status === "completed") {
                    setCompletedAppointments(res?.data);
                } else if (status === "booked") {
                    setScheduledAppointments(res?.data);
                } else if (status === "proposed") {
                    setRequestedAppointments(res?.data);
                }
            })
            .catch((res) => failed(res?.message))
            .finally(() => setBtnLoading(false));
    };

    useEffect(() => {
        fetchData(selectedTab);

        const intervalId = setInterval(() => {
            fetchData("booked");
            fetchData("proposed");
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const sortedByDate = (data, direction = 'desc') => {
        return data?.sort((a, b) => {
            const a_date = moment.tz(a?.requestedPeriod[0]?.start, 'UTC');
            const b_date = moment.tz(b?.requestedPeriod[0]?.start, 'UTC');
            return direction === 'asc' ? a_date - b_date : b_date - a_date;
        });
    };


    return (
        <>
            <section className="common-listing" style={{ marginLeft: "75px" }}>
                {btnLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh",
                        }}
                    >
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <>
                        <div className="four-cards">
                            <Row>
                                <Col sm={6} md={2} onClick={() => setSelectedTab("Upcoming")}>
                                    <DashboardCard
                                        title={"Upcoming"}
                                        icon={AppointmentIcon}
                                        selected={selectedTab === "Upcoming"}
                                    />
                                </Col>

                                <Col sm={6} md={2} onClick={() => setSelectedTab("Requested")}>
                                    <DashboardCard
                                        title={"Requested"}
                                        icon={TimeIcon}
                                        selected={selectedTab === "Requested"}
                                    />
                                </Col>

                                <Col sm={6} md={2} onClick={() => setSelectedTab("Completed")}>
                                    <DashboardCard
                                        title={"Completed"}
                                        icon={CompleteIcon}
                                        selected={selectedTab === "Completed"}
                                    />
                                </Col>

                            </Row>

                        </div>

                        <Row>
                            <Col style={{ display: 'block' }}>
                                <div ref={upcomingAppointmentsRef} className="dash-wrap">
                                    {selectedTab === "Completed" && <AppointmentsList appointments={sortedByDate(completedAppointments)} dashboard={true} selectedTab={selectedTab} setCount={setAppointmentCount} />}
                                    {selectedTab === "Requested" && <AppointmentsList appointments={requestedAppointments} dashboard={true} selectedTab={selectedTab} setCount={setAppointmentCount} />}
                                    {selectedTab === "Upcoming" && <AppointmentsList appointments={scheduledAppointments} dashboard={true} selectedTab={selectedTab} setCount={setAppointmentCount} />}

                                </div>
                            </Col>

                        </Row>
                    </>
                )}
            </section>
        </>

    )

}

export default Appointments;