import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDiseaseForPatient } from "../api/HealthCondition";
import { failed } from "../common/Toastify";
import { CircularProgress } from "@mui/material";

const Condition = () => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [diseaseData, setDiseaseData] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        setBtnLoading(true);
        getDiseaseForPatient(patientId)
            .then((res) => {
                setDiseaseData(res?.data || []);
            })
            .catch((error) => failed(error))
            .finally(() => setBtnLoading(false));
    }, []);


    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>
            

            <div>
                {btnLoading ? (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh"
                    }}>
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <div>
                        {diseaseData && diseaseData.length > 0 ? (
                            diseaseData.map((disease, index) => (
                                <div className="custom-card p-4" style={{ marginBottom: "20px" }}>
                                    <p style={{ fontWeight: 'bold' }}>{disease.diseaseName}</p>
                                    <p>{disease.diseaseDesc}</p>
                                </div>
                            ))
                        ) : (
                            <div className="custom-card p-4">
                                <p>No Data Available</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </section>
    )
};

export default Condition;