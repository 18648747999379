import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./sidebar.css";

import PersonIcon from '../../../assets/images/person-white.png';
import HomeIcon from '../../../assets/images/home.png';
import SettingsIcon from '../../../assets/images/settings.png';
import SessionIcon from '../../../assets/images/session.png';

export const menu = () => [
    { name: "Home", enabled: true, path: "/app/home", icon: HomeIcon },
    { name: "Visits", enabled: true, path: "/app/appointments", icon: SessionIcon },
    { name: "Profile", enabled: true, path: "/app/user-profile", icon: PersonIcon },
    { name: "Settings", enabled: true, path: "/app/settings", icon: SettingsIcon },
];

function Sidebar() {
    const location = useLocation();
    const pathname = location?.pathname;
    const sidebarMenu = menu();

    return (
        <>
            <section className="sidebar-wrap">
                <nav className="flex-md-column sidebar-inner">
                    {sidebarMenu.map((item, index) => (
                        item.enabled && (

                            <NavLink
                                key={index}
                                to={item.path}
                                className={`nav-link ${pathname === item.path ? "active" : ""}`}
                                title={item.name}
                            >
                                <div className="nav-item">
                                    <div className="icon-wrap">
                                        <img src={item.icon} alt={item.name} />

                                    </div>
                                    <p>{item.name}</p>
                                </div>
                            </NavLink>

                        )

                    ))}
                </nav>
            </section>
        </>
    );
}

export default Sidebar;