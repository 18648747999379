import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { getAvailablePractitioners } from "../api/Practitioners";
import { CircularProgress } from "@mui/material";
import { Button } from "../common/Button";
import SelectField from "../common/textfield/SelectField";
import { Col, Form, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import { decryptData } from "../EncryptDecrypt";



const ProviderPreferenceModal = ({ providerModalShow, handleProviderModalClose, formikQuestionnaire, openBookAppointmentModal }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const [providerPreference, setProviderPreference] = useState("practitioner");
    const [practitionerList, setPractitionerList] = useState([]);
    const [practitionerId, setPractitionerId] = useState('');
    const patientLocation = (formikQuestionnaire?.location || '');
    const orgId = useSelector((state) => state?.auth?.user?.orgId);

    let isReadOnly = false;

    const formik = useFormik({
        initialValues: {
            practitioner: ""
        },
        validationSchema: () => {
            if (providerPreference === "practitioner") {
                return Yup.object({
                    practitioner: Yup.string().required("Provider is required")
                });
            } else {
                return Yup.object({});
            }
        },
        onSubmit: () => {
            openBookAppointmentModal(providerPreference, formikQuestionnaire, practitionerId);
        }
    });

    useEffect(() => {
        getAvailablePractitioners(orgId, patientLocation)
            .then((res) => {
                setPractitionerList(res.result.map((ele) => (ele = { value: ele?.id, name: decryptData(ele?.name?.[0]?.text), id: ele?.id })))
            })
            .catch((res) => {
                console.log("error fetching practitioner list", res)
            })
    }, []);

    const handlePractitionerChange = (event) => {
        setPractitionerId(event?.target?.value);
        formik.setFieldValue("practitioner", event?.target?.value);
    };


    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={providerModalShow}
            onHide={handleProviderModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Select Provider Preference</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            {["practitioner", "no preference"].map((val) => (
                                <Form.Check
                                    key={val}
                                    style={{ textTransform: "capitalize", fontSize: "18px", marginRight: "45px" }}
                                    inline
                                    checked={providerPreference === val}
                                    type={"radio"}
                                    label={val === "practitioner" ? "Care Provider" : val}
                                    id={val}
                                    name="practitioner preference"
                                    onChange={(e) => {
                                        setProviderPreference(e.target.id);
                                        if (e.target.id === "no preference") {
                                            setPractitionerId('');
                                        } else {
                                            setPractitionerId(formik.values.practitioner)
                                        }
                                    }}
                                />
                            ))}
                        </Col>
                    </Row>
                    <br />
                    {providerPreference === "practitioner" && (
                        <Row>
                            <SelectField keyField={"practitioner"} label={"Select Provider"} formik={formik} options={practitionerList} readOnly={isReadOnly} onChange={handlePractitionerChange} />
                        </Row>
                    )}

                    <div className="action-wrap">
                        <Button type="submit" disabled={btnLoading}>
                            {btnLoading ? <CircularProgress size={24} /> : 'Next'}
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ProviderPreferenceModal;