import { Button } from "../common/Button";
import { useSelector } from "react-redux";
import { getSingleUser } from '../api/Individual';
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { FormikObj } from "./Constants";
import { CircularProgress } from "@mui/material";
import TextInput from "../common/textfield/TextInput";
import InputFile from '../common/textfield/InputFile';
import SelectField from '../common/textfield/SelectField';
import { Col, Row } from "react-bootstrap";
import ReactDatePicker from "../common/textfield/ReactDatePicker";
import { useNavigate } from "react-router-dom";
import { failed, success } from "../common/Toastify";
import { decryptData } from "../EncryptDecrypt";
import { splitHomeContactNumber } from '../../utils/phoneNumberSupport';
import { handleFileUpload } from '../../utils/ImageToBase64';
import { updatePatientProfile } from "../api/Profile";

const UserProfile = () => {
    const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [file, setFile] = useState();
    const navigate = useNavigate();
    const userData = useRef();

    const [genderOptions, setGenderOptions] = useState([
        { value: "male", name: "Male" },
        { value: "female", name: "Female" },
        { value: "other", name: "Others" },
    ]);

    const formik = useFormik({
        ...FormikObj,
        onSubmit: async (values) => {
            let base64ProfileImage = "";
            if (values?.profileImage) {
                base64ProfileImage = await handleFileUpload(values?.profileImage);
            }
            let profileExtension = values?.profileImage?.type?.split("/")[1];

            setBtnLoading(true);

            let data = {
                phone: values?.phone,
                birthDate: values?.birthDate,
                gender: values?.gender,
                firstName: values?.firstName,
                lastName: values?.lastName,
                photo: {
                    url: base64ProfileImage,
                    extension: profileExtension
                }
            }


            updatePatientProfile(data, patientId).then((res) => {
                if (res?.status === true) {
                    success(res?.message);
                    navigate("/app/home");
                } else {
                    failed(res?.error);
                }
            }).catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res?.message)
            }).finally(() => setBtnLoading(false));
        }
    })


    function getImageExtension(base64Data) {
        const base64String = base64Data.replace(/^data:image\/(png|jpeg|jpg|gif);base64,/, '');
        const byteCharacters = atob(base64String);
        const byteArray = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
        }
        if (byteArray[0] === 0x89 && byteArray[1] === 0x50) {
            return 'png';
        } else if (byteArray[0] === 0xFF && byteArray[1] === 0xD8) {
            return 'jpeg';
        } else if (byteArray[0] === 0x47 && byteArray[1] === 0x49) {
            return 'gif';
        } else if (byteArray[0] === 0x42 && byteArray[1] === 0x4D) {
            return 'bmp';
        } else {
            return 'unknown';
        }
    }



    useEffect(() => {
        setBtnLoading(true);
        const apiBody = {
            id: patientId,
            type: "Patient"
        };

        getSingleUser(apiBody)
            .then((res) => {
                userData.current = res?.data;
                formik.setFieldValue(
                    "firstName",
                    decryptData(res?.data?.name?.[0]?.given?.[0] || "")
                );
                formik.setFieldValue(
                    "lastName",
                    decryptData(res?.data?.name?.[0]?.family || "")
                );
                res?.data?.birthDate &&
                    formik.setFieldValue(
                        "birthDate",
                        decryptData(res?.data?.birthDate || "")?.replace(/-/g, '/')
                    );
                formik.setFieldValue("gender", decryptData(res?.data?.gender));
                formik.setFieldValue(
                    "phone",
                    splitHomeContactNumber(
                        decryptData(
                            res?.data?.telecom?.find((ele) => ele?.system === "phone")
                                ?.value || ""
                        )
                    ).home_phone_number.replace(/^\+1/, '')
                );
                formik.setFieldValue(
                    "photo", res?.data?.photo
                )


                formik.setFieldValue(
                    "email",
                    res?.data?.telecom?.find((ele) => ele?.system === "email")?.value || "");

            }).catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
            }).finally(() => setBtnLoading(false));
    }, []);


    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>
            
            <div>

                {btnLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh",
                        }}
                    >
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <div className="custom-card p-4">
                        <form
                            className="common-form border-fields"
                            onSubmit={formik.handleSubmit}
                        >
                            <Row>
                                <Col>
                                    <TextInput
                                        style={{ textTransform: "capitalize" }}
                                        keyField={"firstName"}
                                        label={"First Name"}
                                        formik={formik}
                                        placeholder={"First Name"}
                                    />
                                </Col>
                                <Col>
                                    <TextInput
                                        style={{ textTransform: "capitalize" }}
                                        keyField={"lastName"}
                                        label={"Last Name"}
                                        formik={formik}
                                        placeholder={"Last Name"}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <ReactDatePicker
                                        keyField={"birthDate"}
                                        label={"Date of Birth"}
                                        formik={formik}
                                        placeholder={"Select Date"}
                                        hasValue={formik?.values?.birthDate}
                                    />
                                </Col>
                                <Col>
                                    <TextInput
                                        keyField={"phone"}
                                        type="phone"
                                        label={"Phone Number"}
                                        formik={formik}
                                        placeholder={"Phone Number"}
                                    />
                                </Col>

                            </Row>
                            <Row>

                                <Col>
                                    <TextInput
                                        keyField={"email"}
                                        label={"Email"}
                                        formik={formik}
                                        placeholder={"Email"}
                                        disabled={true}
                                    />
                                </Col>
                                <Col>
                                    <SelectField
                                        keyField={"gender"}
                                        label={"Gender"}
                                        formik={formik}
                                        options={genderOptions?.map((option) => ({
                                            ...option,
                                            selected: option?.value === formik?.values?.gender,
                                        }))}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <InputFile
                                        keyField={"profileImage"}
                                        label={"Upload Profile Image"}
                                        formik={formik}
                                        placeholder={"Upload Profile Image"}
                                        setFile={setFile}
                                        file={file}
                                        imageUrl={userData?.current?.photo?.url}
                                    />
                                </Col>

                            </Row>

                            <div className="btn-wrap">
                                <Button type="submit" isLoading={btnLoading}>
                                    Update Details
                                </Button>
                                <Button onClick={() => navigate("/app/home")} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </section>
    );
}

export default UserProfile;