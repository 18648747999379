import { useSelector } from 'react-redux';
import { useGetOrgLicensedProducts } from "../../hooks/useGetOrgLicensedProducts";
import MedicationIcon from '../../assets/images/medication@2x.png';
import GetCareIcon from '../../assets/images/getCare@2x.png';
import PrescriptionIcon from '../../assets/images/prescriptions@2x.png';
import ReadingIcon from '../../assets/images/reading@2x.png';
import { useEffect, useState } from 'react';
import DiseaseIcon from '../../assets/images/disease@2x.png';
import DashboardIcon from '../../assets/images/dashboard@2x.png';
import AppointmentIcon from '../../assets/images/appointment@2x.png';
import RequisitionIcon from '../../assets/images/callBack@2x.png';
import RelatedPersonIcon from '../../assets/images/dependant@2x.png';
import { useGetQuestionnaireById } from '../../hooks/useGetQuestionnaireById';
import { getCareAppointmentQuestionnaireID, getCareAppointmentQuestionsById } from '../api/Questionnaires';
import './home.css';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import BookAppointmentModal from '../Questionnaire/BookAppointmentModal';
import ProviderPreferenceModal from '../Questionnaire/ProviderPreferenceModal';
import { Modal } from "react-bootstrap";
import TimeIcon from '../../assets/images/time@3x.png';
import CalendarIcon from '../../assets/images/calender@3x.png';
import QuestionnaireGetCareModal from '../Questionnaire/QuestionnaireGetCare';
import QuestionnaireWaitingRoom from '../Questionnaire/QuestionnaireWaitingRoom';
import WaitingScreen from '../Questionnaire/WaitingScreen';
import { getPatientDataForRelatedPerson, getRelatedPersonByRef } from '../api/RelatedPerson';
import { failed } from '../common/Toastify';
import { getPatientData, getRelatedPersonForPatient, getSingleUser } from '../api/Individual';
import RelatedPersonModal from './RelatedPersonModal';
import GetCareModal from './GetCareModal';


function Home() {
    const userEmail = useSelector((state) => state?.auth?.user?.email);
    const user = useSelector((state) => state?.auth);
    const [home_menu, setMenu] = useState([]);
    const orgId = useSelector((state) => state?.auth?.user?.orgId);
    const licensedProductsFetch = useGetOrgLicensedProducts({ orgId });
    const licensedProducts = licensedProductsFetch.data;
    const [loading, setLoading] = useState(true);
    const [getCareQuestionId, setGetCareQuestionId] = useState('');
    const [getCareQuestions, setGetCareQuestions] = useState({});
    const [getCareModalShow, setGetCareModalShow] = useState(false);
    const [getCareQuestionnaireModal, setGetCareQuestionnaireModal] = useState(false);
    const [providerModalShow, setProviderModalShow] = useState(false);
    const [questionnaireData, setQuestionnaireData] = useState(null);
    const [bookAppointmentModalShow, setBookAppointmentModalShow] = useState(false);
    const [selectedProviderPreference, setSelectedProviderPreference] = useState("");
    const [selectedPractitionerId, setSelectedPractitionerId] = useState("");
    const [waitingRoomQuestionnaireID, setWaitingRoomQuestionnaireID] = useState("");
    const [WRmodalShow, setWRModalShow] = useState(false);
    const [waitingRoomQuestions, setWaitingRoomQuestions] = useState({});
    const [waitingScreenShow, setWaitingScreenShow] = useState(false);
    const [WRQuestionnaireResponse, setWRQuestionnaireResponse] = useState({});
    const navigate = useNavigate();
    const [relatedPerson, setRelatedPerson] = useState(null);
    const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [relatedPersonModal, setRelatedPersonModal] = useState(false);
    const currentUser = sessionStorage.getItem("relatedPersonProfile");
    const [loggedInUserInfo, setLoggedInUserInfo] = useState({});
    const [waitingRoomActive, setWaitingRoomActive] = useState(false);
    console.log("Get care modal: ", getCareModalShow);


    useEffect(() => {
        const fetchData = async () => {
            try {
                await licensedProductsFetch;
                setLoading(false);
                initializeMenu();
            } catch (error) {
                console.error('Error fetching licensedProducts:', error);
                // Handle error state
                setLoading(false);
            }
        };

        fetchData();
    }, [licensedProducts]);

    useEffect(() => {
        const fetchCareAppointmentData = async () => {
            try {
                const res = await getCareAppointmentQuestionnaireID(orgId);
                if (res?.data?.isWaitingRoom === true) {
                    setWaitingRoomActive(true);
                }
                const getCareQuestionId = res?.data?.assignedQuestionnaire?.["getcare-appointment"];
                setGetCareQuestionId(getCareQuestionId);
                const getCareQuestionsRes = await getCareAppointmentQuestionsById(getCareQuestionId);
                setGetCareQuestions(getCareQuestionsRes?.data);
            } catch (error) {
                console.log("Error fetching appointment questionnaire data:", error);
            }
        };

        fetchCareAppointmentData();
    }, [getCareModalShow]);

    useEffect(() => {
        const fetchWaitingRoomData = async () => {
            try {
                const res = await getCareAppointmentQuestionnaireID(orgId);
                const waitingRoomQuestionnaireID = res?.data?.assignedQuestionnaire?.["getcare-waitingroom"];
                setWaitingRoomQuestionnaireID(waitingRoomQuestionnaireID);
                if (waitingRoomQuestionnaireID && waitingRoomActive) {
                    const waitingRoomQuestionsRes = await getCareAppointmentQuestionsById(waitingRoomQuestionnaireID);
                    setWaitingRoomQuestions(waitingRoomQuestionsRes?.data);
                }
            } catch (error) {
                console.log("Error fetching waiting room questionnaire data:", error);
            }
        };

        fetchWaitingRoomData();
    }, [getCareModalShow, waitingRoomActive]);

    useEffect(() => {
        getSingleUser({ id: patientId, type: "Patient" })
            .then((res) => setLoggedInUserInfo(res?.data))
            .catch((res) => failed(res.message))
    }, []);

    useEffect(() => {
        getPatientDataForRelatedPerson(patientId)
            .then((res) => {
                if (res?.data?.parentPatient?.length > 0) {
                    setRelatedPerson(res?.data?.parentPatient);
                    if (!currentUser) {
                        setRelatedPersonModal(true);
                    }
                }
            })
            .catch((res) => failed(res.message))
    }, [patientId]);



    const menu = [
        {
            id: 1,
            title: "Add Vital Readings",
            icon: ReadingIcon,
            description: "Record and track your vital health metrics, including Blood Pressure, Heart Rate, Oxygen Rate, Pulse, and more. Monitor and maintain your well-being with ease.",
            visible: false
        },
        {
            id: 2,
            title: "My Vitals Dashboard",
            icon: DashboardIcon,
            description: "Explore and analyze your vital readings through an intuitive dashboard. Gain insights into your health trends and track progress over time.",
            visible: false
        },
        {
            id: 3,
            title: "Get Care",
            icon: GetCareIcon,
            description: "Access healthcare services effortlessly. Book appointments with practitioners or enter a virtual waiting room for prompt assistance. Your care journey starts here.",
            visible: false
        },
        {
            id: 4,
            title: "Visits",
            icon: AppointmentIcon,
            description: "Stay organized with a comprehensive view of your upcoming, requested, and completed appointments. Manage your healthcare schedule with convenience.",
            visible: false
        },
        {
            id: 5,
            title: "Medications",
            icon: MedicationIcon,
            description: "Review and manage your medications easily. Access information about prescribed medications and stay informed about your treatment plan.",
            visible: false
        },
        {
            id: 6,
            title: "Health Conditions",
            icon: DiseaseIcon,
            description: "Keep track of your health conditions with this feature. Monitor and understand your medical history, ensuring informed decisions about your well-being.",
            visible: false
        },
        {
            id: 7,
            title: "Related Person",
            icon: RelatedPersonIcon,
            description: "Stay connected with the health status of related persons. Monitor and manage health information for your loved ones efficiently.",
            visible: false
        },
        {
            id: 8,
            title: "Requisitions",
            icon: RequisitionIcon,
            description: "Access and manage your requisitions seamlessly. Stay informed about requests and ensure a smooth process for necessary healthcare procedures.",
            visible: false
        },
        {
            id: 9,
            title: "Prescriptions",
            icon: PrescriptionIcon,
            description: "View and manage your prescriptions conveniently. Access details of prescribed medications, dosages, and instructions for your health regimen.",
            visible: false
        },

    ]

    const initializeMenu = () => {
        let data = menu.map(item => {
            if (licensedProducts?.RPM) {
                item.visible = true;
            }
            if ((item.id === 8 || item.id === 9) && licensedProducts?.RPM) {
                item.visible = false;
            }
            if (
                licensedProducts?.virtualCare &&
                (item.id == 3 || item.id == 4 || item.id == 7 || item.id == 8 || item.id == 9)
            )
                item.visible = true;
            return item;
        });
        setMenu(data);
    };

    const handleClick = (item) => {
        if (item.id === 1) {
            navigate("/app/vitals");
        };

        if (item.id === 2) {
            navigate("/app/vital-dashboard");
        };

        if (item.id === 3) {
            setGetCareModalShow(true);
        };

        if (item.id === 4) {
            navigate("/app/appointments");
        };

        if (item.id === 5) {
            navigate("/app/medications");
        };

        if (item.id === 6) {
            navigate("/app/conditions");
        };

        if (item.id === 7) {
            //sessionStorage.removeItem("relatedPersonProfile");
            //if (relatedPerson) {
            //    setRelatedPersonModal(true);
            //} else {
            //    failed("You have no dependents added to your profile");
            //}
            navigate("/app/related-person");

        };

        if (item.id === 8) {
            navigate("/app/requisitions");
        };

        if (item.id === 9) {
            navigate("/app/prescriptions");
        };


    };

    const handleGetCareModalClose = () => {
        setGetCareModalShow(false);
    };

    const handleBookAppointmentClick = () => {
        setGetCareModalShow(false);
        setGetCareQuestionnaireModal(true);
    }

    const openProviderModal = (formikQuestionnaire) => {
        setGetCareQuestionnaireModal(false);
        setProviderModalShow(true);

        setQuestionnaireData({
            questionnaireId: getCareQuestionId,
            formikQuestionnaire,
        });
    };

    const openBookAppointmentModal = (providerPreference, formikQuestionnaire, practitionerId) => {
        setProviderModalShow(false);
        setBookAppointmentModalShow(true);

        setSelectedProviderPreference(providerPreference);
        setSelectedPractitionerId(practitionerId);
    };

    const closeBookAppointmentModal = () => {
        setBookAppointmentModalShow(false);
        setSelectedPractitionerId('');
        setSelectedProviderPreference('');
        setQuestionnaireData(null);
    };

    const handleWRBtnClick = () => {
        setGetCareModalShow(false);
        setWRModalShow(true);
    };

    const handleWaitingRoomEnter = (WRQuestionnaireResponse) => {
        setWRQuestionnaireResponse(WRQuestionnaireResponse[0]);
        setWRModalShow(false);
        setWaitingScreenShow(true);
    };

    return (
        <section>
            {loading ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <div className="home-container">
                    <div className="cards-container">
                        {home_menu.map((item, index) => (
                            <div
                                key={item.id}
                                className={`card ${item.visible ? 'visible' : 'hidden'}`}
                                onClick={() => handleClick(item)}
                                style={{ cursor: 'pointer' }}
                            >
                                <div className="card-content">
                                    <img src={item.icon} alt={`Icon for ${item.title}`} />
                                    <h4 style={{ color: '#0066cc' }}>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {getCareModalShow && <GetCareModal getCareModalShow={getCareModalShow} handleGetCareModalClose={handleGetCareModalClose} handleBookAppointmentClick={handleBookAppointmentClick} handleWRBtnClick={handleWRBtnClick} waitingRoomActive={waitingRoomActive} />}



            {getCareQuestionnaireModal && <QuestionnaireGetCareModal questionnaireModalShow={getCareQuestionnaireModal} handleQuestionnaireModalClose={() => setGetCareQuestionnaireModal(false)} questions={getCareQuestions} openProviderModal={openProviderModal} />}
            {providerModalShow && <ProviderPreferenceModal providerModalShow={providerModalShow} handleProviderModalClose={() => setProviderModalShow(false)} formikQuestionnaire={questionnaireData} openBookAppointmentModal={openBookAppointmentModal} />}
            {bookAppointmentModalShow && <BookAppointmentModal bookAppointmentModalShow={bookAppointmentModalShow} handleBookAppointmentClose={closeBookAppointmentModal} providerPreference={selectedProviderPreference} practitionerId={selectedPractitionerId} formikQuestionnaire={questionnaireData} />}

            {WRmodalShow && <QuestionnaireWaitingRoom questionnaireWRModalShow={WRmodalShow} handleQuestionnaireWRModalClose={() => setWRModalShow(false)} WRquestions={waitingRoomQuestions} openWRScreen={handleWaitingRoomEnter} />}
            {waitingScreenShow && <WaitingScreen waitingScreenShow={waitingScreenShow} waitingScreenClose={() => setWaitingScreenShow(false)} encounterInfo={WRQuestionnaireResponse} />}
            {relatedPersonModal && <RelatedPersonModal relatedPersonModalShow={relatedPersonModal} relatedPersonModalClose={() => setRelatedPersonModal(false)} relationshipData={relatedPerson} loggedUserInfo={loggedInUserInfo} />}
        </section>
    );

}

export default Home;