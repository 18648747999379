import { axios } from "../../lib/axios";


export function getSignature({ topic, role, password }) {
    return axios.post(`session/create`, { topic, role: `${role}`, password })
}

export function isAndroidBrowser() {
    return /android/i.test(navigator.userAgent);
}

export function toggleFullscreen() {
    var elem = document.getElementById("room");
    if (document.fullscreenElement === elem) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    } else {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
}


export function BindEvents(zmClient, stream) {
    // updated properties of user 
    zmClient.on('user-updated', (payload) => {
        //console.log(payload[0]?.userId, ' properties were updated')
    })

    // When a participant turns on or off their video
    zmClient.on('peer-video-state-change', (payload) => {
        console.log(payload);
        const CanvasElem = document.querySelector(`#participant-canvas-${payload.userId}`)
        const AvatarElem = document.querySelector(`#participant-avatar-${payload.userId}`)
        if (payload.action === 'Start') {
            AvatarElem.style.display = "none";
            CanvasElem.style.display = "block";
            stream.renderVideo(CanvasElem, payload.userId, 300, 160, 0, 0, 2)
        } else if (payload.action === 'Stop') {
            AvatarElem.style.display = "flex";
            CanvasElem.style.display = "none";
            stream.stopRenderVideo(CanvasElem, payload.userId)
        }
    })

    zmClient.on('active-speaker', (payload) => {
        console.log('Active speaker', payload)
    })

}
