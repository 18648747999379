import { useQuery } from "react-query";
import { axios } from "../lib/axios";
import { onError } from "./onError"
import { useSelector } from "react-redux";

export function useGetOrgLicensedProducts({ onSuccess = () => { }, orgId = null, ...props }) {
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    const fetch = () => {
        if(orgId != null || (adminOrgs && adminOrgs.length)){
            return axios.get(`discover/organization/${orgId || adminOrgs[0]?.id}`);
        }
    };

    const query = useQuery(
        ["getOrgLicensedProducts"],
        fetch,
        {
            select: (res) => res?.data?.licensedProduct,
            onSuccess,
            onError,
            refetchOnWindowFocus: false,
            ...props,
        }
    );

    return { ...query };
}
