import React, { useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../common/textfield/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import { verifiedUserSetPass } from "../api/AuthService";
import Lock from '../../assets/images/Lock.png';

function VerifiedUserSetPass() {
    const navigate = useNavigate();
    const { state } = useLocation()
    const [btnLoading, setBtnLoading] = useState(false)
    const username = state?.username;
    const session = state?.session;
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPass: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Please Enter your password')
                .matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
                ),
            confirmPass: Yup.string().required("Required confirm password field").oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true)
            verifiedUserSetPass({ username, session, confirmPass: values.confirmPass }).then(({ data }) => {
                if (data.status === true) {
                    success(data.message);
                    console.log("Verified User Set Password!!!");
                } else {
                    failed(data.error);
                }
            }).catch(res => {
                failed(res?.response?.data?.message || res?.response?.data?.message || res.message);
            }).finally(() => setBtnLoading(false));
        },
    });

    return (
        <form className="custom-form" onSubmit={formik.handleSubmit}>
            <Row>
                <Col>
                    <TextInput type="password" icon={Lock} keyField={"password"} label={"New Password"} formik={formik} placeholder={"New Password"} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextInput type="password" icon={Lock} keyField={"confirmPass"} label={"Confirm Password"} formik={formik} placeholder={"Confirm Password"} />
                </Col>
            </Row>
            <div className="btn-wrap" style={{ display: 'flex' }}>
                <Button type="submit" isLoading={btnLoading}>Submit</Button>
            </div>
        </form>
    );
}

export default VerifiedUserSetPass;