import moment from "moment";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import { addManualReadingForPatient } from "../api/VitalObservation";
import { useFormik } from "formik";
import * as Yup from 'yup';
import TextInput from "../common/textfield/TextInput";

const HeartRateModal = ({ heartRateModalShow, heartRateModalClose, units, programId }) => {

    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const currentDate = moment(new Date()).format('MM/DD/YYYY');

    const formik = useFormik({
        initialValues: {
            heartRate: ""
        },
        validationSchema: Yup.object({
            heartRate: Yup.number()
                .required('Heart Rate is required')
                .integer('Heart Rate must be a whole number')
                .min(0, 'Heart Rate must be at least 0')
                .max(200, 'Heart Rate must be at most 200'),
        }),
        onSubmit: (values) => {
            console.log("Trigerred")
            setBtnLoading(true);
            let params = {
                patientId: patientId,
                programId: programId,
                effectiveDateTime: currentDate,
                conditionName: "heart_rate",
                valueQuantity: {
                    value: {
                        heartRate: values.heartRate
                    },
                    unit: {
                        heartRate: units.heartRate
                    },
                },
                device: {
                    reference: 'Device/null',
                }
            };

            addManualReadingForPatient(params)
                .then((res) => success(res?.message))
                .catch((error) => failed(error))
                .finally(() => {
                    setBtnLoading(false);
                    heartRateModalClose();
                })
        }
    });

    console.log("Formik error:", formik.errors)

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={heartRateModalShow} onHide={heartRateModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Heart Rate Reading</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"heartRate"} formik={formik} label={`Heart Rate (${units.heartRate})`} placeholder={"Enter here..."} readOnly={false} disabled={false} required={true} />
                        </Col>
                    </Row>

                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                heartRateModalClose();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Add
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )


}

export default HeartRateModal;