import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from 'yup';
import { Button } from "../common/Button";
import { getAppointmentById } from "../api/Appointments";
import { failed, success } from "../common/Toastify";
import { feedbackSubmit } from "../api/Questionnaires";
import TextArea from "../common/textfield/TextArea";
import { useSelector } from "react-redux";

const FeedbackModal = ({ modalShow, modalClose, questions, questionnaireId, patientId, appointmentData }) => {
    const [encounterId, setEncounterId] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);


    useEffect(() => {
        setBtnLoading(true);
        getAppointmentById(appointmentData?.id)
            .then((res) => setEncounterId(res?.data?.encounterId))
            .catch((res) => failed(res?.message))
            .finally(() => setBtnLoading(false));
    }, [appointmentData]);

    const StarRating = ({ value, onClick }) => {
        const stars = [1, 2, 3, 4, 5];

        return (
            <div>
                {stars.map((star) => (
                    <span
                        key={star}
                        onClick={() => onClick(star)}
                        style={{
                            cursor: 'pointer',
                            fontSize: '24px', // Adjust the font-size to increase the size of stars
                            color: star <= value ? 'blue' : 'gray',
                            transition: 'color 0.3s ease', // Add a smooth transition effect
                        }}
                    >
                        &#9733;
                    </span>
                ))}
            </div>
        );
    };

    const formik = useFormik({
        initialValues: (() => {
            const initialValues = {};

            questions?.item?.forEach((question) => {
                switch (question.type) {
                    case 'freeText':
                        initialValues[question.text] = '';
                        break;
                    case 'stars':
                        initialValues[question.text] = '';
                        break;

                    default:
                        initialValues[question.title] = '';
                }
            });

            return initialValues;
        })(),
        validationSchema: Yup.object().shape({
            ...questions?.item?.reduce((validationSchema, question) => {
                if (question.required) {
                    validationSchema[question.text] = Yup.string().required(`${question.text} is required`);
                }
                return validationSchema;
            }, {}),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            const intakeQuestions = [];

            questions?.item?.forEach((question) => {

                if (question.type === "stars") {
                    const starResponse = {
                        id: question.linkId,
                        question: question.text,
                        questionType: 'stars',
                        answer: [
                            {
                                required: question.required,
                                answer: values[question.text].toString(),
                                option: null,
                                value: null,
                                name: null
                            }
                        ]
                    }

                    intakeQuestions.push(starResponse);
                }

                if (question.type === "freeText") {
                    const freeTextResponse = {
                        id: question.linkId,
                        question: question.text,
                        questionType: "freeText",
                        answer: [
                            {
                                required: question.required,
                                answer: values[question.text],
                                option: null,
                                value: null,
                                name: null
                            }
                        ]
                    }

                    intakeQuestions.push(freeTextResponse);
                }



            });



            const requestFeedback = {
                responseType: 'surveyResponse',
                patientID: patientId,
                encounterId: encounterId,
                questionResponse: intakeQuestions,
                questionnaire: questionnaireId
            }


            feedbackSubmit(requestFeedback)
                .then((res) => success("Feedback submitted successfully"))
                .catch((res) => failed(res?.message))
                .finally(() => {
                    setBtnLoading(false);
                    modalClose();
                })

        }
    });

    const handleStarClick = (questionName, value) => {
        formik.setFieldValue(questionName, value);
    };



    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={modalShow} onHide={modalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Feedback Survey</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span> The session was ended.</span>
                <hr />
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>

                    {questions?.item?.map((question) => (
                        <div key={question.text} className="form-group">
                            {question.type === 'stars' && (
                                <>
                                    <label>{question.text}</label>
                                    <StarRating
                                        value={formik.values[question.text]}
                                        onClick={(value) => handleStarClick(question.text, value)}
                                    />
                                </>
                            )}
                            {question.type === 'freeText' && (
                                <TextArea
                                    keyField={question.text}
                                    label={question.text}
                                    formik={formik}
                                    placeholder={"Type your answer here"}
                                    required={question.required}
                                />
                            )}
                            {formik.touched[question.text] && formik.errors[question.text] && (
                                <div className="error">{formik.errors[question.text]}</div>
                            )}
                        </div>
                    ))}


                    <div className="btn-wrap">
                        <Button type="submit" isLoading={btnLoading}>
                            Submit
                        </Button>
                        <Button onClick={() => modalClose()} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>
                            Not Now
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
};

export default FeedbackModal;