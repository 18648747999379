import { useState } from "react";
import { useGetNotificationsQuery } from "../../hooks/ReactQueryHooks/useGetNotificationsQuery";
import { updateNotificationCount } from "../api/Notification";
import ViewUserNotificationModal from './ViewUserNotificationModal'
import { Table } from "react-bootstrap";
import { Skeleton, Tooltip, TableCell } from "@mui/material";
import { withLocalTime } from "../../utils/DateSupport";
import moment from "moment";
import { Link } from "react-router-dom";
import ViewIcon from "../../assets/images/view.png";
import SearchBar from '../common/components/SearchBar'
import { decryptData } from '../EncryptDecrypt'
import CustomPagination from '../common/components/Pagination/CustomPagination'



const Notifications = ({ setCount = () => { } }) => {

    const [modalShow, setModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [page, setPage] = useState(0);
    const [data, setData] = useState([])
    const rowsPerPage = 10;
    const totalRows = filterData.length;

    const onSuccess = (data) => {
        setData(data?.data)
        setCount(data?.unreadNotifications)
    };

    const { isLoading, refetch } = useGetNotificationsQuery({ onSuccess, callMe: true })



    const handleFilterData = (data) => {
        setFilterData(data)
    }

    const handleShow = (data) => {
        setModalShow(modalShow ? false : data);
        refetch()
    };

    const handleNotificationCount = (data) => {
        updateNotificationCount({ patientId: data?.id, notificationId: data?.notificationId, markAsRead: true })
    }



    return (
        <section className="common-listing" style={{ marginLeft: "100px" }}>
            <ViewUserNotificationModal modalShow={modalShow} handleShow={handleShow} />
            <div className="heading-wrap">
                <SearchBar page={page} setPage={setPage} list={data} filterData={handleFilterData} encoded={true} />
            </div>
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Message</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Event Type</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Time Stamp</th>
                            <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            [1, 2, 3, 4]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                </tr>
                            ))
                        ) : filterData?.length > 0 ? (
                            filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data, index) => {
                                const msg = decryptData(data?.message)
                                return (
                                    <>
                                        <tr key={data?.notificationId}>
                                            <td>
                                                {!data?.markAsRead ? (
                                                    <div
                                                        style={{
                                                            width: "10px",
                                                            height: "10px",
                                                            backgroundColor: "#005da8",
                                                            borderRadius: "50%",
                                                        }}
                                                    ></div>
                                                ) : null}
                                            </td>
                                            <td
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: "170px",
                                                }}
                                            >
                                                {withLocalTime(msg)}
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{data?.title}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className="name-text">
                                                {moment(data?.timestamp).format(
                                                    "MM-DD-YYYY HH:mm"
                                                )}
                                            </td>
                                            <td>
                                                <div
                                                    className="action-wrap"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Tooltip title={"View"}>
                                                        <Link
                                                            to=""
                                                            variant="primary"
                                                            onClick={() => {
                                                                handleShow(data, false);
                                                                handleNotificationCount(data);
                                                            }}
                                                            className="view-btn"
                                                        >
                                                            <img src={ViewIcon} alt="Edit" />
                                                        </Link>
                                                    </Tooltip>

                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })
                        ) : (
                            <tr>
                                <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                    No Data Available
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}
        </section>
    )
}

export default Notifications;