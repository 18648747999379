import { Navigate, useLocation, useRoutes, useNavigate } from 'react-router-dom';
import Login from '../components/Login/login';
import { useSelector } from 'react-redux';
import { AuthAppLayout, publicRoutes } from './public';
import { useEffect } from 'react';
import { useProtectedRoutes } from './protected';
import Header from '../components/common/header/header';

const WrapperWithHeaderOnly = ({ children }) => {
    return (
        <div className="outer-wrapper">
            <Header hideBody={true} />
            <div className="body-outer">
                <div className="body-wrapper">
                    {children}
                </div>
            </div>
        </div>
    )
}

export const AppRoutes = () => {
    let navigate = useNavigate();
    const { pathname } = useLocation();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const user = useSelector((state) => state?.auth?.user);

    const afterLoggedInRoute = '/app/home'

    const commonRoutes = [
        { path: '/', element: isLoggedIn ? <Navigate to={afterLoggedInRoute} /> : <Navigate to='/login' /> },
        { path: '/login', element: isLoggedIn ? <Navigate to={afterLoggedInRoute} /> : <AuthAppLayout><Login /></AuthAppLayout> }
    ];
    const { protectedRoutes } = useProtectedRoutes();
    const routes = isLoggedIn ? protectedRoutes : publicRoutes;
    const element = useRoutes([...routes, ...commonRoutes]);
    useEffect(() => {
        const publicPathNames = ['/', '/login', '/auth/setpassword', '/auth/verifyMFA', '/auth/forgotpassword', '/auth/resetpassword'];
        if (!isLoggedIn && !publicPathNames.includes(pathname)) {
            // <Navigate to='/login' />;
            navigate('/login');
        }
    }, [isLoggedIn, pathname]);


    return <>{element}</>;
};
