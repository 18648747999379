import { useEffect, useState } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import { Button } from "../common/Button";
import { useSelector } from "react-redux";
import { getSingleUser } from "../api/Individual";
import { failed } from "../common/Toastify";
import { useNavigate } from "react-router-dom";

const RelatedPersonModal = ({ relatedPersonModalShow, relatedPersonModalClose, relationshipData, loggedUserInfo }) => {
    const user = useSelector((state) => state?.auth?.user);
    const [selectedOption, setSelectedOption] = useState("SELF");
    const [relatedPersonData, setRelatedPersonData] = useState({});
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = useState(true);

    useEffect(() => {
        setBtnLoading(true)
        if (selectedOption !== "SELF") {
            getSingleUser({ id: selectedOption.id, type: "Patient" })
                .then((res) => setRelatedPersonData(res?.data))
                .catch((res) => failed(res?.error))
                .finally(() => setBtnLoading(false))
        }
    }, [selectedOption]);

    const handleOptionChange = (value) => {
        setSelectedOption(value);
    };

    const renderSelfOption = () => {
        if (loggedUserInfo?.type && loggedUserInfo?.type.includes("Patient")) {
            return (
                <Col xs={12}>
                    <Form.Check
                        type="radio"
                        label="SELF"
                        id={`radio-0`}
                        className="mb-2"
                        checked={selectedOption === "SELF"}
                        onChange={() => handleOptionChange("SELF")}
                    />
                </Col>
            );
        }
        return null;
    };

    const handleButtonClick = () => {
        if (selectedOption === "SELF") {
            sessionStorage.setItem("relatedPersonProfile", JSON.stringify(user));
            relatedPersonModalClose();
            navigate("/app/home");
        } else {
            if (relatedPersonData && Object.keys(relatedPersonData).length > 0) {
                sessionStorage.setItem("relatedPersonProfile", JSON.stringify(relatedPersonData));
                relatedPersonModalClose();
                navigate("/app/home");
            } else {
                failed("Loading Related Person Profile. Please try again");
            }
        }
    }

    return (
        <Modal
            backdropClassName
            backdrop={"static"}
            show={relatedPersonModalShow}
            onHide={relatedPersonModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
        >
            <Modal.Header className="border-0">
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ marginRight: "10px" }}
                >
                    Related Person
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span>Please choose a patient profile to continue.</span>
                <br />
                <Form>
                    <Row>
                        {renderSelfOption()}
                    </Row>
                    {relationshipData?.map((person, index) => (
                        <Row key={index} style={{ textTransform: "capitalize" }}>
                            <Col xs={8}>
                                <Form.Check
                                    type="radio"
                                    label={person.name[0].text}
                                    id={`radio-${index + 1}`}
                                    className="mb-2"
                                    checked={selectedOption === person}
                                    onChange={() => handleOptionChange(person)}
                                />
                            </Col>
                            <Col xs={2}>
                                <span>{person.relationship}</span>
                            </Col>
                        </Row>
                    ))}
                </Form>
                <br />

                <div className="action-wrap">
                    <Button className="custom-btn" title="Submit" style={{ width: "auto" }} onClick={() => handleButtonClick()}>
                        Submit
                    </Button>
                </div>
            </Modal.Body>


        </Modal>
    )

}

export default RelatedPersonModal;