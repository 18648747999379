import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectField from "../common/textfield/SelectField";
import * as Yup from "yup";
import { DateSelectorWithoutFormik } from "../common/textfield/DateSelector";
import { CircularProgress, Skeleton } from "@mui/material";
import moment from "moment";
import TextInput from "../common/textfield/TextInput";
import TextArea from "../common/textfield/TextArea";
import { decryptData } from "../EncryptDecrypt";
import { Button } from "../common/Button";
import { failed, success } from "../common/Toastify";
import { Form } from "react-bootstrap";
import { bookAppointment, checkShedule, getSlots, listOrgSlots, listServiceForOrg } from "../apps/api/AppointmentRequests";
import { generatePass } from "../../components/apps/AppointmentRequests/Constants";
import { getSignature } from '../../components/Zoom/functions';
import './style.css';
import { handleFileUpload } from "../../utils/ImageToBase64";



const BookAppointmentModal = ({ bookAppointmentModalShow, handleBookAppointmentClose, type = "patient", formikQuestionnaire, providerPreference, practitionerId = "" }) => {
    const user = useSelector((state) => state?.auth);
    const patientLocation = (formikQuestionnaire?.location || '');

    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = user?.user['custom:unique_id'];
    const orgId = useSelector((state) => state?.auth?.user?.orgId);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [serviceCategory, setServiceCategory] = useState([]);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [practitionerList, setPractitionerList] = useState([]);
    const [showDateField, setShowDateField] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [loading, setLoading] = useState(false);
    const [orgServiceCategory, setOrgServiceCategory] = useState([]);
    const [todayDate, setTodayDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);

    const isTagPresent = (tagValue) => formikQuestionnaire?.formikQuestionnaire?.some(item => item.tag === tagValue);


    const formik = useFormik({
        initialValues: {
            date: todayDate,
            serviceCategory: "",
            availableSlot: "",
            preferredChannel: "",
            returnTelephoneNumber: "",
            returnTelephoneNumberExt: "",
            practitionerPreference: providerPreference,
            practitioner: practitionerId,
            fileAttachments: []
        },
        validationSchema: Yup.object({
            date: Yup.string().required("Required Date field."),
            serviceCategory: Yup.string().required("Required Appointment type field."),
            availableSlot: Yup.object().required("Required slots field."),
        }),
        onSubmit: async (values) => {

            setLoading(true);

            let documentToBeSent = [];

            if (values.fileAttachments.length > 0) {
                let image = await handleFileUpload(values?.fileAttachments[0]);
                image = image.split(',')[1];
                let fileExtension = values?.fileAttachments[0]?.type?.split("/")[1];


                for (const file of values.fileAttachments) {
                    let image = await handleFileUpload(file);
                    image = image.split(',')[1];
                    let fileExtension = file.type.split("/")[1];

                    const fileAttachment = {
                        data: image,
                        extension: fileExtension,
                        name: file.name,
                    };

                    documentToBeSent.push(fileAttachment);
                }

            } else {
                documentToBeSent = null;
            }



            //const requestedPeriod = [{ start: moment(values?.date + ' ' + values?.availableSlot?.start).format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment(values?.date + ' ' + values?.availableSlot?.end).format("YYYY-MM-DDTHH:mm:ss[Z]") }]
            let start = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.start)}:00Z`;
            let localStartTime = moment(start).format("HH:mm")
            let localStart = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localStartTime)}`;
            let utcStart = moment(localStart).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            let end = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.end)}:00Z`;
            let localEndTime = moment(end).format("HH:mm")
            let localEnd = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localEndTime)}`;
            let utcEnd = moment(localEnd).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
            const requestedPeriod = [{ start: utcStart, end: utcEnd }]

            let topic = 'Appointment-Fonemed';
            let password = generatePass();

            getSignature({ topic: topic.trim(), role: 1, password: password })
                .then(async (res) => {
                    const sessionToken = res?.data?.sessionToken;

                    let questionnaireResponse = {};

                    formikQuestionnaire?.formikQuestionnaire?.forEach((question) => {
                        const tagValue = question.tag;
                        const responseKey = question.patientResponse;

                        if (tagValue === "TELEPHONE") {
                            questionnaireResponse["callerNumber"] = responseKey;
                        }

                        if (tagValue === "LOCATION") {
                            questionnaireResponse["patientLocation"] = responseKey;
                        }

                        if (tagValue === "REASON") {
                            questionnaireResponse["reason"] = responseKey;
                        }

                        if (tagValue === "CHANNEL") {
                            questionnaireResponse["preferredChannel"] = responseKey;
                        }

                        if (question?.callerExt) {
                            questionnaireResponse["callerNumberExt"] = question?.callerExt;
                        } else {
                            questionnaireResponse["callerNumberExt"] = "";
                        }

                    });

                    bookAppointment({ sessionToken, practitionerId: practitionerId, patientId: patientId, requestedPeriod, intakeQuestions: questionnaireResponse, description: questionnaireResponse?.reason, document: documentToBeSent })
                        .then((res) => {
                            success("Appointment Request Sent");


                        })
                        .catch((res) => {
                            failed(
                                res?.response?.data?.message || res?.response?.data?.error || res.message
                            );

                        })
                        .finally(() => {
                            setLoading(false);
                            handleBookAppointmentClose();
                        });
                }).catch((error) => {
                    failed("Get signature error")
                });

        }
    });

    const handleDateChange = (val) => {
        formik.setFieldValue("date", val.format("YYYY-MM-DD"));
        formik.setFieldValue("serviceCategory", "");
        setAvailableSlots([]);
    };

    const removeOverLappingAndPastSlots = (slots) => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        const filteredSlots = slots.filter(slot => {
            const [startHour, startMinute] = slot.start.split(':');
            const [endHour, endMinute] = slot.end.split(':');
            if (
                currentHour > parseInt(endHour) ||
                (currentHour === parseInt(endHour) &&
                    currentMinute >= parseInt(endMinute))
            ) {
                return false;
            }
            if (
                (currentHour > parseInt(startHour) &&
                    currentHour < parseInt(endHour)) ||
                (currentHour === parseInt(startHour) &&
                    currentMinute >= parseInt(startMinute))
            ) {
                return false;
            }
            return true;
        });
        setAvailableSlots(filteredSlots);
        return filteredSlots;
    };

    const removeOrgEarlySlots = (slots) => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();
        const filteredSlots = slots.filter(slot => {
            const [startHour, startMinute] = slot.start.split(':');
            const [endHour, endMinute] = slot.end.split(':');
            if (
                currentHour > parseInt(endHour) ||
                (currentHour === parseInt(endHour) &&
                    currentMinute >= parseInt(endMinute))
            ) {
                return false;
            }
            if (
                (currentHour > parseInt(startHour) &&
                    currentHour < parseInt(endHour)) ||
                (currentHour === parseInt(startHour) &&
                    currentMinute >= parseInt(startMinute))
            ) {
                return false;
            }
            return true;
        });
        setAvailableSlots(filteredSlots);
    }

    useEffect(() => {
        if (formik?.values?.date && formik?.values?.practitioner !== "") {
            checkShedule({ actorId: practitionerId, date: moment(formik?.values?.date).format("YYYY-MM-DD") })
                .then((res) => {
                    setServiceCategory(res?.data?.serviceCategory
                        ?.filter((categoryData) => moment(categoryData?.date?.split("T")?.[0]).weekday() === moment(formik?.values?.date).weekday())
                        ?.map((categoryData) => ({ ...categoryData, value: categoryData?.id })))
                    !res?.data && failed(res?.message)
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        } else {
            if (formik?.values?.date) {
                listServiceForOrg({ date: moment(formik?.values?.date).format("YYYY-MM-DD"), patientId: patientId })
                    .then((res) => {
                        setServiceCategory(res?.data
                            ?.map((categoryData) => ({ ...categoryData, value: categoryData?.id })))
                        !res?.data && failed(res?.message)
                    })
                    .catch((res) => failed(res?.error))
            }
        }
    }, [formik?.values?.date, practitionerId]);


    const handleServiceCategory = (event) => {
        formik.setFieldValue("serviceCategory", event?.target?.value);
        setSlotsLoading(true);
        if (practitionerId !== "") {
            getSlots({ actorId: practitionerId, date: moment(formik?.values?.date).format("YYYY-MM-DD"), serviceCategoryId: event?.target?.value })
                .then((res) => {
                    if (res?.data?.availableSlots?.length === 0) {
                        failed("No Slots available for selected date!")
                    } else {
                        setAvailableSlots(res?.data?.availableSlots)
                    }
                }).catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                }).finally(() => { setSlotsLoading(false) })
        } else {
            listOrgSlots({ serviceCategoryId: event?.target?.value, patientId: patientId, orgId: orgId, date: moment(formik?.values?.date).format("YYYY-MM-DD") })
                .then((res) => {

                    if (moment(formik?.values?.date).format("YYYY-MM-DD") == todayDate) {
                        removeOrgEarlySlots(res.data.availableSlots);
                    } else {
                        setAvailableSlots(res.data.availableSlots);
                    }
                }).catch((res) => failed(res?.message))
                .finally(() => setSlotsLoading(false));
        }
    };

    const handleFileAttachments = (files) => {
        const selectedFilesArray = Array.from(files).slice(0, 6); // Limit to 6 files
        setSelectedFiles(prevFiles => [...prevFiles, ...selectedFilesArray]);
        formik.setFieldValue("fileAttachments", [...selectedFiles, ...selectedFilesArray]);
    };

    const removeSelectedFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
        formik.setFieldValue("fileAttachments", updatedFiles);
    };

    return (
        <>
            <Modal
                backdropClassName
                backdrop={'static'}
                size='lg'
                show={bookAppointmentModalShow} onHide={handleBookAppointmentClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter">Schedule An Appointment</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col>
                                <DateSelectorWithoutFormik label="Select Date" value={formik?.values?.date} handleChange={handleDateChange} minDate={moment()} />
                            </Col>
                            <Col>
                                <SelectField keyField={"serviceCategory"} label={"Appointment Type"} formik={formik} options={serviceCategory} onChange={handleServiceCategory} />
                            </Col>
                        </Row>



                        {availableSlots?.length > 0 ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Row style={{/*  justifyContent: "center", */ rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                    {availableSlots?.map((slot) => {
                                        let time = `${moment(formik?.values?.date)?.format("YYYY-MM-DD")}T${(slot?.start)}:00Z`;
                                        let localTime = moment(time).format("HH:mm");
                                        return (<Col style={{ background: selectedSlot === slot?.start ? "#6c757d38" : "#ffff", width: "14.666667%" }} sm={2} className="slotStyle" key={slot?.start} onClick={() => { formik?.setFieldValue("availableSlot", slot); setSelectedSlot(slot?.start) }}>{localTime}</Col>)
                                    })}
                                </Row>
                            </>
                            : null}
                        {slotsLoading ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Skeleton height={50} />
                            </>
                            : null}

                        <Row>
                            <Col>
                                <Form.Group controlId="fileAttachments">
                                    <Form.Label>File Attachments (Optional)</Form.Label>
                                    <Form.Control
                                        type="file"
                                        multiple
                                        accept=".jpg, .png, .pdf, .doc, .docx"
                                        onChange={(e) => handleFileAttachments(e.target.files)}
                                    />

                                    {selectedFiles.length > 0 && (
                                        <div className="selected-files">
                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className="selected-file">
                                                    {file.name}
                                                    <span className="remove-icon" onClick={() => removeSelectedFile(index)}>
                                                        &#10006;
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="btn-wrap">
                            <Button
                                onClick={() => {
                                    handleBookAppointmentClose();
                                }}
                                variant="secondary"
                                title="Cancel"
                            >
                                Cancel
                            </Button>
                            <Button type="submit">
                                {loading ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    "Send Request"
                                )}
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BookAppointmentModal;