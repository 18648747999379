import axios from "axios";
import { apiUrl } from "../../environment/index";


export const userLogin = (data) => {
    return axios.post(`${apiUrl}auth/login`, data).then((response) => {
        if (response.data.status === true) {
            localStorage.setItem("authentication", response.data.token);
        }
        return response.data;
    }).catch((error) => {
        console.log(error, "===test");
        return error.response.data
    });
};

export const logout = () => {
    localStorage.clear();
    return true;
};

export const verifyAndSetPass = ({ username, verificationCode, password, newPassword }) => { 
    return axios.get(`${apiUrl}auth/verify-email?username=${username}&verificationCode=${verificationCode}`)
        .then((response) => {
            return userLogin({ username: username, password: password }).then((res) => {
                return axios.post(`${apiUrl}auth/reset`, {
                    username,
                    "password": newPassword,
                    "session": res.session,
                }).then((res1) => {
                    return res1
                }).catch((err) => { console.log(err) });
            }).catch((err) => { console.log(err) });
        })
        .catch((error) => { return error });
};

// if @verifyAndSetPass API havent run whole or after verfied email stooped then call the below API
export const verifiedUserSetPass = ({ username, session, confirmPass }) => {
    return axios.post(`${apiUrl}auth/reset`, {
        username,
        "password": confirmPass,
        "session": session,
    }).then((res1) => {
        return res1
    }).catch((err) => { console.log(err) });
};

export const resetPassword = (data) => {
    return axios.post(`${apiUrl}auth/new-password`, data);
};
export const forgotPassword = ({ email, isExpired = false }) => {
    // isExpired is to identify that the user's temporary password has been expired so he need to reset the password.
    return isExpired ? axios.get(`${apiUrl}auth/forgot-password?email=${email?.toLowerCase()}&isExpired=true`) : axios.get(`${apiUrl}auth/forgot-password?email=${email?.toLowerCase()}`);
};
export const verifyMFA = (data) => {
    return axios.post(`${apiUrl}auth/verify-mfa-code`, data);
};


export const getIdentityChallenge = ({ sftID }) => { 
    return axios.get(`${apiUrl}securefiletransfer?sftID=${sftID}`)
        .then((response) => { 
           
        })
        .catch((error) => { return error });
};

export const verifyPatientsIdentity = ( data ) => {  
     console.log(data);
    return axios.post(`${apiUrl}securefiletransfer/verifypatientsidentity`, data).then((response) => { 
        return response.data;
    }).catch((error) => {
        console.log(error, "===test");
        return error.response.data
    });
    
};
