import { Modal, Tooltip } from "react-bootstrap";
import { usePdfRefDocumentRequestQuery } from '../../hooks/ReactQueryHooks/usePdfRefDocumentRequestQuery';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useState } from "react";



const ViewPrescriptionModal = ({ modalShow, modalClose, fileData }) => {
    const fileExtension = "pdf";
    const [document, setDocument] = useState(null)
    const [docData, setDocData] = useState([]);

    const onSuccessViewMedication = (data) => {
        if (data) {
            setDocData(data);
            setDocument(data);
        }
    };

    usePdfRefDocumentRequestQuery({ onSuccess: onSuccessViewMedication, Id: fileData?.id, typeData: fileData?.resourceType })



    return (
        <>
            {document && ( 
                <Modal
                    backdropClassName
                    backdrop={"static"}
                    size="lg"
                    show={modalShow}
                    onHide={modalClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-dialog"
                >
                    <Modal.Header closeButton className="border-0">
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            style={{ marginRight: "10px" }}
                        >
                            View Prescription
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {fileExtension === "pdf" ?
                            document.code === "AccessDenied" ? <span>{document.message}</span> : <iframe src={document[0]?.content?.[0]?.attachment?.base64String} width="750" height="750"></iframe> : <img src={document?.content?.[0]?.attachment?.base64String} width="750" height="750" />
                        }
                    </Modal.Body>

                </Modal>
            )}
        </>
    );

}

export default ViewPrescriptionModal;