import { Modal } from "react-bootstrap";
import TimeIcon from '../../assets/images/time@3x.png';
import CalendarIcon from '../../assets/images/calender@3x.png';


const GetCareModal = ({getCareModalShow, handleGetCareModalClose, handleBookAppointmentClick, handleWRBtnClick, waitingRoomActive}) => {
    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={getCareModalShow} onHide={handleGetCareModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Get Care</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>When would you like to see a provider?</p>
                <div className="icon-row">
                    <div className="modal-card icon-container" style={{ cursor: 'pointer' }}>
                        <div className="icon-box" onClick={handleBookAppointmentClick}>
                            <img src={CalendarIcon} alt="Calendar Icon" className="modal-icon" />
                        </div>
                        <p className="icon-text">Book An Appointment</p>
                    </div>
                    {waitingRoomActive && (
                        <div className="modal-card icon-container" style={{ cursor: 'pointer' }} onClick={handleWRBtnClick}>
                            <div className="icon-box">
                                <img src={TimeIcon} alt="Time Icon" className="modal-icon" />
                            </div>
                            <p className="icon-text">As Soon As Possible</p>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default GetCareModal;