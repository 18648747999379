import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import TextInput from "../common/textfield/TextInput";
import { Button } from "../common/Button";
import * as Yup from 'yup';
import { addManualReadingForPatient } from "../api/VitalObservation";
import { failed, success } from "../common/Toastify";

const BloodPressureModal = ({ bloodPressureModalShow, bloodPressureModalClose, units, programId }) => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const currentDate = moment(new Date()).format('MM/DD/YYYY');

    const formik = useFormik({
        initialValues: {
            systolic: "",
            diastolic: ""
        },
        validationSchema: Yup.object({
            systolic: Yup.number()
                .required('Systolic is required')
                .integer('Systolic must be a whole number')
                .min(0, 'Systolic must be at least 0')
                .max(200, 'Systolic must be at most 200'),
            diastolic: Yup.number()
                .required('Diastolic is required')
                .integer('Diastolic must be a whole number')
                .min(0, 'Diastolic must be at least 0')
                .max(200, 'Diastolic must be at most 200'),
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            let params = {
                patientId: patientId,
                effectiveDateTime: currentDate,
                conditionName: "blood_pressure",
                programId: programId,
                valueQuantity: {
                    value: {
                        systolic: values.systolic,
                        diastolic: values.diastolic
                    },
                    unit: {
                        systolic: units.systolic,
                        diastolic: units.diastolic
                    },
                },
                device: {
                    reference: 'Device/null',
                }
            };
            addManualReadingForPatient(params)
                .then((res) => success(res?.message))
                .catch((error) => failed(error))
                .finally(() => {
                    setBtnLoading(false);
                    bloodPressureModalClose();
                })
        },
    })

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={bloodPressureModalShow} onHide={bloodPressureModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Blood Pressure Reading</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"systolic"} formik={formik} label={`Systolic (${units.systolic})`} placeholder={"Systolic value here..."} readOnly={false} disabled={false} required={true} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <TextInput keyField={"diastolic"} formik={formik} label={`Diastolic (${units.diastolic})`} placeholder={"Diastolic value here..."} readOnly={false} disabled={false} required={true} />
                        </Col>
                    </Row>

                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                bloodPressureModalClose();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Add
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )


};

export default BloodPressureModal;