export const typeOptions = [
    { value: "all", name: "All" },
    { value: "proposed", name: "Pending" },
    { value: "cancelled", name: "Rejected" },
    { value: "booked", name: "Accepted" },
    { value: "completed", name: "Completed" },
]
export const downloadBtnText = "Download";


export const dashTypes = {
    upcoming: "booked",
    requested: "proposed",
    rejected: "cancelled",
    completed: "completed",
    today: "booked"
}

export const downloadTitle = "Download";
export const eventType = {
    proposed: { name: "Pending", backgroundColor: "#D1D100", textColor: "black" },
    cancelled: { name: "Rejected", backgroundColor: "#7f1734", textColor: "white" },
    booked: { name: "Accepted", backgroundColor: "#06b155", textColor: "white" },
    completed: { name: "Completed", backgroundColor: "grey", textColor: "white" },
}
export function generatePass() {
    var pass = '';
    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 1; i <= 8; i++) {
        var char = Math.floor(Math.random() * str.length + 1);
        pass += str.charAt(char);
    }
    return pass;
}