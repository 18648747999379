import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteRelatedPersonForPatient, getRelatedPersonForPatient, getSingleUser } from "../api/Individual";
import { Col, Row } from "react-bootstrap";
import { failed, success } from "../common/Toastify";
import { CircularProgress, Link, Tooltip } from "@mui/material";
import DeleteIcon from '../../assets/images/delete.png';
import { Button } from "../common/Button";
import AddRelativeModal from "../Settings/AddRelativeModal";
import RelatedPersonModal from "../Home/RelatedPersonModal";
import { getPatientDataForRelatedPerson } from "../api/RelatedPerson";


const RelatedPerson = () => {
    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const userEmail = user?.email;
    const userId = user?.["custom:unique_id"];
    const sub = user?.sub;
    const [relationshipList, setRelationshipList] = useState([]);
    const [inviteSent, setInviteSent] = useState(false);
    const [loadingRelationshipList, setLoadingRelationshipList] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [relatedPerson, setRelatedPerson] = useState(null);
    const [relatedPersonModal, setRelatedPersonModal] = useState(false);
    const [loggedInUserInfo, setLoggedInUserInfo] = useState({});
    const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const currentUser = sessionStorage.getItem("relatedPersonProfile");




    useEffect(() => {
        setLoadingRelationshipList(true);
        getRelatedPersonForPatient(userId)
            .then((res) => setRelationshipList(res?.data))
            .catch((res) => failed(res?.error))
            .finally(() => setLoadingRelationshipList(false))
    }, [modalShow])

    useEffect(() => {
        getSingleUser({ id: patientId, type: "Patient" })
            .then((res) => setLoggedInUserInfo(res?.data))
            .catch((res) => failed(res.message))
    }, []);

    useEffect(() => {
        getPatientDataForRelatedPerson(patientId)
            .then((res) => {
                if (res?.data?.parentPatient?.length > 0) {
                    setRelatedPerson(res?.data?.parentPatient);
                    if (!currentUser) {
                        setRelatedPersonModal(true);
                    }
                }
            })
            .catch((res) => failed(res.message))
    }, [patientId]);

    const handleDeleteClick = (data) => {
        setBtnLoading(true);
        const payload = {
            id: data?.id,
            userId: userId
        }
        deleteRelatedPersonForPatient(payload)
            .then((res) => {
                success(res.message = "Related Person Deleted Successfully");
                setRelationshipList(prevRelationshipList => prevRelationshipList.filter(item => item.id !== data.id));

            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
            }).finally(() => setBtnLoading(false))
    }

    const handleAddRelativeClick = () => {
        setModalShow(true);
    }

    const closeAddRelativeModal = () => {
        setModalShow(false);
    };

    const handleSwitchProfile = () => {
        sessionStorage.removeItem("relatedPersonProfile");
        if (relatedPerson) {
            setRelatedPersonModal(true);
        } else {
            failed("You have no dependents added to your profile");
        }
    }



    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>

            {(btnLoading || loadingRelationshipList) ? (
                <div style={{
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    height: "80vh",
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (

                <div className="custom-card p-4">
                    <form className="common-form border-fields">
                        <Row>
                            <Col>
                                <div className="program-box">
                                    <h4 style={{ textAlign: "center", color: "#005da8" }}>My Relationships</h4>
                                    <div style={{ textTransform: "capitalize" }}>
                                        {relationshipList ? (
                                            relationshipList.length === 0 ? (
                                                <div className="no-data-message">No Data Available</div>
                                            ) : (
                                                relationshipList.map((relation) => (
                                                    <div key={relation?.id} className="program-item">
                                                        <div className="program-info">
                                                            <div className="program-name">{relation?.name?.[0]?.text}</div>
                                                            {relation?.relationship && (
                                                                <div className="program-added-at">Relationship: {relation.relationship}</div>
                                                            )}
                                                            <div className="program-added-at">Request Status: {relation.active ? 'Accepted' : 'Pending'}</div>
                                                        </div>

                                                        <div className="action-wrap" style={{ justifyContent: 'flex-end' }}>
                                                            <Tooltip title={"Delete Relative"}>
                                                                <Link
                                                                    to=""
                                                                    variant="primary"
                                                                    className="delete-btn"
                                                                    onClick={() => handleDeleteClick(relation)}
                                                                >
                                                                    <img src={DeleteIcon} alt="Delete" />
                                                                </Link>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        ) : (
                                            <div className="no-data-message">No Data Available</div>
                                        )}

                                        <div className="button-container" style={{ marginTop: "20px" }}>
                                            <Button className="custom-btn" title="Add Relative" style={{ width: "auto" }} onClick={() => handleAddRelativeClick()}>
                                                Invite Relative
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col>
                                <div className="program-box">
                                    <h4 style={{ textAlign: "center", color: "#005da8" }}>My Dependants</h4>
                                    <div style={{ textTransform: "capitalize" }}>
                                        {relatedPerson ? (
                                            relatedPerson.length === 0 ? (
                                                <div className="no-data-message">No Data Available</div>
                                            ) : (
                                                relatedPerson.map((relation) => (
                                                    <div key={relation?.id} className="program-item">
                                                        <div className="program-info">
                                                            <div className="program-name">{relation?.name?.[0]?.text}</div>
                                                            <div className="program-added-at">Date of Birth: {relation?.birthDate ? relation?.birthDate : '-'}</div>

                                                            <div className="program-added-at">Address: {relation?.address[0]?.line[0] ? relation?.address[0]?.line[0] + ", " + relation?.address[0]?.city + ", " + relation?.address[0]?.country : '-'}</div>
                                                        </div>

                                                        <div className="action-wrap" style={{ justifyContent: 'flex-end' }}>

                                                        </div>
                                                    </div>
                                                ))
                                            )
                                        ) : (
                                            <div className="no-data-message">No Dependants Added</div>
                                        )}

                                        {relatedPerson && relatedPerson.length > 0 && (
                                            <div className="button-container" style={{ marginTop: "20px" }}>
                                                <Button
                                                    className="custom-btn"
                                                    title="Switch Profile"
                                                    style={{ width: "auto" }}
                                                    onClick={() => handleSwitchProfile()}
                                                >
                                                    Switch Profile
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </form>

                </div>
            )}


            {modalShow &&
                <AddRelativeModal
                    show={modalShow}
                    onClose={() => {
                        setModalShow(false);
                        setInviteSent(true);
                    }}
                    userId={userId}
                    setInviteSent={setInviteSent}
                />
            }
            {relatedPersonModal && <RelatedPersonModal relatedPersonModalShow={relatedPersonModal} relatedPersonModalClose={() => setRelatedPersonModal(false)} relationshipData={relatedPerson} loggedUserInfo={loggedInUserInfo} />}

        </section>

    )
}

export default RelatedPerson;