import { Navigate, Outlet, } from 'react-router-dom';
import Sidebar from '../components/common/sidebar/sidebar';
import Header from '../components/common/header/header';
import ChangePassword from '../components/Profile/change-password';
import Home from '../components/Home/Home';
import Settings from '../components/Settings/Settings';
import UserProfile from '../components/Profile/user-profile';
import Conditions from '../components/Condition/Condition';
import VitalReadings from '../components/VitalReadings/VitalReadings';
import MyDashboard from '../components/VitalReadings/MyDashboard';
import Visits from '../components/Visits/Visits';
import ZoomCreate from '../components/Zoom/ZoomCreate';
import ZoomCreateWR from "../components/Zoom/ZoomCreateWR";
import Prescriptions from "../components/Prescriptions/Prescriptions";
import Requisitions from '../components/Requisitions/Requisition';
import Notifications from '../components/Notification/Notifications';
import Medication from '../components/Medication/Medication';
import RelatedPerson from '../components/RelatedPerson/RelatedPerson';
import Appointments from '../components/Appointments/Appointments';

const App = () => {
    return (
        <div className="outer-wrapper">
            <Header />
            <div className="body-outer">
                <Sidebar />
                <div className="body-wrapper">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export const useProtectedRoutes = () => {
    const protectedRoutes = [
        {
            path: '/app',
            element: <App />,
            children: [
                { path: "*", element: <Navigate to="/app/home" /> },
                { path: "change-password", element: <ChangePassword /> },
                { path: "home", element: <Home /> },
                { path: "settings", element: <Settings /> },
                { path: "user-profile", element: <UserProfile /> },
                { path: "conditions", element: <Conditions /> },
                { path: "vitals", element: <VitalReadings /> },
                { path: "vital-dashboard", element: <MyDashboard /> },
                { path: "visits", element: <Visits /> },
                { path: "zoom-meeting", element: <ZoomCreate /> },
                { path: "zoom-meeting-wr", element: <ZoomCreateWR /> },
                { path: "prescriptions", element: <Prescriptions /> },
                { path: "requisitions", element: <Requisitions /> },
                { path: "notifications", element: <Notifications /> },
                { path: "medications", element: <Medication /> },
                { path: "related-person", element: <RelatedPerson /> },
                { path: "appointments", element: <Appointments /> }


            ]
        }
    ]

    return { protectedRoutes };
}

