import React, { useState } from "react";
import CustomPagination from "../common/components/Pagination/CustomPagination";
import Table from "react-bootstrap/Table";
import { TableCell, Skeleton } from "@mui/material";
import { decryptData } from "../EncryptDecrypt";
import { convertVitalsBaseValueToOrgLevelUnit } from "../Admin/Schedule/constants";
import "./patients-detail.css";

const colorGrading = (range) => {
    switch (range) {
        case "High":
            return "red"
        case "Intermediate":
            return "yellow"
        case "Normal":
            return "green"
        default:
            return;
    }
}

const GetVitalsUnit = ({ orgVitalsUnit, conditionKey }) => {
    switch (conditionKey) {
        case "blood_glucose":
            return orgVitalsUnit?.blood_glucose?.glucose
        case "blood_pressure":
            return orgVitalsUnit?.blood_pressure?.systolic
        case "heart_rate":
            return orgVitalsUnit?.heart_rate?.heartRate
        case "oxygen":
            return orgVitalsUnit?.oxygen?.oxygen
        case "temperature":
            return orgVitalsUnit?.temperature?.temperature
        case "weight":
            return orgVitalsUnit?.weight?.weight
        default:
            break;
    }
}


const thStyle = { textAlign: "-webkit-center" }

const ConditionValue = ({ condition, conditionData, orgVitalsUnit, range }) => {
    switch (condition) {
        case "blood_pressure":
            return (
                <td style={{ textAlign: "-webkit-center" }}>
                    <>{conditionData ?
                        <span className={`circle-wrap ${colorGrading(range)}`}>
                            {parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_pressure?.systolic)).toFixed(1) % 1 === 0
                                ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_pressure?.systolic))
                                : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_pressure?.systolic)).toFixed(1) || "-"}
                        </span>
                        : "-"}</>
                </td>
            )
        case "weight":
            return (
                <td style={{ textAlign: "-webkit-center", }}>
                    <>{conditionData ? <span className={`circle-wrap ${colorGrading(range)}`}>
                        {parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.weight?.weight)).toFixed(1) % 1 === 0
                            ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.weight?.weight))
                            : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.weight?.weight)).toFixed(1)
                            || "-"}
                    </span>
                        : "-"}</>
                </td>
            )
        case "blood_glucose":
            return (
                <td style={{ textAlign: "-webkit-center" }}>
                    <>{conditionData ? <span className={`circle-wrap ${colorGrading(range)}`}>
                        {parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_glucose?.glucose)).toFixed(1) % 1 === 0
                            ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_glucose?.glucose))
                            : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.blood_glucose?.glucose)).toFixed(1)
                            || "-"}
                    </span>
                        : "-"}</>
                </td>
            )
        case "oxygen":
            return (
                <td style={{ textAlign: "-webkit-center" }}>
                    <>{conditionData ? <span className={`circle-wrap ${colorGrading(range)}`}>
                        {parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.oxygen?.oxygen)).toFixed(1) % 1 === 0
                            ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.oxygen?.oxygen))
                            : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.oxygen?.oxygen)).toFixed(1)
                            || "-"}
                    </span>
                        : "-"}</>
                </td>
            )
        case "temperature":
            return (
                <td style={{ textAlign: "-webkit-center" }}>
                    <>{conditionData ? <span className={`circle-wrap ${colorGrading(range)}`}>
                        {parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.temperature?.temperature)).toFixed(1) % 1 === 0
                            ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.temperature?.temperature))
                            : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.temperature?.temperature)).toFixed(1)
                            || "-"}
                    </span>
                        : "-"}</>
                </td>
            )
        case "heart_rate":
            return (
                <td style={{ textAlign: "-webkit-center" }}>
                    <>{conditionData ? <span className={`circle-wrap ${colorGrading(range)}`}>
                        {parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.heart_rate?.heartRate)).toFixed(1) % 1 === 0
                            ? parseInt(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.heart_rate?.heartRate))
                            : parseFloat(convertVitalsBaseValueToOrgLevelUnit(conditionData, orgVitalsUnit?.heart_rate?.heartRate)).toFixed(1)
                            || "-"}
                    </span>
                        : "-"}</>
                </td>
            )
        default:
            return (<>{""}</>)
    }
}

const PatientReadingTable = ({ patientsReadingData, isLoading, isFetching, columnsToShow, orgVitalsUnit }) => {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;
    const totalRowsReading = patientsReadingData?.length;

    return (
        <div>
            <Table responsive>
                <thead>
                    <tr>
                        <th style={{ verticalAlign: "top" }} rowSpan="2" colSpan="1">
                            Reading Time
                        </th>
                        {/*<th style={{ verticalAlign: "top" }} rowSpan="2" colSpan="1">
                            Remote
                        </th>*/}
                        {columnsToShow?.["Blood Pressure"] ?
                            <th style={{ ...thStyle, verticalAlign: "top" }} rowSpan="1" colSpan="2">
                                Blood Pressure<br />(<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={"blood_pressure"} />)
                            </th> : null}
                        {columnsToShow?.["Blood Glucose"] ?
                            <th style={{ ...thStyle, verticalAlign: "top" }} rowSpan="2" colSpan="1">
                                Blood Glucose<br />(<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={"blood_glucose"} />)
                            </th> : null}
                        {columnsToShow?.["Weight"] ?
                            <th style={{ ...thStyle, verticalAlign: "top" }} rowSpan="2" colSpan="1">
                                Weight<br />(<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={"weight"} />)
                            </th> : null}
                        {columnsToShow?.["Temperature"] ?
                            <th style={{ ...thStyle, verticalAlign: "top" }} rowSpan="2" colSpan="1">
                                Temperature<br />(<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={"temperature"} />)
                            </th> : null}
                        {columnsToShow?.["Oxygen"] ?
                            <th style={{ ...thStyle, verticalAlign: "top" }} rowSpan="1" colSpan="2">
                                Oxygen (<GetVitalsUnit orgVitalsUnit={orgVitalsUnit} conditionKey={"oxygen"} />)
                            </th> : null}
                    </tr>
                    <tr>
                        {columnsToShow?.["Blood Pressure"] ?
                            <>
                                <th style={thStyle} >SYS</th>
                                <th style={thStyle} >DIA</th>
                            </>
                            : null}
                        {columnsToShow?.["Oxygen"] ?
                            <>
                                <th style={thStyle} >Oxygen</th>
                                <th style={thStyle}>Heart rate</th>
                            </>
                            : null}
                    </tr>
                </thead>
                <tbody>
                    {isLoading || isFetching ? (
                        [1, 2, 3, 4, 5, 6].map((val) => (
                            <tr key={val}>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((index) => (
                                    <td key={index}>
                                        <Skeleton animation="wave" />
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) :
                        patientsReadingData?.length > 0 ?
                            patientsReadingData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data, index) => {
                                const condition = decryptData(data?.code?.text)
                                const rangeCondition = (data?.interpretation && data?.interpretation.length) ? data?.interpretation[0]?.coding[0]?.display : data.range;

                                return (
                                    <tr key={index}>
                                        <td className="text-color">{new Date(data?.createdAt ? data?.createdAt : data?.timestamp).toLocaleString()}</td>
                                        {/*<td>Yes</td>*/}
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.systolic)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.diastolic)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.glucose)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.weight)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.temperature)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.oxygen)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                        <ConditionValue condition={condition} conditionData={decryptData(data?.valueQuantity?.value?.heartRate)} orgVitalsUnit={orgVitalsUnit} range={rangeCondition} />
                                    </tr>
                                );
                            })
                            : (
                                <tr>
                                    <TableCell colSpan="7" style={thStyle}>
                                        No Data Available
                                    </TableCell>
                                </tr>
                            )}
                </tbody>
            </Table>
            {patientsReadingData?.length > 0 ? (
                <div>
                    <CustomPagination tableData={patientsReadingData} totalRows={totalRowsReading} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} />
                </div>
            ) : null}
        </div>
    );
};

export default PatientReadingTable;
