import { CircularProgress, Skeleton, TableCell } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getRequisitions } from "../api/Requisition";
import { failed } from "../common/Toastify";
import CustomPagination from "../common/components/Pagination/CustomPagination";
import { Table } from "react-bootstrap";
import { noDataAvailText, serviceRequestTitle } from "../VitalReadings/Constants";
import moment from "moment";

const Requisitions = () => {

    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [requisitionData, setRequisitionData] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;

    useEffect(() => {
        setBtnLoading(true);
        getRequisitions(patientId)
            .then((res) => {

                setRequisitionData(res?.data);
            })
            .catch((res) => failed(res?.message))
            .finally(() => setBtnLoading(false))
    }, [patientId])



    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>
            
            <div>

                {btnLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80vh",
                        }}
                    >
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <div className="table-wrap">
                        <Table responsive>
                            <thead>
                                <tr>
                                    {serviceRequestTitle?.map((title) => (
                                        <th
                                            key={title}
                                            style={{
                                                textAlign: title === "Edit" ? "center" : "auto",
                                            }}
                                        >
                                            {title}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {btnLoading ? (
                                    [1, 2, 3, 4, 5, 6].map((val) => (
                                        <tr key={val}>
                                            {serviceRequestTitle?.map((index) => (
                                                <td key={index}>
                                                    <Skeleton animation="wave" />
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                ) : requisitionData?.length > 0 ? (
                                    requisitionData
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        ?.map((data) => {
                                            return (
                                                <tr key={data?.id}>
                                                    <td>
                                                        {moment(data?.updatedAt).format("MM/DD/YYYY | HH:mm")}
                                                    </td>
                                                    <td>{data?.practitionerName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                                                    <td>{data?.encounter?.reference.split("/")?.[1] || "NA"}</td>
                                                    <td
                                                        style={
                                                            (data?.status == "active"
                                                                ? { color: "green" }
                                                                : null) ||
                                                            (data?.status == "In Progress"
                                                                ? { color: "grey" }
                                                                : null) ||
                                                            (data?.status == "Cancelled"
                                                                ? { color: "red" }
                                                                : null)
                                                        }
                                                    >
                                                        {
                                                            data.status == "draft" ? data.status = "In Progress".split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') :
                                                                data?.status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}

                                                    </td>
                                                    <td>{data?.category[0]?.coding[0]?.display}</td>

                                                </tr>
                                            );
                                        })
                                ) : (
                                    <tr>
                                        <TableCell
                                            colSpan={serviceRequestTitle.length}
                                            style={{
                                                textAlign: "center",
                                            }}
                                        >
                                            {noDataAvailText}
                                        </TableCell>
                                    </tr>
                                )}
                            </tbody>

                        </Table>
                        {requisitionData?.length > 0 ? (
                            <div>
                                <CustomPagination
                                    tableData={requisitionData}
                                    totalRows={requisitionData?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    handlePage={(pageNo) => setPage(pageNo)}
                                />
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </section>
    );
}

export default Requisitions;