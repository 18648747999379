import { Modal } from "react-bootstrap";
import { Button } from "../common/Button";
import { CircularProgress, lighten } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { regions } from "./Constants";
import { joinWaitingRoom } from "../api/WaitingRoom";
import { failed } from "../common/Toastify";
import { getEncounterById } from "../api/Encounter";
import { getPatientData } from "../api/Individual";
import { encryptData } from "../EncryptDecrypt";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";


const WaitingScreen = ({ waitingScreenShow, waitingScreenClose, encounterInfo }) => {

    const [isLoading, setIsLoading] = useState(false);
    const user = useSelector((state) => state?.auth?.user);
    const [patientName, setPatientName] = useState("");
    const [encounterData, setEncounterData] = useState(null);
    const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const orgId = useSelector((state) => state?.auth?.user?.orgId);
    const navigate = useNavigate();



    useEffect(() => {
        const fetchEncounterData = async () => {
            try {
                if (encounterInfo && encounterInfo.encounterId) {
                    const res = await getEncounterById(encounterInfo.encounterId);
                    setEncounterData(res?.data);
                }
            } catch (error) {
                failed(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (encounterInfo) {
            // Initial fetch
            fetchEncounterData();

            // Set up interval to fetch every 5 seconds
            const intervalId = setInterval(() => {
                fetchEncounterData();
            }, 5000);

            // Clean up the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [encounterInfo]);


    const onEnterBtnClick = async () => {
        if (encounterData && encounterData.sessionToken) {
            // Session token exists, perform the original action
            const base64Url = await encounterData?.sessionToken?.split(".")[1],
                base64 = await base64Url?.replace(/-/g, "+")?.replace(/_/g, "/"),
                buff = await Buffer.from(base64, "base64"),
                payloadinit = await buff.toString("ascii"),
                parseddata = JSON.parse(payloadinit || "{}");
            let encounterId = encounterData ? encounterData?.encounterId : null;
            let topic = parseddata?.tpc.trim();
            await navigate("/app/zoom-meeting-wr", { state: { encounterId, appointmentData: encounterData, topic: encryptData(parseddata?.tpc), sessionToken: encounterData?.sessionToken, password: encryptData(parseddata?.password) } });

        } else {
            // Session token does not exist, show a message
            failed("Please wait for practitioner to join the waiting room.");
        }
    };


    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            show={waitingScreenShow} onHide={waitingScreenClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Waiting Room</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields">
                    <label>There are 10 people ahead of you. The Join Session button will be activated when the practitioner is ready.</label>
                </form>
                <br />

                <div className="action-wrap">
                    <Button
                        variant="primary"
                        className="success-btn"
                        style={{
                            margin: "0 7px 0 0",
                            backgroundColor: (encounterData && encounterData.sessionToken) ? '#00C853' : lighten('#00C853', 0.4),
                        }}
                        onClick={() => onEnterBtnClick()}
                    >
                        Join Session
                    </Button>
                    <Button onClick={() => waitingScreenClose()} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default WaitingScreen;