import { Col, Modal, Row } from "react-bootstrap";
import TextInput from "../common/textfield/TextInput";
import { useFormik } from "formik";
import { Button } from "../common/Button";
import { useState } from "react";
import SelectField from "../common/textfield/SelectField";
import * as Yup from 'yup';
import { inviteRelatedPersonForPatient } from "../api/Individual";
import { failed, success } from "../common/Toastify";
import { useNavigate } from "react-router-dom";

const AddRelativeModal = ({ show, onClose, userId, setInviteSent }) => {

    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();

    const relationsList = [
        {
            name: "Father",
            value: "FATHER",
        },
        {
            name: "Mother",
            value: "MOTHER",
        },
        {
            name: "Son",
            value: "SON",
        },
        {
            name: "Daughter",
            value: "DAUGHTER",
        },
        {
            name: "Brother",
            value: "BROTHER",
        },
        {
            name: "Sister",
            value: "SISTER",
        },
        {
            name: "Spouse/Partner",
            value: "SPOUSE_PARTNER",
        },
        {
            name: "Adult Child",
            value: "ADULT_CHILD",
        },
        {
            name: "Grandparent",
            value: "GRANDPARENT",
        },
        {
            name: "Relative",
            value: "RELATIVE",
        },
        {
            name: "Caretaker",
            value: "CARETAKER",
        }
    ]


    const formik = useFormik({
        initialValues: {
            email: '',
            relation: '',
            id: userId
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Required email field")
                .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
            relation: Yup.string().required("Required Relationship Field")
        }),
        onSubmit: (values) => {
            setBtnLoading(true);
            const payload = {
                patientRefId: values.id,
                relationship: values.relation,
                email: values.email,
                isInvited: true,
                patient: {
                    reference: 'Patient/' + values.id,
                    type: "Patient"
                },
                text: {
                    status: 'generated'
                }
            }

            inviteRelatedPersonForPatient(payload)
                .then((res) => {
                    success(res.message = "Invite sent successfully");
                    setInviteSent(true);

                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => {
                    setBtnLoading(false);
                    onClose();
                })


        }
    });

    const handleRelationshipChange = (event) => {
        formik.setFieldValue("relation", event?.target?.value);
    };



    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size='lg'
            show={show} onHide={onClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">

            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">Invite Relative</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col>
                            <TextInput keyField={"email"} formik={formik} label={"Email Id"} placeholder={"Email Id"} readOnly={false} disabled={false} required={true} />
                        </Col>



                    </Row>
                    <Row>
                        <Col>
                            <SelectField keyField={"relation"} label={"Select Relationship"} formik={formik} placeholder={"Select"} options={relationsList} onChange={(e) => handleRelationshipChange(e)} />
                        </Col>
                    </Row>
                    <hr />
                    <p><b>Alert:</b> By sharing health data, the user will have access to sensitive information about your medical history, treatments, and conditions. Please ensure you trust this individual with personal data, as it may impact your privacy and confidentiality. Proceed with caution and only share with trusted individuals. Your health and privacy are paramount.</p>






                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Invite
                        </Button>
                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}

export default AddRelativeModal;