import { CircularProgress, Tooltip, Typography, lighten } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Audio from "../../assets/images/Audio.svg";
import Video from "../../assets/images/Video.svg";
import { eventType } from "./Constants";
import './style.css';
import moment from "moment";
import { getSingleUser } from "../api/Individual";
import { failed, success } from "../common/Toastify";
import { Link, useNavigate } from "react-router-dom";
import { Close, Done, FilePresent } from "@mui/icons-material";
import { Button } from "../common/Button";
import { AttachementModal } from "./AttachementModal";
import { handleCancelAppointment } from "../api/Appointments";
import { encryptData } from "../EncryptDecrypt";
import { Buffer } from "buffer";



const ViewAppointmentModal = ({ appointmentModalShow, appointmentModalClose, appointmentData, isProviderAppointment = false, handleRescheduleClick }) => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const data = appointmentData;
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [patient, setPatient] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        setBtnLoading(true);
        getSingleUser({ id: patientId, type: "Patient" })
            .then((res) => setPatient(res?.data))
            .catch((res) => failed(res?.error))
            .finally(() => setBtnLoading(false));
    }, []);


    const handleShowFile = async (url) => {
        setShowAttachmentModal(true)
        setAttachmentUrl(url)
    };

    const handleConnect = async (data) => {
        if (data?.isHostJoined !== true) {
            failed("Please wait for the host to start session");
        } else {
            if (data?.sessionToken) {
                setBtnLoading(true)
                const base64Url = await data?.sessionToken?.split(".")[1],
                    base64 = await base64Url?.replace(/-/g, "+")?.replace(/_/g, "/"),
                    buff = await Buffer.from(base64, "base64"),
                    payloadinit = await buff.toString("ascii"),
                    parseddata = JSON.parse(payloadinit || "{}"),
                    patient = data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor,
                    practitioner = data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;
                let encounterId = data?.encounterId ? data?.encounterId : null;
                let topic = parseddata?.tpc.trim();
                await navigate("/app/zoom-meeting", { state: { encounterId, appointmentData: data, topic: encryptData(parseddata?.tpc), sessionToken: data?.sessionToken, password: encryptData(parseddata?.password) } });

            } else { failed("Session token does not exist!") }
        }

    }

    const handleUpdateRequest = (data, status) => {
        setBtnLoading(true);
        let payload = {
            id: data?.id,
            isFromWeb: data?.isFromWeb,
            patientId: data?.patientId,
            practitionerId: data?.practitionerId,
            status: status
        };

        handleCancelAppointment(payload)
            .then((res) => success(res?.message))
            .catch((res) => failed(res?.error))
            .finally(() => {
                setBtnLoading(false);
                appointmentModalClose();
            })
    }

    return (
        <>
            {showAttachmentModal ? <AttachementModal modalShow={showAttachmentModal} url={attachmentUrl} handleShow={() => setShowAttachmentModal(false)} /> : null}

            <Modal
                backdropClassName
                backdrop={'static'}
                show={appointmentModalShow} onHide={appointmentModalClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered className="custom-dialog appointment-custom-modal">
                <Modal.Header closeButton className="border-0">
                    <Modal.Title id="contained-modal-title-vcenter" style={{ textTransform: "capitalize" }}>
                        Appointment Info
                    </Modal.Title>&nbsp;&nbsp;&nbsp;&nbsp;

                    {(data?.status === "booked" && !isProviderAppointment) && (
                        <div className="action-wrap">
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                    <img src={Audio} alt="Audio Channel" />
                                ) : (
                                    <img src={Video} alt="Video Channel" />
                                )}
                            </Tooltip>
                        </div>
                    )}

                    {data?.status === "proposed" && (
                        <div className="action-wrap">
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                    <img src={Audio} alt="Audio Channel" />
                                ) : (
                                    <img src={Video} alt="Video Channel" />
                                )}
                            </Tooltip>
                        </div>
                    )}
                    {/* Appointment type- Rejected */}
                    {data?.status === "cancelled" && (
                        <div className="action-wrap">
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                    <img src={Audio} alt="Audio Channel" />
                                ) : (
                                    <img src={Video} alt="Video Channel" />
                                )}
                            </Tooltip>
                        </div>
                    )}
                    {/* Appointment type- Completed */}
                    {data?.status === "completed" && (
                        <div className="action-wrap">
                            <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                    <img src={Audio} alt="Audio Channel" />
                                ) : (
                                    <img src={Video} alt="Video Channel" />
                                )}
                            </Tooltip>
                        </div>
                    )}
                    <div style={{ background: eventType?.[data?.status]?.backgroundColor, color: eventType?.[data?.status]?.textColor, borderRadius: "2rem", padding: "0 0.7rem", marginLeft: "clamp(0rem, 3.5vw, 3rem)" }}>{eventType?.[data?.status]?.name}</div>


                </Modal.Header>


                {btnLoading ? (
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "80vh"
                    }}>
                        <CircularProgress size={50} />
                    </div>
                ) : (
                    <Modal.Body style={{ fontSize: "18px" }}>
                        <Row>
                            <Col>
                                <b>Appointment Details</b><br />
                                <div className="col-section">
                                    <span className="label-grey">Date:</span>  {`${moment(data?.requestedPeriod?.[0]?.start).format("DD MMM YYYY")}`}<br />
                                    <span className="label-grey">Reason:</span>  {data?.description}<br />
                                    <span className="label-grey">Telephone Number:</span> {data?.intakeQuestions?.callerNumber} {data?.intakeQuestions?.callerNumberExt && data?.intakeQuestions?.callerNumberExt != "" ? 'ext. ' + data?.intakeQuestions?.callerNumberExt : ''}
                                </div>
                            </Col>
                            <Col><br />
                                <div className="col-section" style={{ textTransform: "capitalize" }}>
                                    <span className="label-grey">Time:</span>  {`${moment(data?.requestedPeriod?.[0]?.start).format("HH:mm")} - ${moment(data?.requestedPeriod?.[0]?.end).format("HH:mm")}`} <br />
                                    {/* <span className="label-grey">Description:</span>  {data?.comment}<br /> */}
                                    <span className="label-grey">Attachments:</span>
                                    {data?.document?.length > 0 && data?.document.map((pdfUrl, index) => (<Link onClick={() => handleShowFile(pdfUrl?.url)}> <FilePresent /> </Link>))}
                                    {data?.document?.url && (<Link onClick={() => handleShowFile(data?.document?.url)}><FilePresent /></Link>)}
                                    {!data?.document?.length > 0 && !data?.document?.url && (<span>  -</span>)} <br />
                                    <span className="label-grey">Practitioner:</span>  {data?.practitionerData?.name[0]?.text}
                                </div>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col>
                                <b>Patient Details</b><br />
                                <div className="col-section">
                                    <span className="label-grey">Name: </span>  <span style={{ textTransform: "capitalize" }}>{data?.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display}</span><br />
                                    <span className="label-grey">Date of Birth: </span>{patient?.birthDate}<br />
                                    <span className="label-grey">Email: </span>  {patient?.telecom?.find((ele) => ele?.system === "email")?.value || ""}<br />
                                </div>
                            </Col>
                            <Col><br />
                                <div className="col-section" style={{ textTransform: "capitalize" }}>
                                    <span className="label-grey">Gender: </span>  {patient?.gender}<br />
                                    <span className="label-grey">Age: </span>{moment().diff(moment(patient?.birthDate, 'MM-DD-YYYY'), 'years')}<br />
                                    <span className="label-grey">Phone: </span>  {patient?.telecom?.find((ele) => ele?.system === "phone")?.value || ""}<br />
                                </div>
                            </Col>
                        </Row>

                        <br />

                        <div className="btn-wrap-block">
                            {(data?.status === "booked" && !isProviderAppointment)
                                ? <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around" }}>
                                    {data?.intakeQuestions?.preferredChannel === 'video' || data?.intakeQuestions?.preferredChannel === 'Video' || data?.intakeQuestions?.preferredChannel === 'video/chat'
                                        ? <Button variant="primary" onClick={() => {
                                            if (data?.isHostJoined !== true) {
                                                failed("Please wait for practitioner to start the session.");
                                            } else {
                                                handleConnect(data);
                                            }
                                        }} className="success-btn" style={{ margin: "0 7px 0 0" }}>Connect</Button>
                                        : data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone'
                                            ? <span className="label-grey">Practitioner will call you.&nbsp;&nbsp;</span>
                                            : null
                                    }
                                </div>
                                : null}

                            {data?.status !== "cancelled"
                                ?
                                <>
                                    <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around" }}>

                                        {data?.status === "proposed" ?
                                            !data?.isFromWeb ?
                                                <span className="action-wrap" style={{ display: "flex", justifyContent: "space-around", margin: "0 15px 0 0" }}>Practitioner haven't accepted</span>
                                                : <>
                                                    <span className="action-wrap" style={{ display: "flex", justifyContent: "space-around", marginTop: "0.15rem" }}>
                                                        <Button variant="primary" onClick={() => handleUpdateRequest(data, "booked")}
                                                            className="success-btn" startIcon={<Done style={{ color: "white" }} />} style={{ marginRight: "20px" }}>Accept</Button>
                                                        <Button variant="primary" onClick={() => handleUpdateRequest(data, "cancelled")}
                                                            className="delete-btn" startIcon={<Close style={{ color: "white" }} />}>Reject</Button>
                                                    </span>
                                                </>
                                            : null}
                                        {(data?.status !== "completed" && data?.status !== "proposed") ?
                                            <Button variant="primary" className="view-btn" style={{ margin: "0 7px 0 0" }} onClick={() => handleRescheduleClick()} >Reschedule</Button>
                                            : null}

                                    </div>
                                    {(data?.status === "booked" || (data?.status === "proposed" && !data?.isFromWeb)) ?
                                        <div className="action-wrap" style={{ display: "flex", justifyContent: "space-around" }}>
                                            <Button variant="primary" onClick={() => handleUpdateRequest(data, "cancelled")}
                                                className="delete-btn" style={{ margin: "0" }}>Cancel Appointment</Button>
                                        </div>
                                        : null}


                                </>
                                : null}

                        </div>



                    </Modal.Body>
                )}





            </Modal>

        </>
    )
};

export default ViewAppointmentModal;