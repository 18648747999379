import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadBtnText } from "./Constants";
import { Tooltip } from "@mui/material";
import { useGetDocumentById } from "../../hooks/ReactQueryHooks/useGetDocument";
import heic2any from 'heic2any';

export function AttachementModal({ modalShow, handleShow, url, docData, fromDocument }) {
    const fileExtension = url?.split('.').pop();
    const [document, setDocument] = useState(null)
    const [convertedUrl, setConvertedUrl] = useState(null);

    const onSuccess = (data) => {
        setDocument(data);
    };
    useGetDocumentById({ onSuccess, id: docData?.id });

    useEffect(() => {
        const convertHeicToJpeg = async () => {
            try {
                const blob = await fetch(url).then(res => res.blob());
                const convertedBlob = await heic2any({ blob });
                const convertedUrl = URL.createObjectURL(convertedBlob);
                setConvertedUrl(convertedUrl);
            } catch (error) {
                console.error('Error converting HEIC to JPEG:', error);
            }
        };

        if (fileExtension === "heic") {
            convertHeicToJpeg();
        } else {
            setConvertedUrl(url);
        }

        return () => {
            if (convertedUrl) {
                URL.revokeObjectURL(convertedUrl);
            }
        };
    }, [url, fileExtension]);

    return (
        <>
            <Modal
                backdropClassName
                backdrop={"static"}
                size="lg"
                show={modalShow}
                onHide={handleShow}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="custom-dialog"
            >
                <Modal.Header closeButton className="border-0">
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ marginRight: "10px" }}
                    >
                        {fromDocument ? "Document" : "Attachment"}
                    </Modal.Title>
                    {fileExtension !== "pdf" && <Tooltip title={downloadBtnText}>
                        <a href={url} className="view-btn" download>
                            <DownloadIcon />
                        </a>
                    </Tooltip>}
                </Modal.Header>
                <Modal.Body>
                    {fileExtension === "pdf" ?
                        document?.code === "AccessDenied" ? <span>{document?.message}</span> : <iframe src={fromDocument ? document?.content?.[0]?.attachment?.base64String : convertedUrl} width="750" height="750"></iframe> : <img src={fromDocument ? document?.content?.[0]?.attachment?.base64String : convertedUrl} width="750" height="750" />
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}