import { axios } from "../../lib/axios";
import { useQuery } from "react-query";
import { onError } from "./onError"
import { useSelector } from "react-redux";

export function useGetDocumentQuery({ onSuccess, statusFilter, viewDoc }) {
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

    const fetch = () => {
        return userRoles?.includes("Super-Admin") ? axios.get(`/documentreference?status=${statusFilter}`) : axios.get(`/documentreference?status=${statusFilter}&orgId=${adminOrgs?.[0]?.id}`)
    }

    const query = useQuery(
        "documents",
        () => fetch(),

        {
            select: (data) => data.data,
            onSuccess,
            onError,
            staleTime: viewDoc ? Infinity : 0
        }

    );

    return { ...query };
}

export function useGetDocumentById({ onSuccess, id }) {

    const fetch = () => {
        return id ? axios.get(`/documentreference/${id}`) : ""
    }

    const query = useQuery(
        ["documents", id],
        () => fetch(),

        {
            select: (data) => data.data,
            onSuccess,
            onError
        }

    );

    return { ...query };
}