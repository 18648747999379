export const regions = [
    {
        name: "Alabama",
        value: "US-AL",
        id: "US-AL",
    },
    {
        name: "Alaska",
        value: "US-AK",
        id: "US-AK",
    },
    {
        name: "American Samoa",
        value: "US-AS",
        id: "US-AS",
    },
    {
        name: "Arizona",
        value: "US-AZ",
        id: "US-AZ",
    },
    {
        name: "Arkansas",
        value: "US-AR",
        id: "US-AR",
    },
    {
        name: "California",
        value: "US-CA",
        id: "US-CA",
    },
    {
        name: "Colorado",
        value: "US-CO",
        id: "US-CO",
    },
    {
        name: "Connecticut",
        value: "US-CT",
        id: "US-CT",
    },
    {
        name: "Delaware",
        value: "US-DE",
        id: "US-DE",
    },
    {
        name: "District of Columbia",
        value: "US-DC",
        id: "US-DC",
    },
    {
        name: "Micronesia",
        value: "US-FM",
        id: "US-FM",
    },
    {
        name: "Florida",
        value: "US-FL",
        id: "US-FL",
    },
    {
        name: "Georgia",
        value: "US-GA",
        id: "US-GA",
    },
    {
        name: "Guam",
        value: "US-GU",
        id: "US-GU",
    },
    {
        name: "Hawaii",
        value: "US-HI",
        id: "US-HI",
    },
    {
        name: "Idaho",
        value: "US-ID",
        id: "US-ID",
    },
    {
        name: "Illinois",
        value: "US-IL",
        id: "US-IL",
    },
    {
        name: "Indiana",
        value: "US-IN",
        id: "US-IN",
    },
    {
        name: "Iowa",
        value: "US-IA",
        id: "US-IA",
    },
    {
        name: "Kansas",
        value: "US-KS",
        id: "US-KS",
    },
    {
        name: "Kentucky",
        value: "US-KY",
        id: "US-KY",
    },
    {
        name: "Louisiana",
        value: "US-LA",
        id: "US-LA",
    },
    {
        name: "Maine",
        value: "US-ME",
        id: "US-ME",
    },
    {
        name: "Marshall Islands",
        value: "US-MH",
        id: "US-MH",
    },
    {
        name: "Maryland",
        value: "US-MD",
        id: "US-MD",
    },
    {
        name: "Massachusetts",
        value: "US-MA",
        id: "US-MA",
    },
    {
        name: "Michigan",
        value: "US-MI",
        id: "US-MI",
    },
    {
        name: "Minnesota",
        value: "US-MN",
        id: "US-MN",
    },
    {
        name: "Mississippi",
        value: "US-MS",
        id: "US-MS",
    },
    {
        name: "Missouri",
        value: "US-MO",
        id: "US-MO",
    },
    {
        name: "Montana",
        value: "US-MT",
        id: "US-MT",
    },
    {
        name: "Nebraska",
        value: "US-NE",
        id: "US-NE",
    },
    {
        name: "Nevada",
        value: "US-NV",
        id: "US-NV",
    },
    {
        name: "New Hampshire",
        value: "US-NH",
        id: "US-NH",
    },
    {
        name: "New Jersey",
        value: "US-NJ",
        id: "US-NJ",
    },
    {
        name: "New Mexico",
        value: "US-NM",
        id: "US-NM",
    },
    {
        name: "New York",
        value: "US-NY",
        id: "US-NY",
    },
    {
        name: "North Carolina",
        value: "US-NC",
        id: "US-NC",
    },
    {
        name: "North Dakota",
        value: "US-ND",
        id: "US-ND",
    },
    {
        name: "Northern Mariana Islands",
        value: "US-MP",
        id: "US-MP",
    },
    {
        name: "Ohio",
        value: "US-OH",
        id: "US-OH",
    },
    {
        name: "Oklahoma",
        value: "US-OK",
        id: "US-OK",
    },
    {
        name: "Oregon",
        value: "US-OR",
        id: "US-OR",
    },
    {
        name: "Palau",
        value: "US-PW",
        id: "US-PW",
    },
    {
        name: "Pennsylvania",
        value: "US-PA",
        id: "US-PA",
    },
    {
        name: "Puerto Rico",
        value: "PR",
        id: "PR",
    },
    {
        name: "Rhode Island",
        value: "US-RI",
        id: "US-RI",
    },
    {
        name: "South Carolina",
        value: "US-SC",
        id: "US-SC",
    },
    {
        name: "South Dakota",
        value: "US-SD",
        id: "US-SD",
    },
    {
        name: "Tennessee",
        value: "US-TN",
        id: "US-TN",
    },
    {
        name: "Texas",
        value: "US-TX",
        id: "US-TX",
    },
    {
        name: "Utah",
        value: "US-UT",
        id: "US-UT",
    },
    {
        name: "Vermont",
        value: "US-VT",
        id: "US-VT",
    },
    {
        name: "Virgin Islands",
        value: "US-VI",
        id: "US-VI",
    },
    {
        name: "Virginia",
        value: "US-VA",
        id: "US-VA",
    },
    {
        name: "Washington",
        value: "US-WA",
        id: "US-WA",
    },
    {
        name: "West Virginia",
        value: "US-WV",
        id: "US-WV",
    },
    {
        name: "Wisconsin",
        value: "US-WI",
        id: "US-WI",
    },
    {
        name: "Wyoming",
        value: "US-WY",
        id: "US-WY",
    },
    {
        name: "Alberta",
        value: "CA-AB",
        id: "CA-AB",
    },
    {
        name: "British Columbia",
        value: "CA-BC",
        id: "CA-BC",
    },
    {
        name: "Manitoba",
        value: "CA-MB",
        id: "CA-MB",
    },
    {
        name: "New Brunswick",
        value: "CA-NB",
        id: "CA-NB",
    },
    {
        name: "Newfoundland and Labrador",
        value: "CA-NL",
        id: "CA-NL",
    },
    {
        name: "Northwest Territories",
        value: "CA-NT",
        id: "CA-NT",
    },
    {
        name: "Nova Scotia",
        value: "CA-NS",
        id: "CA-NS",
    },
    {
        name: "Nunavut",
        value: "CA-NU",
        id: "CA-NU",
    },
    {
        name: "Ontario",
        value: "CA-ON",
        id: "CA-ON",
    },
    {
        name: "Prince Edward Island",
        value: "CA-PE",
        id: "CA-PE",
    },
    {
        name: "Quebec",
        value: "CA-QC",
        id: "CA-QC",
    },
    {
        name: "Saskatchewan",
        value: "CA-SK",
        id: "CA-SK",
    },
    {
        name: "Yukon",
        value: "CA-YT",
        id: "CA-YT",
    }
]