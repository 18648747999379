import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";

const TextInput = ({ label, icon = false, type = "", keyField, formik, disabled = false, rightIcon = false, required = true, hideRequired = false, className, isError = false, ...props }) => {
    const [showPass, setShowPass] = useState(false);
    const inputTypeWise = (type, focus) => {
        switch (type) {
            case "phone":
                return (
                    <ReactInputMask mask="(999) 999-9999" value={props.value} onChange={props.onChange} {...formik.getFieldProps(keyField)} {...props} disabled={disabled}>
                        {(inputProps) => <input {...inputProps} type="tel" autoFocus={focus} />}
                    </ReactInputMask>
                );
            case "fax":
                return (
                    <ReactInputMask mask="999-999-9999" value={props.value} onChange={props.onChange} {...formik.getFieldProps(keyField)} {...props} disabled={disabled}>
                        {(inputProps) => <input {...inputProps} type="tel" autoFocus={focus} />}
                    </ReactInputMask>
                );
            case "password":
                return (
                    <>
                        <input type={showPass ? "text" : "password"} placeholder={keyField} {...formik.getFieldProps(keyField)} {...props} endadornment={<InputAdornment position="end"></InputAdornment>} disabled={disabled} />
                        <IconButton aria-label="toggle password visibility" onClick={() => setShowPass(!showPass)}>
                            {showPass ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </>
                );

            default:
                return <input id={keyField} type={type} {...formik.getFieldProps(keyField)} {...props} disabled={disabled} />;
        }
    };
    return (
        <div className={`field-wrap ${className} ${disabled ? "readonly" : ""}`}>
            {label ? <label htmlFor={keyField}>
                {label}
                {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label> : null}
            <div className={`${icon ? "input-icon" : ""}`}>
                {icon ? typeof icon === "object" ? icon : <img src={icon} alt="" title="" /> : null}
                {inputTypeWise(type, formik?.touched[keyField] && formik?.errors[keyField])}
                {rightIcon ? typeof rightIcon === "object" ? rightIcon : <img src={rightIcon} alt="" title="" /> : null}
            </div>
            <div>{isError ? <div className="error-text">{isError}</div> : formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    );
};

export default TextInput;
