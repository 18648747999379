import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { listAppointmentsByStatus } from "../api/Appointments";
import { failed, success } from "../common/Toastify";
import { CircularProgress, Card, CardContent, Typography, Tooltip, lighten } from "@mui/material";
import CalendarIcon from '../../assets/images/cal@2x.png';
import moment from 'moment-timezone';
import TimeIcon from '../../assets/images/time@2x.png';
import UserIcon from '../../assets/images/user@2x.png';
import ViewIcon from '../../assets/images/view.png';
import EditIcon from '../../assets/images/edit.png';
import VideoIcon from '../../assets/images/vedio.png';
import { Button } from '../common/Button';
import CallIcon from '../../assets/images/audioCall.png';
import ViewAppointmentModal from "./ViewAppointmentModal";
import RescheduleModal from "./RescheduleModal";

const Visits = () => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);
    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const user = useSelector((state) => state?.auth?.user);
    const [scheduledAppointments, setScheduledAppointments] = useState([]);
    const [requestedAppointments, setRequestedAppointments] = useState([]);
    const [completedAppointments, setCompletedAppointments] = useState([]);
    const [requestedLoading, setRequestedLoading] = useState(true);
    const [scheduledLoading, setScheduledLoading] = useState(true);
    const [completedLoading, setCompletedLoading] = useState(true);
    const [viewModalShow, setViewModalShow] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState("");
    const [rescheduleModal, setRescheduleModal] = useState(false);

    const sortedByDate = (data, direction = 'desc') => {
        return data?.sort((a, b) => {
            const a_date = moment.tz(a?.requestedPeriod[0]?.start, 'UTC');
            const b_date = moment.tz(b?.requestedPeriod[0]?.start, 'UTC');
            return direction === 'asc' ? a_date - b_date : b_date - a_date;
        });
    };




    const fetchData = () => {

        listAppointmentsByStatus(patientId, "booked")
            .then((res) => {
                setScheduledAppointments(res?.data);
                setScheduledLoading(false);
            })
            .catch((error) => failed(error));

        listAppointmentsByStatus(patientId, "proposed")
            .then((res) => {
                setRequestedAppointments(res?.data);
                setRequestedLoading(false)
            })
            .catch((error) => failed(error))

        listAppointmentsByStatus(patientId, "completed")
            .then((res) => {
                setCompletedAppointments(res?.data);
                setCompletedLoading(false)
            })
            .catch((error) => failed(error))
    };

    useEffect(() => {
        fetchData(); // Initial data fetch

        const intervalId = setInterval(() => {
            fetchData(); // Fetch data every 5 seconds
        }, 5000);

        return () => {
            clearInterval(intervalId); // Cleanup interval on component unmount
        };
    }, []);


    return (
        <section className="common-listing-v" style={{ marginLeft: '100px', display: 'flex', justifyContent: "space-around" }}>
            <div className="custom-card p-4">
                <h2>Scheduled Appointments</h2>
                {scheduledLoading ? (
                    <CircularProgress size={50} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                ) : scheduledAppointments?.length === 0 ? (
                    <Typography variant="body2" style={{ color: '#888', textAlign: "center" }}>
                        No scheduled appointments found
                    </Typography>) : (
                    sortedByDate(scheduledAppointments)?.map(appointment => (

                        <Card key={appointment.id} className="mb-3 mt-3" style={{ backgroundColor: '#E0F7FA', borderRadius: '12px' }}>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <Typography variant="body2" style={{ color: '#888', marginBottom: '5px' }}>
                                            Appointment Slot
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={CalendarIcon} alt="Calendar" style={{ marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                {moment(appointment?.requestedPeriod[0]?.start).format('ddd, MMM DD')}
                                            </Typography>
                                            <img src={TimeIcon} alt="Time" style={{ marginLeft: '18px', marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                {moment(appointment?.requestedPeriod[0]?.start).format('HH:mm')} - {moment(appointment?.requestedPeriod[0]?.end).format('HH:mm')}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <div className="action-wrap" >
                                            <Tooltip title="Modify Appointment">
                                                <div className="view-btn" onClick={() => {
                                                    setSelectedAppointment(appointment);
                                                    setViewModalShow(true);
                                                }}>
                                                    <img src={EditIcon} alt="Modify" />
                                                </div>
                                            </Tooltip>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                    {appointment?.practitionerData?.photo ? (
                                        <img src={appointment?.practitionerData?.photo?.url} alt="Practitioner" style={{ marginRight: '8px', maxWidth: "48px" }} />
                                    ) : (
                                        <img src={UserIcon} alt="User" style={{ marginRight: '8px' }} />
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2" style={{ color: '#888', fontSize: '12px' }}>
                                            Practitioner Name
                                        </Typography>
                                        <Typography variant="body2" style={{ fontSize: '20px' }}>
                                            {appointment?.practitionerData?.name[0]?.text}
                                        </Typography>
                                    </div>
                                    <div className="action-wrap" style={{ marginLeft: 'auto' }}>
                                        {appointment?.intakeQuestions?.preferredChannel === 'phone' || appointment?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                            <Typography variant="body2" style={{ color: '#888', fontSize: '15px', marginRight: '10px' }}>
                                                Practitioner will call you
                                            </Typography>
                                        ) : (
                                            <Button
                                                variant="primary"
                                                className="success-btn"
                                                style={{
                                                    margin: "0 7px 0 0",
                                                    backgroundColor: !appointment.isHostJoined ? lighten('#00C853', 0.4) : '#00C853',
                                                }}
                                                onClick={() => {
                                                    if (!appointment.isHostJoined) {
                                                        failed('Please wait for the host to start this meeting.');
                                                    } else {
                                                        setSelectedAppointment(appointment);
                                                        setViewModalShow(true);
                                                        
                                                    }
                                                }}
                                            >
                                                {appointment?.intakeQuestions?.preferredChannel === 'Video' || appointment?.intakeQuestions?.preferredChannel === 'video/chat' ? (
                                                    <img src={VideoIcon} alt="Video" style={{ marginRight: "10px" }} />
                                                ) : (
                                                    <img src={CallIcon} alt="Call" style={{ marginRight: "10px" }} />
                                                )}
                                                Join
                                            </Button>
                                        )}
                                    </div>

                                </div>

                            </CardContent>
                        </Card>
                    ))
                )}
            </div>

            <div className="custom-card p-4">
                <h2>Requested Appointments</h2>
                {requestedLoading ? (
                    <CircularProgress size={50} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                ) : requestedAppointments?.length === 0 ? (
                    <Typography variant="body2" style={{ color: '#888', textAlign: "center" }}>
                        No requested appointments found
                    </Typography>) : (
                    sortedByDate(requestedAppointments)?.map(appointment => (

                        <Card key={appointment.id} className="mb-2" style={{ backgroundColor: '#E0F7FA', borderRadius: '12px' }}>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <Typography variant="body2" style={{ color: '#888', marginBottom: '5px' }}>
                                            Appointment Slot
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={CalendarIcon} alt="Calendar" style={{ marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                {moment(appointment?.requestedPeriod[0]?.start).format('ddd, MMM DD')}
                                            </Typography>
                                            <img src={TimeIcon} alt="Time" style={{ marginLeft: '18px', marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                {moment(appointment?.requestedPeriod[0]?.start).format('HH:mm')} - {moment(appointment?.requestedPeriod[0]?.end).format('HH:mm')}
                                            </Typography>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                    {appointment?.practitionerData?.photo ? (
                                        <img src={appointment?.practitionerData?.photo?.url} alt="Practitioner" style={{ marginRight: '8px', maxWidth: "48px" }} />
                                    ) : (
                                        <img src={UserIcon} alt="User" style={{ marginRight: '8px' }} />
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2" style={{ color: '#888', fontSize: '12px' }}>
                                            Practitioner Name
                                        </Typography>
                                        <Typography variant="body2" style={{ fontSize: '20px' }}>
                                            {appointment?.practitionerData?.name[0]?.text}
                                        </Typography>
                                    </div>
                                    <div style={{ marginLeft: 'auto' }} onClick={() => {
                                        setSelectedAppointment(appointment);
                                        setViewModalShow(true);
                                    }}>
                                        <Button variant="primary" className="success-btn" style={{ margin: "0 7px 0 0" }}>
                                            {appointment?.intakeQuestions?.preferredChannel === 'Video' || appointment.intakeQuestions?.preferredChannel === 'video/chat' ? (
                                                <img src={VideoIcon} alt="Video" style={{ marginRight: "10px" }} />
                                            ) : (
                                                <img src={CallIcon} alt="Call" style={{ marginRight: "10px" }} />
                                            )}
                                            View
                                        </Button>
                                    </div>

                                </div>

                            </CardContent>
                        </Card>
                    ))
                )}
            </div>

            <div className="custom-card p-4">
                <h2>Completed Appointments</h2>
                {completedLoading ? (
                    <CircularProgress size={50} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                ) : completedAppointments?.length === 0 ? (
                    <Typography variant="body2" style={{ color: '#888', textAlign: "center" }}>
                        No completed appointments found
                    </Typography>) : (
                    sortedByDate(completedAppointments)?.map(appointment => (

                        <Card key={appointment.id} className="mb-2" style={{ backgroundColor: '#E0F7FA', borderRadius: '12px' }}>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div>
                                        <Typography variant="body2" style={{ color: '#888', marginBottom: '5px' }}>
                                            Appointment Slot
                                        </Typography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={CalendarIcon} alt="Calendar" style={{ marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                {moment(appointment?.requestedPeriod[0]?.start).format('ddd, MMM DD')}
                                            </Typography>
                                            <img src={TimeIcon} alt="Time" style={{ marginLeft: '18px', marginRight: '5px' }} />
                                            <Typography variant="body2">
                                                {moment(appointment?.requestedPeriod[0]?.start).format('HH:mm')} - {moment(appointment?.requestedPeriod[0]?.end).format('HH:mm')}
                                            </Typography>
                                        </div>
                                    </div>

                                </div>
                                <div style={{ borderTop: '1px solid #ccc', margin: '10px 0' }}></div>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                    {appointment?.practitionerData?.photo ? (
                                        <img src={appointment?.practitionerData?.photo?.url} alt="Practitioner" style={{ marginRight: '8px', maxWidth: "48px" }} />
                                    ) : (
                                        <img src={UserIcon} alt="User" style={{ marginRight: '8px' }} />
                                    )}
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="body2" style={{ color: '#888', fontSize: '12px' }}>
                                            Practitioner Name
                                        </Typography>
                                        <Typography variant="body2" style={{ fontSize: '20px' }}>
                                            {appointment?.practitionerData?.name[0]?.text}
                                        </Typography>
                                    </div>
                                    <div style={{ marginLeft: 'auto' }} onClick={() => {
                                        setSelectedAppointment(appointment);
                                        setViewModalShow(true);
                                    }}>
                                        <Button variant="primary" className="success-btn" style={{ margin: "0 7px 0 0" }}>
                                            {appointment?.intakeQuestions?.preferredChannel === 'Video' || appointment.intakeQuestions?.preferredChannel === 'video/chat' ? (
                                                <img src={VideoIcon} alt="Video" style={{ marginRight: "10px" }} />
                                            ) : (
                                                <img src={CallIcon} alt="Call" style={{ marginRight: "10px" }} />
                                            )}
                                            View
                                        </Button>
                                    </div>

                                </div>

                            </CardContent>
                        </Card>
                    ))
                )}
            </div>

            {viewModalShow && <ViewAppointmentModal appointmentModalShow={viewModalShow} appointmentModalClose={() => {
                setSelectedAppointment("");
                setViewModalShow(false);
            }} appointmentData={selectedAppointment} handleRescheduleClick={() => {
                setViewModalShow(false);
                setRescheduleModal(true);
            }} />}

            {rescheduleModal && <RescheduleModal rescheduleModalShow={rescheduleModal} rescheduleModalClose={() => setRescheduleModal(false)} previousAppointmentData={selectedAppointment} />}
        </section>
    )
}

export default Visits;