import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteRelatedPersonForPatient, getProgramsForPatient, getRelatedPersonForPatient, getSettingsForPatient, updateSettingsForPatient } from "../api/Individual";
import { CircularProgress, Link, Switch, Tooltip } from "@mui/material";
import './Settings.css';
import { failed, success } from "../common/Toastify";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import DeleteIcon from '../../assets/images/delete.png';
import { Button } from "../common/Button";
import AddRelativeModal from "./AddRelativeModal";
import { useNavigate } from "react-router-dom";

const Settings = () => {

    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const navigate = useNavigate();
    const userEmail = user?.email;
    const userId = user?.["custom:unique_id"];
    const sub = user?.sub;
    const [settings, setSettings] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [relationshipList, setRelationshipList] = useState([]);
    const [inviteSent, setInviteSent] = useState(false);


    const [loadingSettings, setLoadingSettings] = useState(false);
    const [loadingRelationshipList, setLoadingRelationshipList] = useState(false);
    const [loadingProgramList, setLoadingProgramList] = useState(false);

    const [modalShow, setModalShow] = useState(false);

    const notificationLabels = {
        isMFAenabled: "Multi Factor Authentication",
        isNewMessage: "New Message",
        isNewNotification: "Email Notification",
        isRequestAccepted: "Request Accepted",
        isRequestDenied: "Request Denied",
        isSessionStarted: "Session Started"
    }


    useEffect(() => {
        setLoadingSettings(true);
        getSettingsForPatient({ payload: user })
            .then((res) => {
                setSettings(res?.data)

            })
            .catch((res) => {
                failed(res?.error);
                navigate("/app/home");
            })
            .finally(() => setLoadingSettings(false))

    }, []);

    useEffect(() => {
        setLoadingRelationshipList(true);
        getRelatedPersonForPatient(userId)
            .then((res) => setRelationshipList(res?.data))
            .catch((res) => failed(res?.error))
            .finally(() => setLoadingRelationshipList(false))
    }, [modalShow])

    useEffect(() => {
        setLoadingProgramList(true);

        getProgramsForPatient(userId)
            .then((res) => {
                setProgramList(res?.data || []);
            })
            .catch((error) => console.log("Error fetching programs"))
            .finally(() => setLoadingProgramList(false));

    }, []);

    const handleToggle = (setting, value) => {
        setBtnLoading(true);

        const payload = {
            type: setting,
            value: value,
            user: {
                sub: sub,
                email: userEmail
            }
        };

        updateSettingsForPatient(payload).then((res) => {
            setSettings(prevSettings => ({
                ...prevSettings,
                [setting]: value
            }));
            success(res.message = "Settings Updated Successfully");
        })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
            }).finally(() => setBtnLoading(false))
    }

    const handleDeleteClick = (data) => {
        setBtnLoading(true);
        const payload = {
            id: data?.id,
            userId: userId
        }
        deleteRelatedPersonForPatient(payload)
            .then((res) => {
                success(res.message = "Related Person Deleted Successfully");
                setRelationshipList(prevRelationshipList => prevRelationshipList.filter(item => item.id !== data.id));

            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message)
            }).finally(() => setBtnLoading(false))
    }

    const handleAddRelativeClick = () => {
        setModalShow(true);
    }

    const closeAddRelativeModal = () => {
        setModalShow(false);
    };




    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>
            
            {(btnLoading || loadingSettings || loadingRelationshipList || loadingProgramList) ? (
                <div style={{
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: "center",
                    height: "80vh",
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (

                <div className="custom-card p-4">
                    <form className="common-form border-fields">
                        <Row>
                            <Col>
                                <div className="program-box">
                                    <h4 style={{ textAlign: "center", color: "#005da8" }}>Notifications</h4>
                                    <div>
                                        {Object.entries(settings).map(([key, value]) => (
                                            <div key={key} className="setting-item">
                                                {notificationLabels[key]}
                                                <Switch name="test" defaultChecked={value} onChange={(e) => handleToggle(key, e.target.checked)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>

                            

                            <Col>
                                <div className="program-box">
                                    <h4 style={{ textAlign: "center", color: "#005da8" }}>Programs</h4>
                                    <div>
                                        {programList && programList.length === 0 ? (
                                            <div className="no-data-message">No Data Available</div>
                                        ) : (

                                            programList.map((program) => (
                                                <div key={program?.programId} className="program-item">
                                                    <div className="program-info">
                                                        <div className="program-name">{program?.program?.title}</div>
                                                        <div className="program-added-at">Added: {moment(program?.program?.addedAt).format('DD MMM, Y')}</div>

                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>

                </div>
            )}


            {modalShow &&
                <AddRelativeModal
                    show={modalShow}
                    onClose={() => {
                        setModalShow(false);
                        setInviteSent(true);
                    }}
                    userId={userId}
                    setInviteSent={setInviteSent}
                />
            }
        </section>

    )
}

export default Settings;