import { DateSelectorWithoutFormik } from "../common/textfield/DateSelector";
import { filterOptions, intialVitalOptions } from "./Constants";
import moment from "moment";
import { getPatientLatestReadingDate, getProgramEnrolledForPatient, getThreshold } from "../api/VitalObservation";
import { useEffect, useState } from "react";
import { useGetOrgVitalsUnits } from "../../hooks/ReactQueryHooks/useGetOrgVitalsUnits";
import { usePatientReadingQuery } from "../../hooks/ReactQueryHooks/usePatientReadingQuery";
import { useSelector } from "react-redux";
import PatientReadingTable from "./patient-reading-table";
import { failed } from "../common/Toastify";
import { CircularProgress } from "@mui/material";

const MyDashboard = () => {
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);
    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const userOrg = useSelector((state) => state?.auth?.user?.orgId);
    const [btnLoading, setBtnLoading] = useState(false);
    const [patientReadingData, setPatientReadingData] = useState("");
    const [vitalsColumn, setVitalsColumn] = useState(intialVitalOptions);
    const [thresholdData, setThresholdData] = useState([]);
    const [PatientReadingDate, setPatientReadingDate] = useState(moment().format("MM/DD/YYYY"));
    const { data: orgVitalsUnit } = useGetOrgVitalsUnits({ orgId: userOrg });

    const onSuccessPatient = (data) => {
        setPatientReadingData(data)
    };

    const { isLoading: isLoadingPatient, isFetching: isFetchingPatient, refetch: refetchPatient } = usePatientReadingQuery({ onSuccess: onSuccessPatient, id: patientId, date: PatientReadingDate });
    useEffect(() => {
        setBtnLoading(true);
        getPatientLatestReadingDate(patientId)
            .then((response) => {
                setPatientReadingDate(response?.data?.[0]?.effectiveDateTime);
            })
            .catch((error) => { console.log(error); })

        getThreshold(patientId).then((res) => {
            setThresholdData(res.data)
        }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
            .finally(() => setBtnLoading(false));

    }, []);

    return (
        <section className="common-listing" style={{ marginLeft: '100px' }}>
            

            {btnLoading ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <div>
                    <div className="mnth-date-wrap">
                        <div style={{ marginRight: "1rem" }}>
                            <span>Select Date: </span>
                            <DateSelectorWithoutFormik
                                label={false}
                                maxDate={moment()}
                                value={PatientReadingDate}
                                handleChange={(val) =>
                                    setPatientReadingDate(val.format("MM/DD/YYYY"))
                                }
                            />
                        </div>
                    </div>



                    <div className="table-wrap diff-head">
                        <PatientReadingTable
                            orgVitalsUnit={orgVitalsUnit}
                            patientsReadingData={patientReadingData}
                            isLoading={isLoadingPatient}
                            isFetching={isFetchingPatient}
                            thresholdData={thresholdData}
                            columnsToShow={vitalsColumn}
                        />
                    </div>

                </div>
            )}
        </section>
    )

}

export default MyDashboard;