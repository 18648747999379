import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Button } from "../common/Button";
import TextInput from "../common/textfield/TextInput";
import TextArea from "../common/textfield/TextArea";
import SelectFiled from "../common/textfield/SelectField";
import { CircularProgress, Skeleton } from "@mui/material";
import { DateSelectorWithoutFormik } from "../common/textfield/DateSelector";
import moment from "moment";
import { failed, success } from "../common/Toastify";
import "./style.css";
import * as Yup from "yup";
import { getSlots, checkShedule, bookAppointment, updateAppointmentRequest, getSignature } from "../api/Reschedule";
import { generatePass } from "./Constants";



const RescheduleModal = ({ rescheduleModalShow, rescheduleModalClose, previousAppointmentData }) => {
    const user = useSelector((state) => state?.auth?.user);
    const [btnLoading, setBtnLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState("");
    const [serviceCategory, setServiceCategory] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [todayDate, setTodayDate] = useState(moment(new Date()).format('YYYY-MM-DD'));


    const formik = useFormik({
        initialValues: {
            reason: "",
            patient: "",
            date: todayDate,
            serviceCategory: "",
            availableSlot: "",
        },
        validationSchema: Yup.object({
            reason: Yup.string().required("Required Reason field"),
            patient: Yup.string().required("Required Patient field"),
            date: Yup.string().required("Required Date field"),
            serviceCategory: Yup.string().required("Required Date field"),
            availableSlot: Yup.object().required("Required Date field"),
        }),
        onSubmit: async (values) => {
            setBtnLoading(true);

            try {
                // Step 1: Cancel the previous appointment
                await updateAppointmentRequest({
                    id: previousAppointmentData?.id,
                    status: "cancelled",
                    patientId: previousAppointmentData?.patientId,
                    practitionerId: previousAppointmentData?.practitionerId,
                    sessionToken: previousAppointmentData?.sessionToken
                });

                // Step 2: Get the signature for the new appointment
                const topic = 'Appointment-Fonemed';
                const password = generatePass();
                const signatureResponse = await getSignature({ topic: topic.trim(), role: 1, password: password });
                const sessionToken = signatureResponse.data.sessionToken;

                // Step 3: Book the new appointment
                //const requestedPeriod = [
                //{
                //    start: moment(values?.date + ' ' + values?.availableSlot?.start).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                //    end: moment(values?.date + ' ' + values?.availableSlot?.end).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                //}
                //];

                let start = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.start)}:00Z`;
                let localStartTime = moment(start).format("HH:mm")
                let localStart = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localStartTime)}`;
                let utcStart = moment(localStart).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
                let end = `${moment(values?.date)?.format("YYYY-MM-DD")}T${(values?.availableSlot?.end)}:00Z`;
                let localEndTime = moment(end).format("HH:mm")
                let localEnd = `${moment(values?.date)?.format("YYYY-MM-DD")} ${(localEndTime)}`;
                let utcEnd = moment(localEnd).utc().format("YYYY-MM-DDTHH:mm:ss[Z]")
                const requestedPeriod = [{ start: utcStart, end: utcEnd }]


                await bookAppointment({
                    document: previousAppointmentData?.document,
                    sessionToken,
                    practitionerId: previousAppointmentData?.practitionerId,
                    patientId: previousAppointmentData.patientId,
                    requestedPeriod,
                    description: values?.reason,
                    comment: values?.description,
                    isReschedule: true,
                    intakeQuestions: previousAppointmentData?.intakeQuestions
                });

                // Step 4: Display success message and close the modal
                success("Appointment Rescheduled");
                rescheduleModalClose();

            } catch (error) {
                failed(error?.response?.data?.message || error?.response?.data?.error || error?.message);
            } finally {
                setBtnLoading(false);
            }
        }
    });

    useEffect(() => {
        if (previousAppointmentData?.id) {
            formik.setFieldValue("reason", previousAppointmentData.description);
            formik.setFieldValue("patient", previousAppointmentData.participant?.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor?.display);

        }
    }, [previousAppointmentData]);

    useEffect(() => {
        if (formik?.values?.date) {
            checkShedule({ actorId: previousAppointmentData?.practitionerId, date: moment(formik?.values?.date).format("YYYY-MM-DD") })
                .then((res) => {
                    setServiceCategory(res?.data?.serviceCategory
                        ?.filter((categoryData) => moment(categoryData?.date?.split("T")?.[0]).weekday() === moment(formik?.values?.date).weekday())
                        ?.map((categoryData) => ({ ...categoryData, value: categoryData?.id })))
                    !res?.data && failed(res?.message)
                })
                .catch((res) => {
                    failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                });
        }
    }, [formik?.values?.date, previousAppointmentData?.id]);

    const handleServiceCategory = (event) => {
        formik.setFieldValue("serviceCategory", event?.target?.value);
        setSlotsLoading(true)
        getSlots({ actorId: previousAppointmentData?.practitionerId, date: moment(formik?.values?.date).format("YYYY-MM-DD"), serviceCategoryId: event?.target?.value })
            .then((res) => {
                if (res?.data?.availableSlots?.length === 0) {
                    failed("No Slots available for selected date!")
                } else {
                    setAvailableSlots(res?.data?.availableSlots)
                }
            }).catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => { setSlotsLoading(false) })
    };

    const handleDateChange = (val) => {
        formik.setFieldValue("date", val.format("YYYY-MM-DD"));
        formik.setFieldValue("serviceCategory", "");
        setAvailableSlots([])
    };

    return (
        <Modal
            backdropClassName
            backdrop={'static'}
            size="lg" show={rescheduleModalShow} onHide={rescheduleModalClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered className="custom-dialog">
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Rescheduling Appointment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="common-form border-fields" onSubmit={formik.handleSubmit}>

                    <>
                        <Row>
                            <Col>
                                <TextInput keyField={"reason"} label={"Reason"} formik={formik} placeholder={"Title"} />
                            </Col>
                            <Col>
                                <TextInput disabled={true} keyField={"patient"} label={"Patient"} formik={formik} placeholder={"Patient"} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DateSelectorWithoutFormik label="Date" value={moment(formik?.values?.date)} handleChange={handleDateChange} minDate={moment()} />
                            </Col>
                            <Col>
                                <SelectFiled keyField={"serviceCategory"} label={"Select Service Category"} formik={formik} options={serviceCategory} onChange={handleServiceCategory} />
                            </Col>
                        </Row>
                        {availableSlots?.length > 0 ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Row style={{ justifyContent: "center", rowGap: "0.5rem", marginBottom: "1.5rem", marginTop: "0.5rem" }}>
                                    {availableSlots?.map((slot, index) => {
                                        let time = `${moment(formik?.values?.date)?.format("YYYY-MM-DD")}T${(slot?.start)}:00Z`;
                                        let localTime = moment(time).format("HH:mm");
                                        return (<Col style={{ background: selectedSlot === slot?.start ? "#6c757d38" : "#ffff", width: "14.666667%" }} sm={2} className="slotStyle" key={index} onClick={() => { formik?.setFieldValue("availableSlot", slot); setSelectedSlot(slot?.start) }}>{localTime}</Col>)
                                    })}                                </Row>
                            </>
                            : null}
                        {slotsLoading ?
                            <>
                                <Row>
                                    <Col>Select Slot</Col>
                                </Row>
                                <Skeleton height={50} />
                            </>
                            : null}

                    </>

                    <div className="btn-wrap">
                        <Button
                            onClick={() => {
                                rescheduleModalClose();
                            }}
                            variant="secondary"
                            title="Cancel"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" isLoading={btnLoading}>
                            Done
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );




};

export default RescheduleModal;