import React, { useState } from 'react'
import { Modal, } from 'react-bootstrap'
import { withLocalTime } from '../../utils/DateSupport'
import { Button } from '../common/Button';
import { updateNotification } from '../api/Notification';
import { failed, success } from '../common/Toastify';
import { acceptRelatedPerson, rejectRelatedPerson } from '../api/RelatedPerson';

const ViewUserNotificationModal = ({ modalShow, handleShow }) => {
    const [btnLoading, setBtnLoading] = useState(false);
    const handleAcceptClick = (item, type) => {
        setBtnLoading(true);

        let request = {
            notificationId: item.notificationId,
            patientId: item.id,
            isAnswered: true,
            status: type,
        };

        let updateNotificationPayload = {
            id: item.id,
            patientRefId: item.patientRefId,
            active: true,
        };


        acceptRelatedPerson(updateNotificationPayload)
            .then((res) => {
                success(res?.message);
            })
            .catch((res) => failed(res?.message))

        updateNotification(request)
            .then((res) => {
                success(res?.message);
                handleShow();
            })
            .catch((res) => failed(res?.message))
            .finally(() => setBtnLoading(false))

    };

    const handleRejectClick = (item, type) => {
        setBtnLoading(true);
        let request = {
            notificationId: item.notificationId,
            patientId: item.id,
            isAnswered: true,
            status: type,
        };

        let updateNotificationPayload = {
            id: item.id,
            patientRefId: item.patientRefId,
            active: true,
        };

        if (!type && !item.relative?.id) {
            rejectRelatedPerson({ id: item.id, userId: item.patientRefId })
                .then((res) => {
                    success(res?.message);
                })
                .catch((res) => {
                    failed(res?.message);
                })
        }




        updateNotification(request)
            .then((res) => {
                success(res?.message);
                handleShow();
            })
            .catch((res) => failed(res?.message))
            .finally(() => setBtnLoading(false))
    }

    return (
        <Modal
            show={modalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-dialog"
            onHide={handleShow}
            backdropClassName
            backdrop={'static'}
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    View Notification
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{ fontSize: "1rem" }} >
                    {withLocalTime(modalShow?.message)}
                </p>

                {!modalShow.isAnswered && modalShow?.type === "accept/reject" ? (
                    <div className="btn-wrap">
                        <Button onClick={() => handleAcceptClick(modalShow, true)} isLoading={btnLoading}>
                            Accept
                        </Button>
                        <Button onClick={() => handleRejectClick(modalShow, false)} variant="secondary" title="Cancel" style={{ marginLeft: '10px' }}>
                            Reject
                        </Button>
                    </div>
                ) : (
                    <div>

                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default ViewUserNotificationModal