export const headerTitles = {
    "/app/home": "Home",
    "/app/change-password": "Change Password",
    "/app/settings": "Settings",
    "/app/user-profile": "Profile",
    "/app/conditions": "Conditions",
    "/app/vitals": "Vitals",
    "/app/vital-dashboard": "Vitals Dashboard",
    "/app/visits": "Visits",
    "/app/zoom-meeting": "Zoom Meeting",
    "/app/zoom-meeting-wr": "Zoom Meeting",
    "/app/prescriptions": "Prescriptions",
    "/app/requisitions": "Requisitions",
    "/app/notifications": "Notifications",
    "/app/related-person": "Related Persons",
    "/app/appointments": "Visits"
}