import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addMedicationTakenForPatient, listMedicationsForPatient } from "../api/Medication";
import { failed, success } from "../common/Toastify";
import { CircularProgress } from "@mui/material";
import { DateSelectorWithoutFormik } from "../common/textfield/DateSelector";
import DrugIcon from "../../assets/images/drugs.png";
import { Table } from "react-bootstrap";
import { MedicationHeadTitle } from './Constants';
import { Skeleton, TableCell } from "@mui/material";
import TakenIcon from '../../assets/images/check-mark.png';
import ErrorIcon from '../../assets/images/error-icon.png';
import NotTakenIcon from '../../assets/images/prohibition-symbol.png';
import { decryptData } from "../EncryptDecrypt";
import './medicationStyle.css';
import { Button } from "../common/Button";





const Medication = () => {
    const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [MedicationData, setMedicationData] = useState([]);
    const [MedicationDate, setMedicationDate] = useState(moment().format("YYYY-MM-DD"));
    const currentDate = new Date();



    useEffect(() => {
        let payload = {
            date: MedicationDate
        };
        setBtnLoading(true);
        listMedicationsForPatient(payload, patientId)
            .then((res) => {
                let data = res?.data?.medication;
                if (!data || Object.keys(data).length === 0) {
                    setMedicationData([]); // or setMedicationData({}) depending on your data structure
                    return;
                }
                let formatDataByTime = {};
                Object?.keys(data)?.forEach((medId) => {
                    data?.[medId]?.repeats?.forEach(({ time, status }) => {
                        const newTime = moment(moment().format("YYYY-MM-DD") + ' ' + time).format("HH:mm");
                        if (!formatDataByTime[newTime]) formatDataByTime[newTime] = [{ ...data?.[medId], time, status, medId }];
                        else formatDataByTime[newTime] = [...formatDataByTime[newTime], { ...data?.[medId], status, medId }];
                    });
                });
                setMedicationData(formatDataByTime || []);
            })
            .catch((error) => failed(error))
            .finally(() => setBtnLoading(false))
    }, [MedicationDate]);

    const handleTakenClick = (time, status, medId) => {
        setBtnLoading(true);
        let payload = {
            request: {
                isMobile: true,
                date: moment(MedicationDate).format('YYYY-MM-DD'),
                time: time,
                status: status == 'Taken' ? 'Take' : 'Taken',
            },
            data: {
                patientId: patientId,
                medicationId: medId
            }
        };

        console.log("Payload to be sent: ", payload);

        addMedicationTakenForPatient(payload)
            .then((res) => {
                success(res?.message);
            })
            .catch((res) => failed(res?.error))
            .finally(() => setBtnLoading(false))

    };


    return (
        <>
            <section className="common-listing" style={{ marginLeft: "100px" }}>
                <div className="mnth-date-wrap">
                    <span>Select Date: </span>
                    <br />
                    <DateSelectorWithoutFormik
                        label={false}
                        value={moment(MedicationDate)}
                        handleChange={(val) => setMedicationDate(val.format("YYYY-MM-DD"))}
                    />
                </div>
                <div className="table-wrap medication-wrap">
                    <Table responsive>
                        <thead>
                            <tr>
                                {MedicationHeadTitle?.map(title => <th key={title} style={{
                                    textAlign: title === "Edit" ? "center" : "auto"
                                }}>{title}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {btnLoading
                                ? [1, 2, 3, 4, 5, 6].map(val => <tr key={val}>
                                    {MedicationHeadTitle?.map(index => <td key={index}>
                                        <Skeleton animation="wave" />
                                    </td>)}
                                </tr>)
                                : Object.keys(MedicationData).length > 0 ? Object.keys(MedicationData)?.map(timeGroup => {
                                    return MedicationData?.[timeGroup]?.map((med, index) => {
                                        let { name, dose, status, time, id } = med;
                                        name = decryptData(name);
                                        return <tr key={`${timeGroup}-${index}`}>
                                            {time
                                                ? <td rowSpan={MedicationData?.[timeGroup].length} style={{
                                                    whiteSpace: "nowrap",
                                                    paddingLeft: "20px"
                                                }}>
                                                    {timeGroup}
                                                    {MedicationData?.[timeGroup]?.some(() => status?.toLowerCase() === "not-taken") ? <img src={ErrorIcon} className="error-icon" alt={name} /> : null}
                                                </td>
                                                : null}
                                            <td>
                                                <div className="medi-wrap">
                                                    <div className="img-wrap">
                                                        <img src={DrugIcon} alt={name} />
                                                    </div>
                                                    <div className="text-wrap">
                                                        <h4>{name}</h4>
                                                        <p>Dose: {dose}</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="medi-status">
                                                    <span>-</span>
                                                </div>
                                            </td>

                                        </tr>;
                                    });
                                }) : <tr>
                                    <TableCell colSpan={MedicationHeadTitle.length} style={{
                                        textAlign: "center"
                                    }}>
                                        No Medications Available
                                    </TableCell>
                                </tr>}
                        </tbody>
                    </Table>
                </div>
            </section>

        </>

    )



};

export default Medication;