import { TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import React, { useState } from 'react';

const onKeyDown = (e) => {
    e.preventDefault();
};
//const ageLimit = moment().subtract(10, "years")
function DateSelector({ keyField, formik, label, minDate = moment().subtract(200, "years"), maxDate = moment(), required = true, hasValue = false, readOnly = false }) {
    const [value, setValue] = useState(hasValue || "");
    const [open, setOpen] = useState(false)
    const handleChange = (val) => {
        formik.setFieldValue(keyField, moment(val).format("MM/DD/YYYY"));
        setValue(val);
    };
    //useEffect(() => {
    //    !hasValue && formik.setFieldValue(keyField, moment(ageLimit).format("MM-DD-YYYY"));
    //    // eslint-disable-next-line react-hooks/exhaustive-deps
    //}, [])
    return (
        <div className="field-wrap createPatientDOB">
            <label htmlFor={keyField}>
                {label}
                {required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={false}
                    inputFormat="MM-DD-YYYY"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={hasValue ? moment(formik.values[keyField], "MM-DD-YYYY") : value}
                    onChange={handleChange}
                    readOnly={readOnly}
                    closeOnSelect
                    onOpen={() => !readOnly && setOpen(true)}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    renderInput={(params) => <TextField
                        onKeyDown={onKeyDown}
                        onBeforeInput={onKeyDown}
                        onClick={() => !readOnly && setOpen(true)}  {...params} error={false} />}
                />
            </LocalizationProvider>
            <div>{formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    )
}

export default DateSelector



export function DateSelectorWithoutFormik({ isError = "", handleChange, value, label, required = true, minDate, maxDate, readOnly = false, hideRequired = false, ...props }) {
    const [open, setOpen] = useState(false)
    return (
        <div className="field-wrap createPatientDOB">
            {label ? <label>
                {label}
                {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : " (Optional)"}
            </label> : null}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={false}
                    value={value}
                    inputFormat='YYYY-MM-DD'
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleChange}
                    readOnly={readOnly}
                    {...props}
                    closeOnSelect
                    onOpen={() => !readOnly && setOpen(true)}
                    onClose={() => !readOnly && setOpen(false)}
                    open={open}
                    renderInput={(params) => <TextField
                        onBeforeInput={onKeyDown}
                        onKeyDown={onKeyDown}
                        onClick={() => !readOnly && setOpen(true)}  {...params} error={false} />}
                />
            </LocalizationProvider>
            <div>{isError ? <div className="error-text">{isError}</div> : null}</div>
        </div>
    )
}
