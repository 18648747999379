import React, { useEffect, useState } from "react";
import "./header.css"
import FonemedLogo from '../../../assets/images/FoneMed-Logo.jpg';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../api/AuthService";
import { userlogout } from "../../slice/authService";
import { useLocation, useNavigate } from "react-router-dom";
import { failed, success } from "../Toastify";
import DownArrow from '../../../assets/images/down-arrow.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { useGetNotificationsQuery } from "../../../hooks/ReactQueryHooks/useGetNotificationsQuery";
import { Badge, Box, Tooltip, MenuList, Menu } from "@mui/material";
import { Notifications } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { decryptData } from "../../EncryptDecrypt"
import { getSingleUser } from "../../api/Individual";
import { headerTitles } from "./constants";
import UserIcon from '../../../assets/images/user@2x.png';
import { Button } from "../../common/Button";


function Header({ hideBody = false }) {
    const location = useLocation();
    const isHomePage = location.pathname === "/app/home";
    const navigate = useNavigate();
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;
    const id = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);
    const dispatch = useDispatch();
    const tokenExpiry = useSelector((state) => state?.auth?.user?.exp);
    const isExpired = Math.round(Date.now() / 1000) >= tokenExpiry;
    const [notificationData, setNotificationData] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [unreadNotifications, setUnreadNotifications] = useState(null);
    const open = Boolean(anchorEl);
    const userName = useSelector((state) => state?.auth?.user?.email);
    const [userData, setUserData] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);

    const onSuccess = (data) => {
        setNotificationData(data?.data)
        setUnreadNotifications(data?.unreadNotifications)
    }

    useGetNotificationsQuery({ onSuccess, callMe: true });


    const Logout = () => {
        setBtnLoading(true);
        sessionStorage.removeItem('relatedPersonProfile');
        logout();
        setBtnLoading(false);
        dispatch(userlogout());
        isExpired ? failed("Token has expired, Please login again!", 5000) : success("Successfully Logged Out");
        navigate('/login');
    };
    useEffect(() => {
        if (isExpired) {
            Logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpired])

    useEffect(() => {
        if (isHomePage) {
            const apiBody = {
                id,
                type: "Patient",
            };
            getSingleUser(apiBody)
                .then((res) => {
                    setProfilePhoto(res?.data?.photo?.url);
                    setUserData(res?.data);
                })
                .catch((res) => {
                    failed(res?.message)
                })
        }
    }, [id, isHomePage]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <section className="header-wrap">
                <div className="logo" title="Fonemed A Healthier Tomorrow">
                    <img src={FonemedLogo} alt="Fonemed A Healthier Tomorrow" />
                </div>

                {hideBody ? null : <div className="header-body">
                    <div className="left-wrap d-flex align-items-center title">
                        {headerTitles[location.pathname] || ""}

                    </div>
                    <div className="right-wrap">

                        {userData && userData.name && userData.name[0]?.text && (
                            <div className="title"  style={{ textTransform: "capitalize" }}>
                                Welcome {userData.name[0].text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        )}

                        <div>
                            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', cursor: "pointer" }}>
                                <Tooltip title="Notifications">
                                    <Badge badgeContent={unreadNotifications} color="primary">
                                        <Notifications
                                            onClick={handleClick}
                                            style={{ color: "#005da8", fontSize: "clamp(1.5rem,3.5vw,2.5rem" }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        />
                                    </Badge>
                                </Tooltip>
                            </Box>

                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'hidden',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        width: '80%',
                                        maxWidth: '500px',
                                        padding: "0 1rem",
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                {notificationData
                                    ? notificationData?.splice(0, 5)?.map((option) => (
                                        <div key={option?.notificationId}>
                                            <MenuList onClick={handleClose} style={{
                                                display: "inline-block",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                                width: "100%"
                                            }}>
                                                {decryptData(option?.message)}
                                            </MenuList>
                                        </div>
                                    ))
                                    : <MenuList onClick={handleClose}>
                                        No Notification
                                    </MenuList>
                                }
                                {notificationData ? <MenuList onClick={handleClose}>
                                    <Link to={"/app/notifications"}>
                                        View all
                                    </Link>
                                </MenuList> : null}
                            </Menu>



                        </div>

                        <Dropdown className="user-dropdown">
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="user-toggle">
                                {profilePhoto ? (<div className="user-name" style={{ textTransform: "uppercase" }}>
                                    <img src={profilePhoto} alt="" style={{ width: "30px" }} />
                                </div>) :
                                    (<div className="user-name" style={{ textTransform: "uppercase" }}>
                                        <img src={UserIcon} alt="" style={{ width: "30px" }} />
                                    </div>)}
                                <img src={DownArrow} alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => navigate("/app/user-profile")}>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate("/app/change-password")}>Change Password</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate("/app/settings")}>Settings</Dropdown.Item>
                                <Dropdown.Item onClick={Logout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button isLoading={btnLoading} style={{ marginLeft: "55px" }} onClick={Logout}>
                            Logout
                        </Button>
                    </div>

                </div>}
            </section>
        </>
    );
}

export default Header;