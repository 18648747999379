import { Skeleton } from "@mui/material";
import React from "react";

const SelectField = ({ label, options = [], keyField, formik, selectLabel = "Select", required = true, hideRequired = false, optional = true, tooltip = false, tooltipText = "", readOnly = false, isLoading = false, isError = false, ...props }) => {
    return (
        <div className="field-wrap">
            {label ? (
                <label htmlFor={keyField} style={{ whiteSpace: "nowrap" }} className={readOnly ? "readonly" : ""}>
                    {label}
                    {hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : optional ? " (Optional)" : ""}
                </label>
            ) : null}
            {isLoading ? (
                <Skeleton height={40} animation="wave" />
            ) : (
                <select id={keyField} className={readOnly ? "readonly" : ""} style={{ pointerEvents: readOnly ? "none" : "all", cursor: readOnly ? "auto" : "pointer" }} {...formik?.getFieldProps(keyField)} {...props}>
                    <option value="" disabled>
                        {options.length ? selectLabel : "No options"}
                    </option>
                    {options.map(({ value, name, disabled = false }, index) => (
                        <option value={value} key={index} disabled={disabled}>
                            {name}
                        </option>
                    ))}
                </select>
            )}
            <div>{isError ? <div className="error-text">{isError}</div> : formik?.touched[keyField] && formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
        </div>
    );
};

export default SelectField;
