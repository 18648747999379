import { axios } from "../../../lib/axios";

function userData(data, encryptedData, state) {
    return {
        name: [
            {
                use: "usual",
                text: encryptedData.full_name,
                family: encryptedData.last_name,
                given: [encryptedData.first_name],
            },
        ],
        gender: encryptedData.gender,
        birthDate: encryptedData.date_of_birth,
        telecom: [
            {
                system: "phone",
                value: encryptedData.phone_number,
                use: "work",
            },
            {
                system: "email",
                value: encryptedData.email,
                use: "work",
            },
        ],
        address: [
            {
                use: "home",
                line: [encryptedData.address],
                country: data?.country,
                city: encryptedData.city,
                state: state,
                postalCode: encryptedData.postalCode,
            },
        ],
        email: encryptedData.email,
        isPatient: data?.isPatient ? data?.isPatient : undefined,
    };
}
function role(newData, type) {
    return {
        ...newData,
        role: {
            coding: [
                {
                    id: type[0]?.id,
                    system: "http://florizel.fonemed.com/CodeSystem/platform-roles",
                    code: type[0]?.code,
                    display: type[0]?.display,
                    type: type[0]?.type,
                },
            ],
        },
    };
}
function partOf(newData, orgData, organizations) {
    const org = organizations?.filter((org) => org?.id === orgData);
    return {
        ...newData,
        managingOrganization: [
            {
                reference: `Organization/${orgData}`,
                type: "Organization",
                display: org[0]?.name,
            },
        ],
    };
}
function addGeneralPractitioner(newData, generalPractitionerList) {
    return {
        ...newData,
        generalPractitioner: generalPractitionerList.length > 0 ? generalPractitionerList.map((pract) => ({
            reference: `Practitioner/${pract?.id}`,
            type: "Practitioner",
            display: pract?.name[0]?.text
        })) : []
    }
}

const setTypeArray = (userType, isPatient, userTypes) => {
    switch (userType) {
        case "Practitioner":
            return isPatient ? ["Practitioner", "Patient"] : ["Practitioner"];
        case "RelatedPerson":
            return isPatient ? ["RelatedPerson", "Patient"] : ["RelatedPerson"];
        default:
            return userTypes;
    }
};

export const createUser = (data, encryptedData, organizations, state, type, generalPractitionerList, selectedLocationData) => {
    if (data.type === "Patient") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        newData = addGeneralPractitioner(newData, generalPractitionerList)
        return axios.post("/users", { ...newData, healthCardNumber: encryptedData.healthCardNumber, type: "Patient", createAccount: data?.create_account === "true" ? true : false });
    } else if (data.type === "Super-Admin") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        return axios.post("/users", { ...newData, type: "Super-Admin", active: true });
    } else if (data.type === "Practitioner") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        newData = role(newData, type);

        const payload = {
            "type": "Practitioner",
            "name": newData.name,
            "gender": data.gender,
            "birthDate": data.date_of_birth,
            "signatureURL": "",
            "qualification": [
                {
                    "identifier": [
                        {
                            "use": "usual",
                            "type": {
                                "text": "license"
                            },
                            "value": data.license
                        }
                    ],
                    "code": {
                        "coding": [
                            {
                                "text": data.qualification,
                                "display": "college"
                            }
                        ]
                    }
                }
            ],
            "designation": data.designation,
            "telecom": [
                {
                    "system": "phone",
                    "value": encryptedData.phone_number,
                    "use": "work"
                },
                {
                    "system": "phone",
                    "value": data.home_phone_number,
                    "use": "home"
                },
                {
                    "system": "email",
                    "value": data.email,
                    "use": "work"
                }
            ],
            "address": newData.address,
            "email": data.email,
            "managingOrganization": newData.managingOrganization,
            "role": newData.role,
            "active": true
        }

        return axios.post("/users", { ...payload, type: "Practitioner", scopeOfPractice: data.jurisdiction, active: true });
    } else if (data.type === "Related_Person") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        return axios.post("/users", { ...newData, type: "RelatedPerson", patientRefId: data?.related_to, relationship: encryptedData.relationship });
    } else if (data.type === "Device") {
        const payload = {
            "type": "Device",
            "name": [
                {
                    "use": "usual",
                    "text": encryptedData?.device_name,
                    "family": "",
                    "given": [encryptedData?.device_name]
                }
            ],
            "telecom": [
                {
                    "system": "phone",
                    "value": "",
                    "use": "work"
                },
                {
                    "system": "email",
                    "value": encryptedData?.email,
                    "use": "work"
                }
            ],
            "location": {
                "display": selectedLocationData?.name,
                "reference": `Location/${selectedLocationData?.id}`,
                "type": "Location"
            },
            email: encryptedData?.email,
            locationId: selectedLocationData?.id,
        }
        const newPayload = partOf(payload, data.organization, organizations);
        return axios.post("/users", newPayload);
    }
};

export const editUser = (data, encryptedData, organizations, state, type, generalPractitionerList, selectedLocationData, userTypes, createAcctFlag) => {
    if (data.userType === "Patient") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        newData = addGeneralPractitioner(newData, generalPractitionerList);
        newData["type"] = setTypeArray(data?.userType, data?.isPatient, userTypes);
        const apiData = {
            ...newData,
            healthCardNumber: encryptedData.healthCardNumber,
            id: data?.id,
        };

        if (createAcctFlag === true && data?.create_account === "true") {
            apiData.createAccount = true;
        }

        if (data?.create_account === "false") {
            apiData.createAccount = false;
        }
        return axios.put("/users", apiData /* { ...newData, healthCardNumber: encryptedData.healthCardNumber, id: data?.id, createAccount: data?.create_account === "true" ? true : false } */);
    } else if (data.userType === "Practitioner") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        newData = role(newData, type);
        newData = addGeneralPractitioner(newData, generalPractitionerList)
        newData["type"] = setTypeArray(data?.userType, data?.isPatient, userTypes);

        const payload = {
            "type": [data.userType],
            "name": newData.name,
            "gender": data.gender,
            "birthDate": data.date_of_birth,
            "signatureURL": "",
            "qualification": [
                {
                    "identifier": [
                        {
                            "use": "usual",
                            "type": {
                                "text": "license"
                            },
                            "value": data.license
                        }
                    ],
                    "code": {
                        "coding": [
                            {
                                "text": data.qualification,
                                "display": "college"
                            }
                        ]
                    }
                }
            ],
            "designation": data.designation,
            "telecom": [
                {
                    "system": "phone",
                    "value": encryptedData.phone_number,
                    "use": "work"
                },
                {
                    "system": "phone",
                    "value": data.home_phone_number,
                    "use": "home"
                },
                {
                    "system": "email",
                    "value": data.email,
                    "use": "work"
                }
            ],
            "address": newData.address,
            "email": data.email,
            "managingOrganization": newData.managingOrganization,
            "role": newData.role,
            "active": true
        }

        return axios.put("/users", { ...payload, id: data?.id, scopeOfPractice: data?.jurisdiction, });
    } else if (data.userType === "RelatedPerson") {
        let newData = userData(data, encryptedData, state);
        newData = partOf(newData, data.organization, organizations);
        newData["type"] = setTypeArray(data?.userType, data?.isPatient, userTypes);
        return axios.put("/users", { ...newData, id: data?.id, relationship: encryptedData?.relationship, });
    } else if (type === "Device") {
        const payload = {
            id: data?.id,
            "type": ["Device"],
            "name": [
                {
                    "use": "usual",
                    "text": encryptedData?.device_name,
                    "family": "",
                    "given": [encryptedData?.device_name]
                }
            ],
            "telecom": [
                {
                    "system": "phone",
                    "value": "",
                    "use": "work"
                },
                {
                    "system": "email",
                    "value": encryptedData?.email,
                    "use": "work"
                }
            ],
            "location": {
                "display": selectedLocationData?.name,
                "reference": `Location/${selectedLocationData?.id}`,
                "type": "Location"
            },
            email: encryptedData?.email,
            locationId: selectedLocationData?.id,
        }
        const newPayload = partOf(payload, data.organization, organizations);
        return axios.put("/users", newPayload);
    }
};

export const getCustomRoles = (org_id) => {
    return axios.get(`codeSystem?orgIds=${JSON.stringify(org_id)}`);
};
//Api Call for getting single user data
export const getSingleUser = ({ id = null, type = null, active = true, orgId = null, isSuperAdmin = false, isAllUsersAllOrgs = false }) => {
    if (isSuperAdmin) {
        return axios.get(`/users?active=true&type=Super-Admin`)
    } else if (isAllUsersAllOrgs) {
        return axios.get(`/users?active=true`)
    } else if (id && type) {
        return axios.get(`/users?id=${id}&type=${type}`)
    } else if (orgId && type == "") {
        return axios.get(`/users?active=true`)
    } else if (orgId && type !== "All" && type !== null) {
        return axios.get(`/users?orgId=${orgId}&type=${type}&active=${active}`)
    } else if (orgId && type === "All") {
        return axios.get(`/users?orgId=${orgId}&active=${active}`)
    } else if (orgId && type === null) {
        return axios.get(`/users?orgId=${orgId}&active=${active}`)
    } else if (orgId && active === false) {
        return axios.get(`/users?orgId=${orgId}&active=false`)
    } else if (id) {
        return axios.get(`/users?id=${id}`)
    } else if (orgId) {
        return axios.get(`/users?orgId=${orgId}`)
    } else if (type) {
        return axios.get(`/users?type=${type}`)
    } else if (active === false) { // disabled users
        return axios.get(`/users?active=false`)
    }
};
//Api Call for fetching the data of patient from patient table of the DB
export const getPatientData = (patient_id) => {
    return axios.get(`patient?patient_id=${patient_id}`);
};
//Api Call for showing patients in autosearch with debouncing
export const getRelatedPersonsList = (org_id, searchText) => {
    return axios.get(`patient?org_id=${org_id}&search=${searchText}`);
};
//Api call for geting related person info while editing related person type
export const getRelatedPerson = (id) => {
    return axios.get(`relatedPerson?patientRefId=${id}`);
};
//Api call for enroling patient into rpm 
export const enrollPatientsIntoRpm = (patientId, programId) => {
    return axios.post(`program/enroll-patients`, { patientId, programId })
};
//Api call for getting practitioners list to show while creating patient user type
export const getPractitionerList = (orgId, scopeOfPrac, isSelfOrg = false) => {
    return scopeOfPrac ? axios.get(`practitioner?org_id=${orgId}&scopeOfPractice=${scopeOfPrac}&isSelfOrg=${isSelfOrg}`) : axios.get(`practitioner?org_id=${orgId}&isSelfOrg=true`);
};
export const getDevice = (deviceId) => {
    return axios.get(`device/${deviceId}`);
};

export const getSettingsForPatient = (payload) => {
    return axios.get(`patient/settings`, payload);
}

export const updateSettingsForPatient = (payload) => {
    return axios.put(`patient/settings`, payload);
}

export const getProgramsForPatient = (userId) => {
    return axios.get(`patient/program-history?id=${userId}`);
}

export const getRelatedPersonForPatient = (patientId) => {
    let endpoint = patientId ? '?patientRefId=' + patientId : "";
    return axios.get(`relatedPerson${endpoint}`);
}

export const deleteRelatedPersonForPatient = (payload) => {
    return axios.delete(`relatedPerson?id=${payload.id}&patientRefId=${payload.userId}&isMobile=true`);
}

export const inviteRelatedPersonForPatient = (payload) => {
    return axios.post(`relatedPerson`, payload);
}