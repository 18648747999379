import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import * as Yup from 'yup';
import TextInput from "../common/textfield/TextInput";
import SelectField from "../common/textfield/SelectField";
import TextArea from "../common/textfield/TextArea";
import Radio from "../common/textfield/Radio";
import { Button } from "../common/Button";
import { joinWaitingRoom } from "../api/WaitingRoom";
import { failed } from "../common/Toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { getPatientData } from "../api/Individual";
import { regions } from "./Constants";



const QuestionnaireWaitingRoom = ({ questionnaireWRModalShow, handleQuestionnaireWRModalClose, WRquestions, openWRScreen }) => {
    const [isLoading, setIsLoading] = useState(false);
    const storedProfile = sessionStorage.getItem("relatedPersonProfile");
    const storedId = storedProfile ? JSON.parse(storedProfile).id : null;

    const patientId = useSelector((state) => storedId || state?.auth?.user?.["custom:unique_id"]);

    //const patientId = useSelector((state) => state?.auth?.user?.["custom:unique_id"]);
    const orgId = useSelector((state) => state?.auth?.user?.orgId);
    const [patientName, setPatientName] = useState("");
    const user = useSelector((state) => state?.auth?.user);
    const [encounterId, setEncounterId] = useState(null);

    useEffect(() => {
        if (!patientName) {
            getPatientData(patientId)
                .then((res) => {
                    setPatientName(res?.result?.name[0]?.text || user?.email);
                })
        }
    });



    const formik = useFormik({
        initialValues: (() => {
            const initialValues = {
                callerNumberExt: ""
            };

            WRquestions?.item?.forEach((question) => {
                switch (question.answerType) {
                    case 'freeText':
                    case 'dropDown':
                        initialValues[question.text] = '';
                        break;
                    case 'radio':
                        initialValues[question.text] = question.answerOption?.[0]?.text || '';
                        break;
                    default:
                        initialValues[question.text] = '';
                }
            });

            return initialValues;
        })(),
        validationSchema: Yup.object().shape({
            ...WRquestions?.item?.reduce((validationSchema, question) => {
                if (question.required) {
                    validationSchema[question.text] = Yup.string().required(`${question.text} is required`);
                }

                if (question.tag === 'TELEPHONE') {
                    validationSchema[question.text] = Yup.string()
                        .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Invalid phone number. Please enter a valid format like (123) 456-7890')
                        .required(`${question.text} is required`);
                }
                return validationSchema;
            }, {}),
        }),
        onSubmit: (values) => {

            const updatedQuestionnaireData = WRquestions?.item?.map((question) => {
                return {
                    ...question,
                    patientResponse: values[question.text],
                };
            });

            if (values?.callerNumberExt !== '') {
                updatedQuestionnaireData.push({
                    "callerNumberExt": values?.callerExt
                });
            }

            setIsLoading(true);
            let questionnaireResponse = {};

            updatedQuestionnaireData?.forEach((question) => {
                const tagValue = question.tag;
                const responseKey = question.patientResponse;

                if (tagValue === "TELEPHONE") {
                    questionnaireResponse["callerNumber"] = responseKey;
                }

                if (tagValue === "LOCATION") {
                    const locationObj = regions.find((region) => region.name === responseKey);
                    questionnaireResponse["patientLocation"] = locationObj ? locationObj.value : responseKey;
                }

                if (tagValue === "REASON") {
                    questionnaireResponse["reason"] = responseKey;
                }

                if (tagValue === "CHANNEL") {
                    questionnaireResponse["preferredChannel"] = responseKey;
                }

                if (question?.callerNumberExt) {
                    questionnaireResponse["callerNumberExt"] = question?.callerNumberExt;
                } else {
                    questionnaireResponse["callerNumberExt"] = "";
                }

            });

            const request = {
                status: "arrived",
                statusHistory: [
                    {
                        status: 'arrived',
                        period: {
                            start: moment().utc().format(),
                            end: null
                        }
                    },
                ],
                type: [
                    {
                        coding: [{
                            code: "VV",
                            display: "Virtual Visit Encounter Type"
                        }]
                    }
                ],
                period: {
                    start: moment().utc().format(),
                    end: null
                },
                subject: {
                    reference: `Patient/${patientId}`,
                    type: 'Patient',
                    display: `${patientName}`,
                },
                participant: [],
                patientLocation: questionnaireResponse["patientLocation"],
                description: questionnaireResponse["reason"],
                managingOrganization: {
                    reference: `Organization/${orgId}`,
                    type: 'Organization',
                },
            };

            joinWaitingRoom(request)
                .then((res) => {
                    setEncounterId(res?.data?.id);
                    updatedQuestionnaireData.forEach((question) => {
                        question.encounterId = res?.data?.id;
                    });

                })
                .catch((res) => failed(res?.message))
                .finally(() => setIsLoading(false))

            openWRScreen(updatedQuestionnaireData);
        }
    });

    const handleRadioChange = (question, value) => {
        formik.setFieldValue(question, value);
    };

    return (
        <section>
            {isLoading ? (
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80vh"
                }}>
                    <CircularProgress size={50} />
                </div>
            ) : (
                <Modal
                    backdropClassName
                    backdrop={'static'}
                    show={questionnaireWRModalShow} onHide={handleQuestionnaireWRModalClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered className="custom-dialog">

                    <Modal.Header closeButton className="border-0">
                        <Modal.Title id="contained-modal-title-vcenter">Questions</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <form className="common-form border-fields" onSubmit={formik.handleSubmit}>

                            {WRquestions?.item?.map((question, questionIndex) => (
                                <div key={questionIndex}>
                                    {question.answerType === 'freeText' && question.tag !== 'TELEPHONE' && (
                                        <TextArea
                                            keyField={question.text}
                                            label={question.text}
                                            formik={formik}
                                            
                                            placeholder={"Type your answer here"}
                                        />
                                    )}

                                    {question.answerType === 'freeText' && question.tag === 'TELEPHONE' && (
                                        <Row>

                                            <Col>
                                                <TextInput
                                                    keyField={question.text}
                                                    label={'Return Telephone Number'}
                                                    formik={formik}
                                                    type="phone"
                                                    placeholder={"Contact Number here"}
                                                />
                                            </Col>

                                            <Col>
                                                <TextInput keyField={"callerNumberExt"} type="ext" label={"Ext."} formik={formik} placeholder={"Ext"} required={false} />
                                            </Col>

                                        </Row>

                                    )}

                                    {question.answerType === 'dropDown' && (
                                        <SelectField
                                            keyField={question.text}
                                            label={question.text}
                                            formik={formik}
                                            options={question.answerOption.map(option => ({ value: option.text, name: option.text }))}
                                        />
                                    )}

                                    {question.answerType === 'radio' && (
                                        <div>
                                            <div>{question.text}</div>
                                            {question.answerOption?.map((option, optionIndex) => (
                                                <Radio
                                                    key={optionIndex}
                                                    keyField={option.text}
                                                    name={question.text}
                                                    value={option.text}
                                                    radioLabel={option.text}
                                                    label={option.text}
                                                    isChecked={formik?.values[question.text] === option.text}
                                                    formik={formik}
                                                    handleChange={(value) => handleRadioChange(question.text, value)}
                                                />
                                            ))}
                                        </div>
                                    )}


                                </div>
                            ))}

                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? <CircularProgress size={24} /> : 'Enter Waiting Room'}
                            </Button>
                        </form>

                    </Modal.Body>
                </Modal>
            )}
        </section>
    )
}

export default QuestionnaireWaitingRoom;