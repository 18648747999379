import * as Yup from "yup";
import { formattedPhoneNumberLength } from "../../utils/phoneNumberSupport";


export const FormikObj = {
    initialValues: {
        firstName: "",
        lastName: "",
        birthDate: "",
        phone: "",
        profileImage: null,
        gender: "",
        email: ""
    },
    validationSchema: Yup.object({
        firstName: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Required first name field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        lastName: Yup.string()
            .max(15, "Must be 15 characters or less")
            .required("Required last name field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        birthDate: Yup.string().required("Required date of birth field"),
        phone: Yup.string()
            .matches(/^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/, "Enter Valid Work Phone Number.")


    })
}